.add-attendee-container {
  display: flex;
  justify-content: end;
  border-radius: var(--Count, 0px);
  padding: 43px 78px;
  border: 1px solid #d6e3ff;
  background: #fff;
  box-shadow: 0px 4px 28px 0px rgba(204, 204, 204, 0.25);
}

.attendee-sub-container {
  display: flex;
  align-items: center;
  gap: 30px;
}

.attendees-info-text {
  font-size: var(--desktop-fz26);
}

@media screen and (max-width: 1040px) {
  .add-attendee-container {
    /* flex-direction: column; */
    padding: 22px 30px;
  }
  .attendees-info-text {
    font-size: var(--desktop-fz22) !important;
  }
}

@media screen and (max-width: 768px) {
  .add-attendee-container {
    flex-direction: column;
    gap: 10px !important;
  }
  .attendees-info-text {
    font-size: var(--laptop-fz20) !important;
  }
}

@media screen and (max-width: 480px) {
  .attendee-sub-container > img {
    width: 80px;
  }
  .attendee-sub-container {
    gap: 15px !important;
  }
  .add-attendee-container {
    flex-direction: column;
    gap: 20px !important;
    padding: 22px 18px;
  }
  .attendees-info-text {
    font-size: var(--mobile-fz16) !important;
    font-weight: var(--mobile-fw400);
    line-height: 150%;
  }
  .add-attendee-container button {
    padding: 0px 30px !important;
  }
  .add-attendee-container button span {
    font-size: var(--desktop-fz12) !important;
    font-weight: var(--desktop-fw600) !important;
  }
}

@media screen and (max-width: 350px) {
  .attendee-sub-container {
    gap: 10px !important;
  }
  .attendee-sub-container > img {
    width: 60px;
  }
  .attendees-info-text {
    font-size: var(--mobile-fz14) !important;
  }
  .add-attendee-container button {
    padding: 0px 30px !important;
    max-height: 30px !important;
  }
}
