.unsubscribe-contact {
  color: #1890ff;
  cursor: pointer;
}
.unsubscribe-pass-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.unsubscribe-pass-container .unsubscribe-pass-sub-container {
  background: white;
  box-shadow: 1px 1px 10px lightgray;
  border-radius: 8px;
  padding: 2.5rem;
  padding-bottom: 1.5rem;
  margin: 3rem 0;
  max-width: 80%;
}

.unsubscribe-extend-login-button {
  margin-bottom: 0;
  width: 50%;
}

.unsubscribe-text-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0px;
  margin-bottom: 1.5rem;
}
.unsubscribe-page-heading {
  font-size: var(--desktop-fz28);
  font-weight: var(--desktop-fw600);
  margin-bottom: 8px !important;
}
.unsubscribe-button-form-item-control-input-content{
   display: flex;
   justify-content: center;
}

.sub-page-heading {
  font-size: var(--desktop-fz18);
  font-weight: var(--desktop-fw500);
  display: flex;
  align-items: center; /* Center vertically */
  justify-content: center; /* Center horizontally */
  color: gray;
  margin: 0 auto; /* Center the text horizontally */
}

.unsubscribe-form-label-text {
  font-size: var(--desktop-fz18) !important;
  font-weight: var(--desktop-fw500);
  color: rgb(0, 0, 0);
  margin-bottom: 10px !important;
  line-height: 40px;
}

.unsubscribe-input {
  background: white !important;
  width: 100%;
}
.unsubscribe-input:focus {
  border-color: var(--pbss-p1) !important;
}

@media screen and (max-width: 480px) {
  .unsubscribe-pass-container .unsubscribe-pass-sub-container {
    width: 100%;
    max-width: 100%;
    margin-top: 1.5rem;
    padding: 1.8rem;
  }
  .unsubscribe-page-heading {
    font-size: var(--desktop-fz22);
  }

  .sub-page-heading {
    font-size: var(--desktop-fz16);
  }
  .unsubscribe-form-label-text {
    font-size: var(--desktop-fz16) !important;
  }
}
