.common-sponsor-section1 {
  background: var(--White, #fff);
  min-width: 141.251px;
  max-width: 150px;
  width: 141.251px;
  height: 100px;
  max-height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 3.57215px 21.43292px 0px rgba(0, 0, 0, 0.11);
  border-radius: 8.059px;
  border: 0.577px solid #bce;
  box-shadow: 0px 4.03px 48.355px 0px rgba(0, 0, 0, 0.09);
}

.common-sponsor-section1 > img {
}

.sponsor-icon {
  /* width: 75.554px !important; */
  /* height: 29.214px; */
}

.common-sponsor-section1 > p {
  color: #222;
  font-size: 17.861px;
  font-style: normal;
  font-weight: var(--desktop-fw700);
  line-height: 162%;
  /* 28.934px */
}

.nav-login-as {
  border-radius: 6px;
  border: none;
  cursor: pointer;
  background: #000;
  display: inline-flex;
  padding: 8px 20px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  color: var(--White, var(--White, #fff));
  font-size: var(--desktop-fz16);
  font-style: normal;
  font-weight: var(--desktop-fw700);
  line-height: 150%; /* 24px */
}

@media screen and (max-width: 550px) {
  .common-sponsor-section1 {
    width: auto;
  }
}
