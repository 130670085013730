.customized-table {
}

.customized-table-thead > tr > th {
  background-color: #262525 !important;
  color: #f2f2f2 !important;
  font-size: var(--desktop-fz20);
  font-style: normal;
  font-weight: var(--desktop-fw700);
  line-height: 24px;
  /* 100% */
}

.customized-table-cell {
  border-start-start-radius: 0px !important;
  border-start-end-radius: 0px !important;
}

.customized-table-row {
  border-radius: var(--Count, 0px);
  background: #e8f9fa;
}

.customized-table-tbody > tr > td {
  border-bottom: 5px solid white !important;
  color: var(--Black, #000);
  font-size: var(--desktop-fz15);
  font-style: normal;
  font-weight: var(--desktop-fw600);
  line-height: 24px;
  /* 133.333% */
}

.desc-info {
  color: gray;
  font-size: small;
}
