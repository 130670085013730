.costom-textarea-modal {
  min-height: 300px !important;
  max-height: 500px !important;
}

.import-code-modal-cancel {
  margin: 0px 10px;
  background-color: white !important;
  border: 1px solid rgb(158, 158, 158) !important;
  color: rgb(158, 158, 158) !important;
}

.import-code-modal-cancel:hover {
  background-color: rgb(158, 158, 158) !important;
  color: white !important;
  border: 1px solid rgb(158, 158, 158) !important;
}

.import-code-modal {
  background-color: white !important;
  border: 1px solid var(--color-darkslategray-100) !important;
  color: var(--color-darkslategray-100) !important;
}

.import-code-modal:hover {
  background-color: var(--color-darkslategray-100) !important;
  color: white !important;
  border: 1px solid var(--color-darkslategray-100) !important;
}

.update-status-selection {
  width: 100%;
}

.update-footer {
  display: flex;
  justify-content: end;
  margin-top: 30px;
  gap: 10px;
}
