.event-perks-text .ant-typography {
  display: grid;
  grid-template-columns: repeat(2, 2fr); /* Two columns with equal width */
  grid-gap: 10px;
}

.event-perks-text .ant-typography .ant-tag {
  width: auto;
}
.checkbox-editableCell {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #898989 !important;
}
