/* .opening-container {
  margin-bottom: 80px;
} */

.opening-container p {
  font-size: var(--desktop-fz24);
  font-weight: var(--desktop-fw700);
  /* margin-bottom: 25px !important; */
}
.used-for-login {
  display: flex;
  gap: 20px;
  flex-direction: column;
}
.openings-card-container {
  display: grid;
  grid-gap: 20px;
  max-width: 100%;
}

.opening-card-container-for-chapterpage {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 20px;
  max-width: 100%;
}
.login {
  grid-template-columns: repeat(2, 1fr) !important;
}
.home {
  grid-template-columns: repeat(3, 1fr) !important;
}
.opening-card-new-wrpapper-chapPage {
  padding: 18px 30px !important;
}

.opening-card-title-chapPage {
  font-size: var(--desktop-fz18) !important;
}

.opening-card-company-location-chapPage,
.opening-card-company-employer-chapPage {
  font-size: var(--desktop-fz16) !important;
}

.opening-card-description-chapPage {
  font-size: var(--desktop-fz14) !important;
}
.openings-card-flex-container {
  display: flex;
  grid-gap: 20px;
}
.card-head-w {
  width: 85%;
}

.opening-card-wapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
  border: 1px solid #d8d8d8;
  border-radius: 8px;
  padding: 20px;
  margin: 10px;
  flex: 1;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  gap: 10px;
}
.card-title-wapper {
  display: flex;
  justify-content: space-between;
  gap: 15px;
}

.card-title-wapper p {
  color: var(--pbss-p1, #005d61);
  font-size: 22.556px;
  font-style: normal;
  font-weight: var(--desktop-fw700);
  line-height: normal;
}

.card-title-wapper label {
  font-size: var(--desktop-fz14);
  color: #666;
}

.card-title-wapper span {
  background-color: rgba(0, 93, 97, 0.35);
  color: rgba(0, 93, 97);
  font-weight: var(--desktop-fw700);
  height: fit-content;
  padding: 10px 14px;
  border-radius: 50%;
  font-size: var(--desktop-fz14);
}
.opening-card-wapper button {
  border: none;
  display: flex;
  width: 136.292px;
  height: 37.056px;
  padding: 5.163px 10.327px;
  justify-content: center;
  align-items: center;
  gap: 6.196px;
  flex-shrink: 0;
  border-radius: 17.556px;
  background: #000;
  cursor: pointer;
}
.view-desc {
  color: #fff;
  border: none;
  display: flex;
  width: 136.292px;
  height: 37.056px;
  padding: 5.163px 10.327px;
  justify-content: center;
  align-items: center;
  gap: 6.196px;
  flex-shrink: 0;
  border-radius: 17.556px;
  background: var(--pbss-p1) !important;
  cursor: pointer;
}
.opening-card-wapper button a {
  color: #fff;
  display: flex;
  align-items: center;
  margin-top: 0px !important;
  text-decoration: none !important;
}
.opening-card-wapper label {
  color: #747474;
  font-size: 12.889px;
  font-style: normal;
  font-weight: var(--desktop-fw450);
  line-height: var(--desktop-lh170);
  /* 21.912px */
}

.wapper-desc {
  color: #6c6868 !important;
  font-size: 17.186px !important;
  font-style: normal !important;
  font-weight: 450 !important;
  line-height: 150% !important;
}

.opening-card-wapper a {
  color: var(--black, #000);
  text-align: left;
  font-size: 13.648px;
  font-style: normal;
  font-weight: var(--desktop-fw700);
  line-height: normal;
  text-decoration-line: underline;
  margin-top: 15px;
}

.details-icon svg {
  display: flex !important;
  align-items: center;
}

.opening-container-used-for-chapPage {
  width: 100%;
}

@media screen and (max-width: 768px) {
  /* .openings-card-container {
    display: flex;
  } */
}

/* New Opning Card Styles */

.opening-card-new-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 38px 40px;
  border-radius: 0px;
  cursor: pointer;
  border: 0.5px solid #a1b6df;
  background: #fff;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.09),
    0px 0px 4px 0px rgba(0, 0, 0, 0.04);
}
.opening-card-new-wrapper:hover {
  background-color: #2f58a413;
  cursor: pointer;
  box-shadow: 1px 1px 10px #2f58a435;
  transition: all 0.5ms ease-in;
}

.opening-card-title {
  font-size: var(--desktop-fz24);
  font-weight: var(--desktop-fw700);
  color: var(--pbss-p3);
}

.opening-card-main {
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  height: fit-content;
  gap: 10px;
}

.opening-card-company-location {
  font-size: var(--desktop-fz14);
  font-weight: var(--desktop-fw500);
  color: #000;
}

.opening-card-company-employer {
  font-size: var(--desktop-fz14);
  font-weight: var(--desktop-fw500);
  color: #262525;
}

.opening-card-description {
  font-size: var(--desktop-fz16);
  font-weight: var(--desktop-fw400);
}

.opening-card-description * {
  all: unset;
}

.opening-card-description p,
.opening-card-description em,
.opening-card-description strong {
  font-size: var(--desktop-fz16) !important;
  font-weight: var(--desktop-fw400) !important;
}

.opening-card-description ul {
  list-style: none;
}

.view-details-button {
  font-size: var(--desktop-fz14);
  font-weight: var(--desktop-fw700);
  color: #fff;
  padding: 10px 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background-color: var(--pbss-p3);
  border: none;
  cursor: pointer;
}

.opening-card-button-container {
  margin-top: 20px;
}

@media screen and (max-width: 1024px) {
  .opening-card-new-wrapper {
    padding: 22px 10px;
  }
  .opening-card-title {
    font-size: var(--laptop-fz24);
    text-wrap: wrap;
  }
  .opening-card-description {
    font-size: var(--laptop-fz16);
    text-wrap: wrap;
  }
  .opening-card-company-location {
    font-size: var(--laptop-fz18);
  }
  .opening-card-company-employer {
    font-size: var(--laptop-fz18);
  }
  .view-details-button {
    font-size: var(--laptop-fz12);
  }
}

@media screen and (max-width: 768px) {
  .opening-card-button-container {
    margin-top: 10px;
  }
  .opening-card-main {
    gap: 10px;
  }
  .opening-card-new-wrapper {
    padding: 17px 24px !important;
  }
  .openings-card-container {
    grid-template-columns: 1fr !important;
  }
}

@media screen and (max-width: 550px) {
  .openings-card-container {
    grid-template-columns: 1fr !important;
  }
  .opening-card-new-wrapper {
    padding: 17px 24px !important;
  }
  .opening-card-main {
    gap: 10px;
  }
  .opening-card-title {
    font-size: var(--mobile-fz16) !important;
  }
  .opening-card-company-location {
    font-size: var(--mobile-fz12) !important ;
    font-weight: var(--mobile-fw700) !important;
  }
  .opening-card-company-employer {
    font-size: var(--mobile-fz12) !important;
    color: #262525 !important;
    font-weight: var(--mobile-fw700) !important;
  }
  .opening-card-description {
    font-size: var(--mobile-fz10) !important;
    font-weight: var(--mobile-fw400) !important;
    line-height: 140%;
  }
  .view-details-button {
    /* padding: 6px 16px; */
    font-size: var(--mobile-fz8) !important;
    font-weight: var(--mobile-fw700) !important;
  }
  .opening-card-button-container {
    margin-top: 10px;
  }
}
