.food-option-card > span {
  color: var(--Black, #000);
  font-size: var(--desktop-fz12);
  font-style: normal;
  font-weight: var(--desktop-fw600);
  line-height: 150%; /* 18px */
}

.food-option-card {
  display: flex;
  height: fit-content;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  border-radius: 10px;
  padding: 15px 20px;
  background: linear-gradient(180deg, #fff 0%, rgba(255, 255, 255, 0) 100%);
  box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.25);
}

.food-option-conatiner {
  display: flex;
  justify-content: space-between;
}

.food-option-sub {
  display: flex;
  gap: 10px;
}

.total-text {
  color: #817979 !important;
  font-size: 17.91px !important;
  font-style: normal;
  font-weight: var(--desktop-fw450);
  line-height: 150%; /* 26.865px */
}

.total-text > p {
  color: var(--Black, #000);
  font-size: 23.56px;
  font-style: normal;
  font-weight: var(--desktop-fw600);
  line-height: 150%; /* 35.34px */
}

.price-div {
  display: flex;
  justify-content: space-between;
}
.price-div > p {
  font-size: var(--desktop-fz14);
}

.food-option-card > .ant-divider {
  margin: 5px 0px !important;
}

.add-item-button {
  display: flex;
  width: 30.494px;
  height: 30.494px;
  padding: var(--Count, 0px);
  justify-content: center;
  align-items: center;
  gap: 3px;
  flex-shrink: 0;
  border-radius: 33.75px;
  background: #268751;
  border: none;
  font-size: var(--desktop-fz16);
  color: white;
  font-weight: var(--desktop-fw600);
}

.button-collapse-container {
  margin: 20px 0px;
}

.price-button-con {
  display: flex;
  flex-direction: column;
}

.act {
  display: flex;
  flex-direction: column;
}

.button-collapse-container > button {
  height: auto;
}

.add-item-button:hover {
  color: white !important;
}
