.user-button-wrapper {
  height: 100%;
  align-items: center;
  line-height: 34px;
  font-size: var(--desktop-fz12);
  border-radius: 0px !important;
  font-weight: var(--desktop-fw700) !important;
}

.user-button-wrapper-checked {
  border-color: var(--pbss-p3) !important;
  color: var(--pbss-p3) !important;
}

.user-button-wrapper:hover {
  color: var(--pbss-p3) !important;
}
