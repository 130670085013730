.common-alert {
  align-items: center;
  height: fit-content;
  padding: 10px 15px;
  gap: 30px;
}

.common-alert-close-icon {
  background-color: black !important;
  padding: 2px !important;
  border-radius: 50%;
}

.common-alert-close-icon .anticon-close {
  color: white !important;
}

.common-alert .ant-btn:hover {
  color: white !important;
}

@media screen and (max-width: 768px) {
  .common-alert {
    align-items: start;
    height: -moz-fit-content;
    height: fit-content;
    flex-direction: column;
    padding: 10px 15px;
    gap: 10px;
  }
}
