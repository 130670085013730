.header-chapter-banner {
  height: 150px;
  width: 100%;
  image-rendering: -webkit-optimize-contrast;
}

@media screen and (max-width: 480px) {
  .header-chapter-banner {
    height: 80px;
    width: 100%;
    image-rendering: -webkit-optimize-contrast;
  }
}
