.promo-sub-conatiner > button {
  color: #2f57a4 !important;
  font-size: var(--desktop-fz14);
  font-style: normal;
  font-weight: var(--desktop-fw700);
}

.promo-code-container > p {
  color: var(--Black, #000);
  font-size: var(--desktop-fz18);
  margin-bottom: 8px !important;
  font-style: normal;
  font-weight: var(--desktop-fw500);
  line-height: 150%; /* 24px */
}

.promo-sub-conatiner .ant-input {
  border-color: white !important;
  font-size: var(--desktop-fz16) !important;
  color: #2f57a4 !important;
  font-style: normal;
  font-weight: var(--desktop-fw600);
  line-height: 150%; /* 24px */
}
.promo-code-sub-title {
  color: var(--Black, #000);
  font-size: var(--desktop-fz16);
  font-style: normal;
  font-weight: var(--desktop-fw600);
  line-height: 150%; /* 24px */
  margin-bottom: 27px;
}

.promo-sub-conatiner .ant-input:focus {
  box-shadow: none;
}
.promo-sub-conatiner {
  display: flex;
  width: fit-content;
  padding: 6px 10px !important;
  border-radius: 0px !important;
  border: 2px solid #2f57a4 !important;
}
.promo-sub-conatiner .ant-form-item {
  margin-bottom: 0px !important;
}
.cart {
  padding: 10px 12px;
}

.promo-sub-conatiner .ant-input:hover {
  border-color: #ffffff;
  border-inline-end-width: 1px;
}

.promo-code-error-message {
  color: red !important;
  font-size: var(--desktop-fz17);
}

.promo-code-container {
  margin: 20px 0px 10px;
}

/* @media screen and (max-width: 768px) {
  .promo-sub-conatiner {
    min-width: 100%;
  }
} */
