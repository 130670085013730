.footer-container {
  display: flex;
  justify-content: space-between;
  padding: 24px 8%;
  align-items: center;
  background: var(--pbss-p1);
}

p {
  margin: 0 !important;
  padding: 0 !important;
}

.content {
  font-style: normal;
  font-weight: var(--desktop-fw400);
  font-size: var(--desktop-fz15);
  line-height: 21px;
  color: #ffffff;
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.privacy-links {
  display: flex;
  align-items: flex-start;
  gap: 24px;
}

.privacy-links > a {
  color: var(--white);
  /* font-family: Circular Std; */
  font-size: var(--desktop-fz14);
  font-style: normal;
  font-weight: var(--desktop-fw450);
  line-height: 150%;
  text-underline-offset: 4px;
  text-decoration-line: underline;
}

.column > li {
  line-height: 25px;
}

.media-icons {
  display: flex;
  align-items: center;
}

.icon-container-footer {
  display: flex;
  line-height: 40px !important;
}

/* .icon {
  margin-right: 20px;
} */

@media screen and (max-width: 490px) {
  .footer-container {
    flex-direction: column;
    align-items: start;
  }

  .content {
    flex-direction: column-reverse;
    gap: 15px;
    justify-content: center;
  }
  .content > P {
    text-align: center;
    font-size: 11px;
    font-style: normal;
    font-weight: var(--desktop-fw400);
    line-height: 150%;
  }

  .media-icons {
    float: left;
    margin: 15px 0px;
    margin-bottom: 0px;
    justify-content: start;
  }
  .privacy-links > a {
    font-size: 12.317px;
    font-style: normal;
    font-weight: var(--desktop-fw400);
    line-height: 150%; /* 18.475px */
    text-decoration-line: underline;
  }
}

@media screen and (max-width: 690px) {
  .footer-container {
    flex-direction: column;
    align-items: start;
  }

  .media-icons {
    float: left;
    margin: 15px 0px;
    margin-bottom: 0px;
    justify-content: start;
  }
}
