.sponsorInfoDetails-cards-wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 20px 0px;
}

.sponsorInfoDetails-card {
  width: 100%;
  border: 1px solid var(--pbss-p1);
  border-radius: 5px;
  padding: 20px 40px;
}

.sponsorInfoDetails-card h1 {
  font-size: var(--desktop-fz22);
  font-weight: var(--desktop-fw500);
  color: var(--pbss-p1);
  margin: 0px 0px 10px 0px !important;
}

.sponsorshipInfo {
  font-family: inter;
  margin: 5px 0px !important;
  padding: 0px !important;
  box-sizing: border-box;
  font-size: var(--desktop-fz18);
  font-weight: var(--desktop-fw500);
  color: rgb(0, 0, 0);
  font-style: normal;
  font-variant-ligatures: normal;
  font-variant-caps: normal;
  letter-spacing: normal;
  orphans: 2;
  text-align: start;
  text-indent: 0px;
  text-transform: none;
  widows: 2;
  word-spacing: 0px;
  -webkit-text-stroke-width: 0px;
  white-space: normal;
  text-decoration-thickness: initial;
  text-decoration-style: initial;
  text-decoration-color: initial;
}

.sponsorshipInfoOrderedList {
  font-family: inter;
  box-sizing: border-box;
  color: rgb(0, 0, 0);
  font-size: 14px;
  font-style: normal;
  font-variant-ligatures: normal;
  font-variant-caps: normal;
  font-weight: 400;
  letter-spacing: normal;
  orphans: 2;
  text-align: start;
  text-indent: 0px;
  text-transform: none;
  widows: 2;
  word-spacing: 0px;
  -webkit-text-stroke-width: 0px;
  white-space: normal;
  text-decoration-thickness: initial;
  text-decoration-style: initial;
  text-decoration-color: initial;
}

.sponsorshipInfoList {
  font-family: var(--font-inter);
  box-sizing: border-box;
  color: rgb(40, 40, 40);
  font-size: var(--desktop-fz16);
  font-weight: var(--desktop-fw400);
  margin: 5px 0px !important;
}

.sponsorInfoDetails-card p {
  font-size: var(--desktop-fz18);
  font-weight: var(--desktop-fw500);
  margin: 5px 0px !important;
}

.sponsorInfoDetails-card li {
  font-size: var(--desktop-fz16);
  font-weight: var(--desktop-fw400);
  color: #282828;
  margin: 5px 0px !important;
}
