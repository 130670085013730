.event2-listing-container {
  padding: 15px 0px;
  justify-content: space-between;
}

.event2-listing-section1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0;
}

.extend-status-text {
  font-size: var(--desktop-fz14) !important;
}

.extend-my-event {
  display: flex !important;
}

@media screen and (min-width: 1650px) {
  .extend-my-event > button {
    min-width: 200px;
  }
}

@media screen and (max-width: 450px) {
  .extend-my-event {
    flex-direction: column;
  }
}
.event2-filter {
  display: flex;
  gap: 10px;
  height: fit-content;
  width: fit-content;
}

.filter-button {
  border-radius: 6px;
  border: 1.237px solid #000;
  color: var(--pbss-p1, #005d61);
  font-size: 15.96px;
  font-style: normal;
  font-weight: var(--desktop-fw450);
  line-height: 25.08px;
  /* 157.143% */
}

.event2-selection {
  display: flex;
  width: 350px;
  padding: 7px 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 9.194px;
  flex-shrink: 0;
  border-radius: 6px;
  border: 1px solid #000;
  background: linear-gradient(
    47deg,
    #bce8ea -85.95%,
    rgba(255, 255, 255, 0) 216.03%
  );
}
.event2-speakers-list {
  font-size: var(--desktop-fz15) !important;
  margin-inline-end: 0px !important;
}
.event2-selection-selector {
  background: none !important;
  border: none !important;
  box-shadow: none !important;
}

.event2-selection-arrow {
  width: 15.134px;
  height: 15.134px;
  font-size: var(--desktop-fz15) !important;
  color: #005d61 !important;
}

.event2-listing-cards {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.event2-filter-container {
  display: flex;
  gap: 10px;
}

.evnet-wapper-container {
  display: flex;
  border: 0.573px solid #d9d9d9;
  background: linear-gradient(
    274deg,
    rgba(117, 173, 176, 0.16) 74.25%,
    rgba(251, 251, 251, 0) 191.17%
  );
}

.event2-banner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border-radius: var(--Count, 0px);
  border-right: 0.573px solid #d9d9d9;
  background: linear-gradient(
    274deg,
    rgba(117, 173, 176, 0.16) 74.25%,
    rgba(251, 251, 251, 0) 191.17%
  );
}

.event2-wapper-date > p {
  color: #1f5f62;
  text-align: center;
  width: 80px;
  font-size: 32.546px;
  font-style: normal;
  font-weight: var(--desktop-fw900);
  line-height: 33px;
  /* 101.394% */
}

.event2-wapper-date {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
  width: 100%;
  align-items: center;
}
.by-date-input ::-webkit-input-placeholder {
  color: var(--pbss-p1, #005d61) !important;
}
.by-date-input > input {
  cursor: pointer;
}
.event2-banner > label {
  display: flex;
  width: 174px;
  height: 47px;
  padding: 3.615px 14.461px;
  justify-content: center;
  align-items: center;
  gap: 18.076px;
  flex-shrink: 0;
  border-radius: var(--Count, 0px);
  background: #1f5f62;
  color: white;
  color: var(--White, #fff);
  text-align: center;
  font-size: 19.692px;
  font-style: normal;
  font-weight: var(--desktop-fw700);
  line-height: normal;
}

.event2-wapper-date > span {
  color: #1f5f62;
  font-size: var(--desktop-fz18);
  font-style: normal;
  font-weight: var(--desktop-fw450);
  line-height: normal;
}

.by-date-suffix {
  color: #1f5f62 !important;
}

@media screen and (max-width: 768px) {
  .event2-wapper-date > p {
    width: auto;
  }
  .event2-wapper-date {
    flex-direction: row;
    justify-content: space-around;
  }
  .event2-banner {
    flex-direction: row;
  }
  .evnet-wapper-container {
    flex-direction: column;
  }
  .event2-listing-section1 {
    flex-direction: column;
    align-items: start;
    gap: 20px;
  }
}

/* @media screen and (max-width: 1200px) {
  .chapter-date-section {
    flex-direction: column;
    gap: 15px;
  }
} */
@media screen and (max-width: 900px) {
  .chapter-date-section-extend {
    flex-direction: column !important;
    gap: 15px;
  }
}
@media screen and (min-width: 900px) {
  .chapter-date-section-extend {
    flex-direction: row !important;
    gap: 15px;
  }
}
@media screen and (max-width: 500px) {
  .event2-banner > label {
    width: 100%;
  }
  .event2-wapper-date {
    flex-direction: column;
    justify-content: center;
  }
  .event2-banner {
    flex-direction: column;
    padding-top: 20px;
    gap: 10px;
  }
  .by-date {
    width: 100px !important;
  }
  .button-grid > button {
    width: 100% !important;
  }
}

@media screen and (max-width: 1450px) {
  .chapter-date-section > .listing-heading-container {
    width: 100% !important;
  }
}

.event2-listing-section1 > .listing-heading-container > p {
  color: var(--Black, #000);
  font-size: var(--desktop-fz28);
  font-style: normal;
  font-weight: var(--desktop-fw450);
  line-height: 132%;
  /* 36.96px */
}

.chapter-date-section > .listing-heading-container {
  width: 60%;
}

.event2-listing-section1 > .listing-heading-container > span {
  color: #666;
  font-size: var(--desktop-fz18);
  font-style: normal;
  font-weight: var(--desktop-fw450);
  line-height: 212%;
  /* 38.16px */
}

.chapter-date-section {
  display: flex;
  justify-content: space-between;
  padding: 35px 30px;
  border-radius: var(--Count, 0px);
  border: 1px solid #e0e0e0;
  background: #fbfbfb;
  box-shadow: -24px 4px 84px 24px rgba(0, 0, 0, 0.02);
  margin-bottom: 40px;
}

.event2-wapper {
  display: flex;
  align-items: end;
}

.button-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  /* Adjust the number of columns as needed */
  gap: 10px;
  margin: 10px 0px;
  /* Adjust the gap between buttons */
}

.button-grid > button {
  display: flex;
  width: 128px;
  height: 35px;
  padding: 2.422px 9.689px;
  justify-content: center;
  align-items: center;
  gap: 12.112px;
  font-weight: var(--desktop-fw700);
  border-radius: 16px;
  border: 1px solid #9f9f9f;
  cursor: pointer;
  background: white;
}

.active {
  background: var(--pbss-p1) !important;
  font-size: var(--desktop-fz18);
  color: white;
}

.by-date {
  display: flex;
  width: 136.034px;
  padding: 12px 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 9.194px;
  flex-shrink: 0;
  border-radius: 6px;
  border: 1px solid;
  background: linear-gradient(
    47deg,
    #bce8ea -85.95%,
    rgba(255, 255, 255, 0) 216.03%
  );
}

.by-date:hover {
  border: 1px solid;
}
