.common-hero-text-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.common-hero-text-heading {
  font-size: var(--desktop-fz28);
  font-weight: var(--desktop-fw500);
}

.common-hero-text-subheading {
  font-size: var(--desktop-fz18);
  font-weight: var(--desktop-fw400);
}

.common-hero-container-wrapper {
  display: flex;
  justify-content: space-between;
  padding: 12px 8% 12px 8%;
  background-color: #003885 !important;
  border: 1px solid #003885;
  color: #ffffff;
}

.common-hero-credit-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}
.common-hero-credit-heading {
  font-size: var(--desktop-fz24);
  font-weight: var(--desktop-fw500);
}

.common-hero-credit-amount {
  font-size: var(--desktop-fz24);
  font-weight: var(--desktop-fw400);
}

/* @media screen and (min-width: 1440px) {
  .common-hero-text-heading {
    font-size: var(--desktop-fz30);
  }
  .common-hero-text-subheading {
    font-size: var(--desktop-fz30);
  }
} */

/* @media screen and (max-width: 1024px) {
  .common-hero-text-heading {
    font-size: var(--desktop-fz28);
  }
  .common-hero-text-subheading {
    font-size: var(--desktop-fz28);
  }
} */

@media screen and (max-width: 768px) {
  .common-hero-text-heading {
    font-size: var(--desktop-fz24);
  }
  .common-hero-text-subheading {
    font-size: var(--desktop-fz18);
  }
  .common-hero-credit-amount,
  .common-hero-credit-heading {
    font-size: var(--mobile-fz24);
  }
  .common-hero-container-wrapper {
    flex-direction: column;
    gap: 15px;
  }
  .common-hero-credit-container {
    justify-content: flex-start;
  }
}

@media screen and (max-width: 480px) {
  .common-hero-text-container,
  .common-hero-credit-container {
    height: auto;
    padding: 10px 8%;
  }
  .common-hero-text-heading {
    font-size: var(--mobile-fz20);
    font-weight: var(--mobile-fw500);
    margin-right: 5px;
  }
  .common-hero-text-subheading {
    margin-top: 5px;
    font-size: var(--mobile-fz16);
    font-weight: var(--mobile-fw450);
  }
  .common-hero-credit-amount,
  .common-hero-credit-heading {
    font-size: var(--mobile-fz16);
  }
  .common-hero-container-wrapper {
    gap: 0px;
  }
}

@media screen and (max-width: 375px) {
  .common-hero-text-heading {
    font-size: var(--mobile-fz18);
  }
  .common-hero-text-subheading {
    font-size: var(--mobile-fz14);
    font-weight: var(--mobile-fw400);
  }
}
