/* add.css */
.main-form-container {
  display: flex;
  /* max-height: 100vh; /* Set a maximum height for the container */
  /* overflow-y: scroll; */
  /* height: 60vh; */
  /* max-height: 84vh;
  overflow-y: scroll; */
  justify-content: center;
  align-items: center;
  width: 70%;
  padding: 20px 40px;
  background-color: white;
}

.two-column-form {
  width: 100%;
  margin-bottom: 10px;
}

.input-container {
  display: grid;
  grid-template-columns: 1fr;
}

.custom-card {
  width: 100%;
  max-width: 800px;
}

.button-container {
  gap: 10px;
  display: flex;
  width: 100%;
  float: right;
  justify-content: space-between;
}
.form-flex-button {
  display: flex;
  float: left;
  gap: 10px;
}

.form-flex-button-right {
  display: flex;
  float: right;
  gap: 10px;
}

.chapterKeysWarning {
  min-width: 100%;
  color: gray;
  border: 1p solid #f0f0f0;
  display: flex;
  gap: 5px;
  margin-bottom: 24px;
}

.chapterKeysWarning > span {
  color: red;
}

.chpater-client-keys > div {
  margin-bottom: 0px;
}

@media screen and (max-width: 1200px) {
  .main-form-container {
    width: 100%;
  }
}
