.Commitee-listing-main {
  display: flex;
  flex-direction: column;
  gap: 25px;
  margin: 0 0 1% 0;
}

.Commitee-listing-container {
  display: grid;
  grid-template-columns: repeat(
    auto-fit,
    minmax(220px, 1fr)
  ); /* Adjust column width as needed */
  gap: 20px;
}

.Commitee-listing-container-advisors {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
  gap: 20px;
}

.Commitee-listing-heading > p {
  color: #2e56a4;
  font-size: var(--desktop-fz21);
  font-style: normal;
  font-weight: var(--desktop-fw600);
  line-height: normal;
}
.sub-heading-standing-committee {
  color: gray;
  font-size: var(--desktop-fz16);
  margin-top: 10px;
  font-style: normal;
  font-weight: var(--desktop-fw500);
  line-height: normal;
}
.Commitee-listing-sub-heading > p {
  color: #2e56a4;
  font-size: var(--desktop-fz20);
  font-style: normal;
  font-weight: var(--desktop-fw600);
  line-height: normal;
}

.Commitee-listing-heading > label {
  color: #282828;
  font-size: var(--desktop-fz18);
  font-style: normal;
  font-weight: var(--desktop-fw400);
  line-height: 199%; /* 35.82px */
}

@media screen and (max-width: 480px) {
  .Commitee-listing-main {
    gap: 30px;
  }
  .Commitee-listing-heading > p {
    font-size: var(--desktop-fz22);
  }
  .Commitee-listing-sub-heading > p {
    font-size: var(--desktop-fz18);
  }
  .Commitee-listing-heading > label {
    font-size: var(--desktop-fz14);
  }
}
