.main-perks-card {
  /* width: 100vw; */
  width: 100%;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.11);
  border-radius: 16px 16px 8px 8px;
  margin-bottom: 10px;
}

.perks-header {
  border-radius: 16px 16px 0px 0px;
  background: #2e55a4f1;
}

.sponsorPerksListing > .ant-row{
 row-gap: 10px !important;
}

.perks-header-layout {
  padding: 10px;
  padding-left: 20px;
  gap: 20px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  justify-content: space-between;
}

.perks-type {
  align-items: center;
  gap: 200px;
  display: flex;
}

.perks-type p {
  color: #fff;
  font-size: var(--desktop-fz18);
  font-style: normal;
  font-weight: var(--desktop-fw600);
}

.perks-type span {
  padding: 4px 15px;
  height: fit-content;
  min-width: fit-content;
  border-radius: 30.499px;
  background: #ff993b;
  color: #fff;
  font-size: var(--desktop-fz16);
  font-style: normal;
  font-weight: var(--desktop-fw600);
}

.perks-pricing-navigation {
  justify-content: end;
  align-items: center;
  gap: 77px;
  display: flex;
  flex-wrap: wrap;
}

.perks-pricing-navigation p {
  color: #fff;
  font-size: var(--desktop-fz18);
  font-style: normal;
  font-weight: var(--desktop-fw600);
}

.perks-pricing-navigation>div>button {
  color: var(--pbss-p1-dark);
  font-size: var(--desktop-fz16);
  font-style: normal;
  font-weight: var(--desktop-fw600);
  border-radius: 6px;
  border: 1px solid #fff;
  background: #fff;
  padding: 6px 10px;
  cursor: pointer;
  box-shadow: 0px 0px 20px 0px rgba(79, 112, 176, 0.36);
}

.perks-pricing-navigation>div>button:hover {
  box-shadow: 0px 0px 20px 7px rgba(48, 105, 217, 0.56);
  /* color: #fff; */
}

.upload-sponsor-assets-mobile,
.mobileGetSponsorButton {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px 10px;  
  margin: 10px;
  font-size: var(--mobile-fz16);
  font-weight: var(--mobile-fw600);
  color: var(--pbss-p1-dark);
  background-color: #fff;
  border: 1px solid var(--pbss-p1);
  border-radius: 6px;
  cursor: pointer;
  display: none;
}

.perks-body {
  background-color: #e1ebff;
}

.perks-body-layout {
  padding: 26px 26px;
  max-width: 100%;
}

.perks-gird-conatiner {
  padding: 0;
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(auto-fit, minmax(189px, 1fr));
  justify-items: center;
}

.perks-list {
  padding: 0;
  gap: 10px;
  justify-items: center;
  width: 100%;
}

.perks-item {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;
  padding: 10px 10px 10px 10px;
  justify-content: start;
  align-items: center;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0px 3.747px 31.847px 0px rgba(0, 0, 0, 0.07);
  max-width: 250px;
  min-height: 150px;
}

.perks-item-list {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 5px;
  padding: 10px 10px 10px 10px;
  justify-content: start;
  align-items: center;
}

.perks-list-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 20%;
  padding-right: 10px;
}

.perks-list-container-html {
  display: flex;
  width: 100%;
  padding: 10px;
}


.perks-list-container-html ul {
 margin: 0;
 padding: 0px 20px;
}

.perks-inter {
  font-family: Inter !important;
}

.perks-label {
  color: var(--Black, #000);
  font-size: var(--desktop-fz20);
  font-style: normal;
  font-weight: var(--desktop-fw600);
  line-height: 100%;
  display: flex;
  align-items: center;
}

.perks-key {
  color: #3e3e3e;
  font-size: var(--desktop-fz20);
  font-style: normal;
  font-weight: var(--desktop-fw500);
  line-height: 100%;
  flex: 1;
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: end;
}

.perks-key .anticon-check {
  font-size: 24px;
  font-weight: 700;
  color: #189249;
}

.availableSpotColor {
  color: #189249;
  margin: 0;
  padding: 0;
}

.perks-key div {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.align-right-perks-button {
  width: fit-content;
}

.perks-pricing-navigation-buttons-container {
  display: flex;
  gap: 10px;
  justify-content: flex-end;
}

@media screen and (max-width: 1440px) {
  .perks-type {
    gap: 100px;
  }

  .perks-pricing-navigation p {
    min-width: max-content;
  }

  .perks-pricing-navigation {
    gap: 20px;
  }

  .perks-list-container {
    gap: 10%;
  }
}

@media screen and (max-width: 1024px) {
  .align-right-perks-button {
    width: 100%;
  }

  .perks-type {
    gap: 30px;
  }

  .perks-pricing-navigation {
    gap: 20px;
  }

  .perks-pricing-navigation p {
    font-size: var(--desktop-fz16);
    min-width: max-content;
  }

  .perks-pricing-navigation>div>button {
    font-size: var(--desktop-fz16);
    padding: 7px 15px;
  }

  .perks-type p {
    font-size: var(--desktop-fz16);
  }

  .perks-type span {
    font-size: var(--desktop-fz16);
  }

  .perks-list-container {
    flex-direction: column;
  }
}

@media screen and (max-width: 768px) {
  .upload-button-container-perks-pricing-navigation {
    width: fit-content !important;
  }

  .perk-type {
    min-width: max-content;
  }

  .perks-type span {
    min-width: fit-content;
  }

  .perks-gird-conatiner {
    grid-template-columns: 1fr;
  }

  .perks-header-layout {
    grid-template-columns: 1fr;
  }

  .align-right-perks-button {
    width: fit-content;
  }

  .perks-item {
    max-width: 100%;
    display: flex;
    flex-direction: row;
    min-height: 70px;
    gap: 10px;
    padding: 5px 18px;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .perks-pricing-navigation>p {
    text-align: left !important;
    width: min-content !important;
  }
}

/* Media query for mobile screens  */
@media screen and (max-width: 830px) {
  .upload-button-container-perks-pricing-navigation {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }

  .perks-pricing-navigation {
    justify-content: space-between;
  }

  .perks-type {
    justify-content: space-between;
  }

  .perks-type p {
    font-size: var(--laptop-fz16);
  }

  .perks-type span {
    font-size: var(--laptop-fz14);
  }

  .perks-pricing-navigation {
    gap: 20px;
  }

  .perks-pricing-navigation p {
    font-size: var(--laptop-fz16);
  }

  .perks-pricing-navigation>button {
    font-size: var(--laptop-fz16);
    padding: 7px 15px;
  }

  .perks-body-layout {
    padding: var(--laptop-fz16);
  }

  .perks-label {
    font-size: var(--laptop-fz18);
  }

  .perks-key {
    font-size: var(--laptop-fz16);
  }

  .perks-key div {
    flex-direction: row;
  }

  .perks-key .anticon-check {
    font-size: 20px;
  }

  .perks-pricing-navigation>p {
    width: 100%;
    text-align: right;
  }
}

@media screen and (max-width: 550px) {
  .perks-list-container-html {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .perks-header-layout {
    margin-bottom: 0px;
  }

  .main-perks-card {
    margin-bottom: 0px;
    padding-bottom: 0px
  }

  .collapse-perks-card,
  .collapse-perks-card-item,
  .collapse-perks-card-header,
  .collapse-perks-card-content,
  .collapse-perks-card-content-box {
    padding: 0 !important;
  }

  .collapse-perks-card {
    border-radius: 16px 16px 0px 0px !important;
    border: none;
  }

  .collapse-perks-card-header {
    /* background-color: #04369a; */
    background-color: var(--pbss-p1);
    border-radius: 16px 16px 0px 0px !important;
    min-height: 100% !important;
  }

  .perks-pricing-navigation {
    display: none;
  }

  .mobileGetSponsorButton,
  .upload-sponsor-assets-mobile {
    display: block;
  }

  .collapse-perks-card-expand-icon {
    width: 40px;
  }

  .collapse-perks-card-expand-icon>div {
    position: absolute;
    top: 50%;
    right: 5%;
    transform: translateY(-50%);
  }
}

@media screen and (max-width: 480px) {


  .handalUploadAssets {
    color: var(--pbss-p1-dark);
    font-size: var(--desktop-fz18);
    font-style: normal;
    font-weight: var(--desktop-fw600);
    border-radius: 6px;
    border: 1px solid #fff;
    background: #fff;
    padding: 7.882px 19.705px;
    cursor: pointer;
    box-shadow: 0px 0px 20px 0px rgba(79, 112, 176, 0.36);
  }

  .perks-pricing-navigation>button {
    padding: 5px 11px;
  }

  .perks-gird-conatiner {
    grid-template-columns: 1fr;
    justify-items: center;
  }

  .perks-item {
    width: 100%;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    min-height: 70px;
    gap: 10px;
    padding: 5px 18px;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  }

  .perks-label {
    font-size: var(--mobile-fz15);
  }

  .perks-key {
    font-size: var(--mobile-fz15);
  }

  .collapse-perks-card-expand-icon>div {
    right: 8%;
  }

  .perks-type {
    gap: 10px;
  }

  .perks-list-container {
    list-style: none;
    padding: 10px 0px !important;
  }
}

@media screen and (max-width: 375px) {
  .perks-type {
    flex-direction: column;
    gap: 10px;
    align-items: flex-start;
  }

  .collapse-perks-card-expand-icon>div {
    right: 15%;
  }

  .perks-label {
    font-size: var(--mobile-fz14);
  }

  .perks-key {
    font-size: var(--mobile-fz14);
  }
}

@media screen and (max-width: 320px) {
  .perks-type {
    gap: 10px;
  }

  .perks-pricing-navigation {
    gap: 30px;
  }

  .collapse-perks-card-expand-icon>div {
    top: 70%;
    right: 8%;
  }
}