.discount-price-container {
  display: flex;
  justify-content: space-between;
}

.price-container {
  width: 100%;
  margin: 8px 0px;
  width: 100%;
  text-align: center;
}
.price-container-credit {
  width: 100%;
  margin: 8px 0px;
  width: 100%;
  text-align: center;
}
.price-label {
  color: rgb(108, 108, 108);
  font-size: var(--desktop-fz20);
  font-style: normal;
  font-weight: var(--desktop-fw600);
  line-height: 150%; /* 43.667px */
}
.price-txt {
  color: var(--black);
  font-size: var(--desktop-fz20);
  font-style: normal;
  font-weight: var(--desktop-fw600);
  line-height: 150%;
}
.final-price,
.total-blue {
  color: var(--pbss-p1) !important;
  font-size: var(--desktop-fz24) !important;
}
.total-text-cart {
  font-size: var(--desktop-fz24) !important;
}
.action-con {
  display: flex;
  justify-content: end;
}
.price-cart-divider {
  margin: 4px 0 !important;
}
.main-confirmation-content-container {
  display: flex;
  justify-content: end;
}
.extra-message {
  margin-top: 68px;
}
.extra-message-left {
  margin-left: 258px;
}
.extra-message-cart-left {
  margin-left: 800px;
}
.extra-message-cart-left {
  margin-left: 800px;
}
.extra-message-cart-left-credit {
  margin-top: 144px !important;
  margin-left: 800px;
}
.confirmation-container {
  margin: 10px 0px;
  display: flex;
  width: 300px;
  flex-direction: column;
  justify-content: end;
  align-items: center;
  padding: 10px 30px;
}
.main-container-payment {
  display: flex;
  justify-content: space-between;
}
.confirmation-container .ant-typography {
  color: var(--pbss-p1);
  font-family: var(--font-inter);
  font-size: var(--desktop-fz22);
  font-style: normal;
  font-weight: var(--desktop-fw500);
  line-height: 150%; /* 31.758px */
  margin: 0px;
}
.confirmation-action-button {
  width: 300px;
}

/* @media screen and (max-width: 668px) {
  .confirmation-container {
    flex-direction: column;
    gap: 10px;
    align-items: start;
  }
} */

@media screen and (max-width: 1440px) {
  .extra-message {
    margin-bottom: 10px;
    margin-left: 0px;
  }
  .main-container-payment {
    gap: 10px;
  }
  .extra-message-cart-left-credit {
    margin-top: 144px !important;
    margin-left: 420px;
  }
  .extra-message-cart-left {
    margin-left: 420px;
  }
}
@media screen and (max-width: 1024px) {
  .extra-message-cart-left {
    margin-left: 70px;
  }
}
@media screen and (max-width: 768px) {
  .confirmation-container p {
    font-size: var(--laptop-fz24);
  }
  .confirmation-container .ant-typography {
    font-size: var(--laptop-fz18);
  }
  .proceedToPayBtn span {
    font-size: var(--laptop-fz14);
    padding: 0px 15px !important;
  }
  .proceedToPayBtn {
    padding: 0px 15px !important;
    max-height: 40px !important;
  }
  .main-container-payment {
    display: flex;
    flex-direction: column-reverse;
  }
  .extra-message {
    margin: 0px;
  }
  .extra-message-cart-left-credit {
    margin-top: 0px !important;
    margin-left: 0px;
  }
}

@media screen and (max-width: 480px) {
  .btn-main-container {
    width: fit-content;
  }

  .price-container {
    flex: 2;
  }
  .price-container-credit {
    flex: 2;
  }
  .btn-main-container {
    flex: 1;
  }

  .btn-main-container .ant-space {
    width: 100%;
  }
  .confirmation-container {
    flex-direction: column;
    padding: 0px;
    gap: 10px;
    padding: 10px 20px;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
  .btn-main-container > button {
    padding: 10px !important;
    margin-bottom: 0px;
  }
  .confirmation-container .attendees-text {
    font-size: var(--laptop-fz20) !important;
  }
  .price-container-credit {
    width: 100%;
  }
  .price-container {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .confirmation-container p {
    font-size: var(--laptop-fz15);
    width: fit-content;
  }
  .confirmation-container .ant-typography {
    font-size: var(--laptop-fz14);
    width: fit-content;
  }
  .proceedToPayBtn span {
    font-size: var(--laptop-fz14);
    padding: 0px 5px !important;
  }
  .proceedToPayBtn {
    padding: 0px 10px !important;
    max-height: 40px !important;
  }

  .btn-main-container {
    display: flex;
    justify-content: start;
    width: 100%;
  }
  .action-con .ant-space .ant-space-item {
    width: 100%;
  }
  .action-con .ant-btn {
    width: 100%;
  }
  .price-label {
    font-size: var(--desktop-fz14);
  }
  .price-txt {
    font-size: var(--desktop-fz14);
  }
}

@media screen and (max-width: 350px) {
  .confirmation-container p {
    font-size: var(--mobile-fz16);
  }
  .confirmation-container .ant-typography {
    font-size: var(--mobile-fz10);
  }
  .proceedToPayBtn span {
    font-size: var(--mobile-fz12);
  }
  .proceedToPayBtn {
    padding: 2px 5px !important;
    max-height: 30px !important;
  }
}
