#checkbox {
  display: none;
}

.toggle {
  position: relative;
  width: 39px;
  height: 40px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 6px;
  transition-duration: 0.3s;
}

.mobile-menu .ant-drawer-header {
  background-image:url('./../../../Assets/pbssBackground.png');
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;
  padding: 12px 40px;
  padding-right: 20px;
}

.mobile-menu .ant-drawer-header-title {
  display: flex;
  justify-content: end;
}

.bars {
  width: 100%;
  height: 5px;
  background-color: var(--white);
  border-radius: 5px;
  transition-duration: 0.3s;
}
#checkbox:checked + .toggle #bar2 {
  transform: translateY(14px) rotate(60deg);
  margin-left: 0;
  transform-origin: right;
  transition-duration: 0.3s;
  z-index: 2;
}

#checkbox:checked + .toggle #bar1 {
  transform: translateY(28px) rotate(-60deg);
  transition-duration: 0.3s;
  transform-origin: left;
  z-index: 1;
}

#checkbox:checked + .toggle {
  transform: rotate(-90deg);
}

.drawer-menu {
  border-inline-end: none !important;
  font-weight: bold;
}
.drawer-menu-item-selected {
  background-color: var(--color-cadetblue-100) !important;
  color: var(--pbss-primary) !important;
}

.account-collapse {
  margin: 0 15px;
}

.account-collapse-header-text {
  display: flex;
  justify-content: center;
  color: var(--pbss-p1);
  font-size: var(--desktop-fz19);
  font-style: normal;
  font-weight: var(--desktop-fw500);
  line-height: 24px; /* 126.316% */
  letter-spacing: -0.38px;
}

.account-collapse-content-box {
  padding: 0 !important;
}

.auth-button-container {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin: 20px 15px;
}

.profile-auth-container {
  margin-top: 20px;
}

.drawer-menu .pbss-menu-item {
  padding: 0px !important;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.4px;
  color: var(--black) !important;
}

.mobile-menu > .ant-drawer-wrapper-body {
  max-width: 500px !important;
}
