.regin-heading {
  color: white;
  font-size: var(--desktop-fz20);
  font-style: normal;
  font-weight: var(--desktop-fw500);
  line-height: 100%;
}

.pbss-special-announcement {
  color: #ff7875 !important;
  font-weight: 400 !important;
  text-align: left;
  padding-bottom: 8px;
}

.align-right {
  display: flex;
  justify-content: end;
}

.tablist-jobAds {
  width: 300px;
  display: flex;
  flex-direction: row;
  justify-content: start;
}

.pbss-international-tab-list > .user-event-card-nav {
  margin-bottom: 0px !important;
  width: 100%;
  display: flex;
  align-items: end;
}

.pbss-international-tab-list
  > .user-event-card-nav
  > .user-event-card-nav-wrap {
  display: flex;
  justify-content: end;
  width: 100%;
  align-items: end;
}

.pbss-international-tab-list {
  align-items: end;
  display: flex;
  width: 50% !important;
  flex-direction: row;
}

.col-ads-listing {
  padding-left: 0px !important;
  padding-right: 0px !important;
  margin-left: -16px;
}

.jvm-tooltip.active {
  background-color: var(--pbss-p1) !important;
  color: white !important;
}

.fit-content-button {
  width: fit-content !important;
  min-width: 40%;
}

.d3-map > img {
  margin: 20px 0px;
  width: 100%;
  height: 400px;
  object-fit: contain;
}

.regin-label-text {
  color: white;
  font-size: var(--desktop-fz18);
  font-style: normal;
  font-weight: var(--desktop-fw600);
  line-height: 100%;
  /* 36px */
}

.chapter-navigation-button {
  position: relative;
  /* flex: 0.8; */
  overflow-x: scroll;
  display: flex;
  gap: 15px;
}

.chapter-navigation-button::-webkit-scrollbar {
  display: none;
}

/* Optional: You can also hide scrollbar for Firefox and Edge */
.chapter-navigation-button {
  scrollbar-width: none;
}

.chapter-navigation-button::-ms-scrollbar {
  display: none;
}

.scroll-back-button {
  position: fixed;
  left: 0px;
  background-color: transparent !important;
  color: var(--pbss-p1) !important;
  border: none !important;
}

.scroll-next-button {
  position: fixed;
  right: 0px;
  background-color: transparent !important;
  color: var(--pbss-p1) !important;
  border: none !important;
  height: fit-content;
  border-radius: 5px;
  padding: 10px;
  cursor: pointer;
  /* /transition: background-color 0.3s, color 0.3s; */
}

.scroll-next-button:hover {
  background-color: #297fb959 !important;
}

.scroll-back-button:hover {
  background-color: #297fb959 !important;
}

.chapter-navigation-button {
  scroll-behavior: smooth;
}

.chapter-navigation-button::-webkit-scrollbar {
  width: 0;
}

.chapter-navigation-button {
  scrollbar-width: none;
}

.chapter-navigation-button::-ms-scrollbar {
  display: none;
}

.regin-container {
  background: #003885 !important;
  color: whitesmoke !important;
  position: relative;
  justify-content: space-between;
  /* margin: 0.7rem 0.1rem; */
  background: var(--PBSS-BG-LI);
  padding: 6px 8%;
  display: flex;
  /* align-items: center; */
  gap: 2%;
}

@media screen and (min-width: 1600px) {
  .regin-container {
    padding: 10px 8% 10px 8%;
  }
}

.regin-head-container {
  width: fit-content;
  display: flex;
  flex-direction: column;
  gap: 0.4em;
}

.chapter-navigation-wrapper {
  flex: 1 1;
  overflow-x: hidden;
  display: flex;
  align-items: end;
  gap: 10px;
  width: 100%;
  justify-content: start;
}

.chapter-navigation-arrow-button {
  border-radius: var(--br-6xs);
  background: none;
  border: none;
  width: auto;
  min-height: 28px;
  height: fit-content;
  padding: 8px 10px;
  justify-content: center;
  display: flex;
  /* width: 100%; */
  align-items: center;
  gap: 16px;
  flex-shrink: 0;
  color: var(--pbss-p1);
  font-size: var(--desktop-fz16);
  font-style: normal;
  font-weight: var(--desktop-fw700);
  line-height: 150%;
  cursor: pointer;
  transition: all 0.3ms ease-in;
  /* margin-bottom: 15px; */
}

.chapter-navigation-arrow-button:hover {
  color: var(--white);
  background: var(--pbss-p1);
  transition: all 0.3ms ease-in-out;
}

.chapter-navigation-button {
  /* flex: 2; */
}

.chapter-navigation-button > button {
  background: none;
  border: none;
  width: auto;
  /* height: 68px; */
  height: fit-content;
  padding: 5px 10px;
  justify-content: center;
  display: flex;
  /* width: 100%; */
  align-items: center;
  gap: 15.886px;
  flex-shrink: 0;
  color: white;
  flex-direction: row-reverse;
  font-size: var(--desktop-fz14);
  font-style: normal;
  font-weight: var(--desktop-fw700);
  line-height: 150%;
  cursor: pointer;
  transition: all 0.3ms ease-in;
}

.chapter-navigation-button > button > img {
  width: 15px;
}

.chapter-navigation-button > button:hover {
  color: var(--mapColor);
  transition: all 0.3ms ease-in-out;
}

.chapters-select-selector {
  height: 49px !important;
  padding: 14px var(--Count, 0px) 14px 15px;
  justify-content: center;
  align-items: center;
  gap: 15px;
  border-radius: 5.91px !important;
  border: 1px solid var(--pbss-p1) !important;
}

.chapters-select-selection-placeholder {
  color: var(--pbss-p1) !important;
  font-size: 14.77px;
  font-style: normal;
  font-weight: var(--desktop-fw600);
  line-height: 150%;
}

.map-conatiner {
  flex: 0.8;
}

.jsmap-container {
  width: 100%;
  height: 500px;
}

.map-container {
  display: flex;
  justify-content: space-between;
}

.map-container-column {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Map Marker */
.jvm-marker {
  width: 23px;
  height: 23px;
  transform: translateY(-11.5px);
}

@media screen and (max-width: 1300px) {
  .chapter-navigation-button {
    flex: 2;
  }

  .map-container {
    flex-direction: column;
    gap: 30px;
  }
}

@media screen and (max-width: 1024px) {
  .chapter-navigation-arrow-button {
    padding: 8px 10px;
  }
}

@media screen and (max-width: 768px) {
  .pbss-international-tab-list {
    width: 80% !important;
  }
  .col-ads-listing {
    margin-left: 0px;
  }

  .pbss-map-row-container {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }

  .regin-container {
    flex-direction: column;
    gap: 20px;
  }

  .d3-map > img {
    height: 280px;
  }

  .map-conatiner {
    flex: 1;
  }
}

@media screen and (max-width: 468px) {
  .col-ads-listing {
    margin-left: 0px;
  }

  .jsmap-container {
    height: 250px !important;
    margin: 38px 0 !important;
    width: 80% !important;
  }

  .regin-container {
    padding: 5% 8%;
    margin: 0;
  }

  .regin-head-container {
    gap: 10px;
    display: flex;
    flex-direction: column;
  }

  .regin-heading {
    font-size: var(--desktop-fz22);
    display: flex;
    font-weight: var(--desktop-fw600);
    justify-content: center;
  }

  .regin-label-text {
    display: flex;
    justify-content: center;
    text-align: center;
    font-size: var(--desktop-fz14);
    font-style: normal;
    font-weight: var(--desktop-fw500);
    line-height: 150%;
  }

  .d3-map > img {
    height: 200px;
  }
}
