.page-title-card {
  display: flex;
  align-items: center;
  background-color: #f4f4f4;
  position: relative;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.page-image {
  width: 100%;
  height: 404.051px;
  justify-content: center;
  align-items: center;
  object-fit: cover;
  display: flex;
  opacity: 0.9;
}

.page-content {
  flex: 1;
  position: absolute;
  left: 8%;
}

.page-title {
  color: var(--Black, #ffffff);
  font-size: var(--desktop-fz36);
  font-style: normal;
  font-weight: var(--desktop-fw600);
  line-height: 150%;
}

.divider {
  height: 1px;
  width: 200px;
  border-radius: var(--Count, 0px);
  background: #c4c4c4;
  margin: 30px 0px;
}

.page-description {
  color: var(--Black, #ffffff);
  font-size: var(--desktop-fz22);
  font-style: normal;
  font-weight: var(--desktop-fw450);
  line-height: 150%; /* 36px */
}
