.static-container > p {
  color: #505050;
  font-size: var(--desktop-fz18);
  font-style: normal;
  font-weight: var(--desktop-fw450);
  line-height: 160%;
}

.static-container > span {
  color: var(--pbss-p1);
  font-size: var(--desktop-fz28);
  font-style: normal;
  font-weight: var(--desktop-fw500);
  line-height: 150%;
  /* padding: 12px 5px; */
  padding: 5px 5px 12px 5px;
  width: 100% !important;
  display: flex;
}

.list-sharing-text {
  color: #505050;
  font-size: var(--desktop-fz18) !important;
  font-style: normal;
  font-weight: var(--desktop-fw400) !important;
  line-height: 160%; /* 35.64px */
}

.static-container-card {
  display: flex;
  align-items: center;
  gap: 20px;
}

.card-body {
  background: #f2f6ff;
  padding: 0.8rem 1rem;
}

.card-body > label {
  color: #3a538b;
  font-size: var(--desktop-fz18);
  font-style: normal;
  font-weight: var(--desktop-fw500);
  line-height: 162%;
}

.static-container > label {
  color: var(--Black, #000);
  font-size: var(--desktop-fz20);
  font-style: normal;
  font-weight: var(--desktop-fw600);
  line-height: 162%;
}

.static-container > p {
  color: #505050;
  font-size: var(--desktop-fz14);
  font-style: normal;
  font-weight: var(--desktop-fw450);
  line-height: 85%;
}

.static-container > p > label {
  color: var(--Black, #000);
  font-size: var(--desktop-fz20);
  font-style: normal;
  font-weight: var(--desktop-fw600);
  line-height: 162%;
  margin-left: 10%;
}

.static-container {
  margin: 20px 0px;
}

.time-text {
  display: flex;
  margin: 20px 0px !important;
}

.static-container > li {
  color: #505050;
  font-size: var(--desktop-fz20);
  font-style: normal;
  font-weight: var(--desktop-fw450);
  line-height: 202%;
  /* 48.48px */
  margin: 10px 0px;
}

.vendor-show-card-container {
  border: 1px solid #e8e8e8;
  background: #fff;
  padding: 1.4rem;
  padding-top: 1rem;
  margin: 1.5rem 0;
  margin-bottom: 0;
  margin-top: 0;
}

.vendor-show-card-container > span {
  color: #2e56a4;
  font-size: var(--desktop-fz22);
  font-style: normal;
  font-weight: var(--desktop-fw500);
  line-height: 150%;
}

.vendor-show-card-container > div {
  padding: 10px 0px;
  padding-bottom: 0px;
}

.vendor-show-card-container > ul > div > li {
  color: #282828;
  font-size: var(--desktop-fz16);
  font-style: normal;
  font-weight: var(--desktop-fw400);
  line-height: 160%;
  /* 37.12px */
}

.time-flex-card {
  height: fit-content;
  display: flex;
  padding: 0 !important;
  align-items: center;
}

.time-flex-card > p {
  flex: 0.4;
  color: #282828;
  font-size: var(--desktop-fz16);
  font-style: normal;
  font-weight: var(--desktop-fw400);
  line-height: 162%;
}

.time-flex-card > label {
  flex: 0.8;
  color: #505050;
  font-family: Inter;
  font-size: var(--desktop-fz16);
  font-style: normal;
  font-weight: var(--desktop-fw700);
  line-height: 162%;
}

.time-card-container {
  padding-bottom: 0 !important;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.vendor-show-btext-container > p {
  color: #282828;
  font-size: var(--desktop-fz16);
  font-style: normal;
  font-weight: var(--desktop-fw400);
  line-height: 162%;
  /* 25.92px */
}

.vendor-show-btext-container > label {
  color: #505050;
  font-size: var(--desktop-fz16);
  font-style: normal;
  font-weight: var(--desktop-fw700);
  line-height: 162%;
}

.vendor-show-btext-container {
  display: flex;
  flex-direction: column;
  /* gap: 10px; */
}

.vendor-show-btext-container > span {
  color: var(--pbss-p1);
  font-size: var(--desktop-fz18);
  font-style: normal;
  font-weight: var(--desktop-fw500);
  line-height: 150%;
}
.vendor-show-card-container > ul {
  padding-left: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.time-card-container > ul {
  padding-left: 20px;
  margin: 0px !important;
}
@media screen and (max-width: 480px) {
  .list-sharing-text {
    font-size: var(--desktop-fz16) !important;
  }
  .static-container > span {
    font-size: var(--desktop-fz18);
    margin: 5px;
  }
  .commonInfo-image {
    height: 200px;
  }
  .common-image-text > p {
    font-size: var(--desktop-fz14);
  }
  .static-container-card {
    flex-direction: column;
    width: 100%;
  }
  .card-body {
    width: 100%;
    min-height: fit-content !important;
  }
  .card-body > label {
    font-size: var(--desktop-fz14);
  }
  .card-body > p {
    font-size: var(--desktop-fz12);
  }
  .vendor-show-card-container > span {
    font-size: var(--desktop-fz18);
  }
  .vendor-show-card-container > ul > div > li {
    font-size: var(--desktop-fz14);
  }

  .vendor-show-card-container {
    margin: 1.5rem 0;
  }
  .time-flex-card > p {
    font-size: var(--desktop-fz14);
  }
  .time-flex-card {
    flex-direction: column;
    align-items: start;
  }
  .vendor-show-card-container > div {
    padding: 10px 0;
  }
  .common-image-text {
    padding: 1rem !important;
  }
  .time-flex-card > label {
    font-size: var(--desktop-fz14);
  }
  .vendor-show-btext-container > p {
    font-size: var(--desktop-fz14);
  }
  .vendor-show-btext-container > label {
    font-size: var(--desktop-fz14);
  }
}

@media screen and (max-width: 768px) {
  .card-body {
    width: 100%;
    min-height: 130px;
  }
  .common-image-text {
    padding: 2rem;
  }
  .time-flex-card > p {
    flex: 0.6;
  }
  .time-flex-card > label {
    flex: 0.4;
  }
}
