.upload-conatiner .ant-form-item-control-input-content {
  height: 240px !important;
  width: 100%;
}

.upload-dag-conatiner {
  padding: 0px 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  justify-content: center;
}

.logo-form-item {
  flex: 0.5;
}

.logo-form-item-a {
  flex: 0.5;
}

.upload-space {
  width: 100%;
}

.upload-conatiner {
  margin: 1.5em 0;
  display: flex;
  gap: 40px;
}

.upload-space-item {
  width: 100%;
}

.upload-conatiner .ant-upload-list {
  max-width: 50%;
  min-width: fit-content;
}

.upload-dag-conatiner > label {
  color: var(--black);
  font-size: var(--desktop-fz20);
  font-style: normal;
  font-weight: var(--desktop-fw700);
  line-height: 150%; /* 30px */
  cursor: pointer;
}

.upload-conatiner .ant-upload-drag {
  background: #fcffff !important;
}

.upload-conatiner .ant-upload-drag:hover {
  border: 1.5px dashed #74c1c5 !important;
}

.upload-dag-conatiner > button {
  border-radius: 5px;
  color: var(--black);
  font-size: var(--desktop-fz15);
  font-weight: var(--desktop-fw600);
  border: 1px solid var(--black);
  background: var(--White, #fff);
}

.upload-dag-conatiner > button:hover {
  border: 1px solid #4096ff !important;
  color: #4096ff !important;
}

.ant-progress-bg {
  background-color: #26858a !important;
}

.upload-text {
  color: #9a9a9ad7 !important;
  text-align: center;
  font-feature-settings: 'cv04' on, 'cv03' on, 'cv01' on;
  font-size: var(--desktop-fz14) !important;
  font-style: normal;
  font-weight: var(--desktop-fw450);
  line-height: 145%; /* 17.4px */
}

.sponsor-vendor-selector {
  background-color: black !important;
  color: white !important;
  height: auto !important;
  padding: 8px !important;
}
.sponsor-vendor-arrow {
  color: rgb(255, 255, 255) !important;
}

.options-container {
  display: flex;
  justify-content: space-between;
  gap: 15px;
}

.sponsor-vendor-selector:hover {
  border: 1px solid #207176 !important;
}

.sponsor-vendor-on-selector:hover {
  border: 1px solid #207176 !important;
}

.sponsor-vendor-on-selector {
  background-color: rgb(255, 255, 255) !important;
  color: rgb(0, 0, 0) !important;
  height: auto !important;
  padding: 8px !important;
}

.sponsor-vendor-disabled-selector {
  background-color: rgba(0, 0, 0, 0.04) !important;
  color: rgba(0, 0, 0, 0.25) !important;
  height: auto !important;
  border-color: #d9d9d9;
  padding: 8px !important;
}
.sponsor-vendor-selection-placeholder {
  color: rgb(255, 255, 255) !important;
}

.action-assets-submit {
  display: flex;
  justify-content: end;
}

.action-assets-submit .ant-form-item {
  margin-bottom: 0px;
}

@media screen and (max-width: 768px) {
  .upload-conatiner {
    flex-direction: column;
  }
}

@media screen and (max-width: 480px) {
  .upload-conatiner {
    gap: 20px;
  }
  .options-container {
    flex-direction: column;
  }
  .sponsor-vendor {
    width: 100% !important;
  }
  .sponsor-vendor-on {
    width: 100% !important;
  }
  .upload-conatiner .ant-form-item-control-input-content {
    height: 100% !important;
    margin: 0px;
  }
}

@media screen and (max-width: 1424px) {
  .logo-form-item {
    flex: 0.5;
  }

  .logo-form-item-a {
    flex: 0.5;
  }
}

@media screen and (max-width: 1004px) {
  .logo-form-item {
    flex: 0.5 !important;
  }

  .logo-form-item-a {
    flex: 0.5 !important;
  }
}
