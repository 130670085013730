.contact-us-info {
  color: gray;
  font-size: var(--desktop-fz16);
  line-height: 23px;
  width: 80%;
  margin: 0px 0px 30px !important;
}

.extend-contactUs {
  min-height: 200px !important;
}

.contact-us-container {
  max-width: 900px;
}

@media screen and (max-width: 768px) {
  .contact-us-info {
    width: 100%;
  }
  .extend-contactUs-form {
    flex-direction: column;
    gap: 0;
  }
}
