.sponsor-form-item-item {
  margin-bottom: 0 !important;
  border-radius: 4px;
}

.sponsor-form-item-item input {
  padding: 10px;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
}
.hide-sponsor {
  height: 0px !important;
}
.sponsor-form-item-item input:hover {
  border-color: var(--pbss-p1);
}

.sponsor-form-item-item input:focus {
  border-color: var(--pbss-p1);
}

.sponsor-form-label label {
  font-size: var(--desktop-fz15) !important;
  font-weight: var(--desktop-fw500);
}

.sponsor-form-label {
  gap: 6px !important;
  margin: 10px 0px;
}

.icon-style {
  color: var(--black) !important;
}

.sponsor-submit-container {
  display: flex;
  align-items: end;
  justify-content: end;
}

/* styles.css */

.event-sponsor-details {
  margin: 1em;
}
.sub-sponsor-wapper {
  gap: 20px;
  display: flex;
  max-width: 50%;
}

.sub-sponsor-wapper p {
  flex: 0.5;
}

.event-sponsor-details strong {
  font-weight: bold;
}

.event-sponsor-details p {
  margin-bottom: 10px;
}

.upload-list-name-fix-class-list-item-name {
  max-width: 350px;
}

@media screen and (max-width: 1440px) {
  .upload-list-name-fix-class-list-item-name {
    max-width: 250px;
  }
}

@media screen and (max-width: 1024px) {
  .upload-list-name-fix-class-list-item-name {
    max-width: 150px;
  }
}

@media screen and (max-width: 768px) {
  .upload-list-name-fix-class-list-item-name {
    max-width: 40vw;
  }
}

@media screen and (max-width: 480px) {
  .upload-list-name-fix-class-list-item {
    position: relative;
    height: auto;
    margin-top: 6px;
    font-size: 14px;
    display: flex;
    align-items: center;
    transition: background-color 0.3s;
  }
  .form-item-md {
    margin-bottom: 0px !important;
  }
}
