.want-to-see-your-ads-container {
  border: 1px solid #dde9ff;
}

.want-to-see-your-ads-card {
  width: 100%;
  padding: 25px 10px;
  display: grid;
  gap: 10px;
  background-color: var(--pbss-p2);
  text-align: start;
}

.extend-height {
  min-height: 400px;
}

.marquee--inner {
  display: block;
  position: absolute;
  animation: marquee 20s linear infinite;
  width: 100%;
}
.register-width {
  width: 400px !important;
}

.marquee--inner:hover {
  animation-play-state: paused;
}

.marquee--inner > span {
  display: flex;
  flex-direction: column;
}
.padding-top20 {
  margin-top: 10px;
}

@keyframes marquee {
  0% {
    top: 0;
  }
  100% {
    top: -100%;
  }
}

.want-to-see-your-ads-buttons-container {
  background-color: var(--PBSS-BG-LI);
  width: 99.8%;
  border-top: 1px solid #dde9ff;
  border-bottom: 1px solid #dde9ff;
  border-right: 1px solid #dde9ff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  bottom: 0px;
  padding: 5px 8px;
}

.margin20 {
  margin-bottom: 20px;
}

.scrolling-card {
  border-right: 1px solid #dde9ff;
}

.tab-view-mobile {
  margin-bottom: 0px !important;
}

.register {
  height: 550px !important;
}
.scrolling-none {
  height: auto !important;
  min-height: fit-content !important;
}
.margin-none {
  margin-bottom: 0px !important;
}
.for-register {
  line-height: 150%;
  margin-top: 0px;
}
.want-to-see-your-ads-listing-container {
  display: flex;
  flex-direction: column;
  gap: 0px;
}

.want-to-see-your-ads-title {
  color: var(--pbss-p1);
  font-family: Inter;
  font-size: var(--desktop-fz18);
  font-style: normal;
  font-weight: var(--desktop-fw700);
  line-height: normal;
}

.want-to-see-your-ads-description {
  color: var(--Black, #000);
  font-size: var(--desktop-fz16);
  font-style: normal;
  font-weight: var(--desktop-fw700);
  line-height: 140%;
}

.want-to-see-your-ads-button {
  border: 1px solid var(--pbss-p1);
  border-radius: 5px;
  background-color: var(--pbss-p1);
  padding: 3px 6px;
  color: #fff;
  text-align: center;
  font-size: var(--desktop-fz14);
  font-weight: var(--desktop-fw700);
  line-height: normal;
  width: 160px;
  height: 36px;
  cursor: pointer;
}

.want-to-see-your-ads-button:hover {
  background-color: #1a3b7a; /* Change background color on hover */
}

.want-to-see-your-ads-listing-card {
  padding: 10px;
  border-bottom: 1px solid #dde9ff;
  font-size: var(--desktop-fz14);
  font-weight: var(--desktop-fw400);
  display: flex;
  flex-direction: column;
}

.want-to-see-your-ads-listing-card span a {
  font-weight: var(--desktop-fw700);
  font-size: var(--desktop-fz14);
  text-decoration: underline;
  color: var(--pbss-p1);
}

.view-more-svg-button {
  background-color: none;
  background: none;
  border: none;
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: var(--desktop-fz14);
  font-weight: var(--desktop-fw700);
  color: var(--pbss-p3);
  line-height: 120%;
  padding: 0px 0px;
  cursor: pointer;
  box-shadow: none;
}
.view-more-svg-button:hover {
  color: var(--pbss-p3) !important;
}
.view-more-svg-button {
  background-color: none;
  background: none;
  border: none;
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: var(--desktop-fz14);
  font-weight: var(--desktop-fw700);
  color: var(--pbss-p3);
  line-height: 120%;
  padding: 0px 0px;
  cursor: pointer;
  box-shadow: none;
}

.want-to-see-your-ads-listing-container {
  height: 400px !important;
  /* overflow-y: scroll; */
}

.want-to-see-your-ads-listing-card.scrolling {
  opacity: 1;
  animation: scroll 20s infinite;
}

.want-to-see-your-ads-listing-container:hover {
  /* overflow-y: scroll; */
  /* Show scrollbar on hover */
  visibility: visible;
  overflow-x: hidden;
}

.want-to-see-your-ads-listing-container {
  overflow-x: hidden;
  overflow-y: none;
}

/* Optional: Customize scrollbar appearance */
.want-to-see-your-ads-listing-container::-webkit-scrollbar {
  width: 8px;
}

.want-to-see-your-ads-listing-container::-webkit-scrollbar-thumb {
  background-color: rgba(131, 131, 131, 0.405);
  border-radius: 10px;
  scroll-behavior: smooth;
  /* visibility: hidden; */
}

.want-to-see-your-ads-listing-container::-webkit-scrollbar-thumb:hover {
  visibility: visible;
}

.want-to-see-your-ads-listing-container::-webkit-scrollbar-track {
  background-color: #868686;
  visibility: hidden;
}

.want-to-see-your-ads-container-margin-for-chapPage {
  width: 100%;
}

@media screen and (min-width: 992px) and (max-width: 1250px) {
  .want-to-see-your-ads-buttons-container {
    /* flex-direction: column; */
    gap: 5px;
    padding: 5px 10px;
  }
  .want-to-see-your-ads-listing-card {
    padding: 10px 10px;
  }
  .want-to-see-your-ads-button {
    padding: 0px;
    height: 30px;
    width: 120px;
    font-size: var(--laptop-fz12);
  }
  .view-more-svg-button {
    padding: 0px;
  }
}

@media screen and (max-width: 1024px) {
  .want-to-see-your-ads-card {
    padding: 20px 15px;
    gap: 15px;
  }
  .mobile-view .user-event-card-tab-btn {
    font-size: var(--laptop-fz12) !important;
  }
  .want-to-see-your-ads-button {
    padding: 0px;
    height: 30px;
    width: 120px;
    font-size: var(--laptop-fz12);
  }
  .want-to-see-your-ads-title {
    font-size: var(--laptop-fz18);
  }
  .want-to-see-your-ads-description {
    font-size: var(--laptop-fz14);
  }
  .want-to-see-your-ads-button {
    font-size: var(--laptop-fz12);
  }
  .want-to-see-your-ads-listing-card span a {
    font-size: var(--laptop-fz14);
  }
  .view-more-svg-button {
    font-size: var(--laptop-fz14);
    padding: 0px 0px;
  }
  .want-to-see-your-ads-listing-card {
    padding: 10px 10px;
    font-size: var(--laptop-fz14);
  }
}

.want-to-see-your-ads-container-margin-for-chapPage {
  /* margin-bottom: 34px; */
}

@media screen and (max-width: 991px) {
  .want-to-see-your-ads-buttons-container {
    flex-direction: row;
    justify-content: space-between;
  }
  .want-to-see-your-ads-buttons-container > button {
    padding: 0px !important;
  }
}

@media screen and (max-width: 768px) {
  .want-to-see-your-ads-buttons-container {
    flex-direction: row;
    justify-content: space-between;
  }
}

@media screen and (max-width: 480px) {
  .want-to-see-your-ads-card {
    padding: 15px 25px;
    gap: 12px;
  }
  .want-to-see-your-ads-title {
    font-size: var(--mobile-fz14);
    font-weight: var(--mobile-fw700);
  }
  .want-to-see-your-ads-description {
    font-size: var(--mobile-fz12);
    font-weight: var(--mobile-fw700);
    line-height: 140%;
  }
  .want-to-see-your-ads-button {
    font-size: var(--mobile-fz10);
    font-weight: var(--mobile-fw700);
    height: 24px;
    width: 110px;
  }
  .want-to-see-your-ads-listing-card span a {
    font-size: var(--mobile-fz11);
    font-weight: var(--mobile-fw700);
  }
  .view-more-svg-button {
    font-size: var(--mobile-fz11);
    font-weight: var(--mobile-fw700);
    padding: 15px 0px;
  }
  .want-to-see-your-ads-listing-card {
    padding: 10px 10px;
    font-size: var(--mobile-fz11);
    font-weight: var(--mobile-fw700);
    text-align: start;
  }
}
