/* Event Card Styles */
.Event2-main-container {
  width: 100%;
  padding: 30px 40px;
  padding-bottom: 40px;
  display: flex;
  justify-content: space-between;
}

.my-events-2container {
  display: flex;
}

.my-event-Event2-main-container {
  padding-right: 0px;
}

.common-events-listing-wrapper {
  width: 100%;
}

.my-event-Event2-section2 {
  height: 100%;
  margin-top: 20px;
  justify-content: center !important;
}

.download-button {
  position: relative;
  border-width: 0;
  color: white;
  font-size: var(--desktop-fz15);
  font-weight: var(--desktop-fw600);
  border-radius: 4px;
  z-index: 1;
}

.refund {
  background: var(--pbss-p1);
  padding: 14px 27px;
  font-size: var(--desktop-fz17) !important;
  cursor: pointer;
}

.refund-button {
  gap: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  flex-direction: column;
  height: 100%;
  margin: 20px 30px 0px;
  justify-content: center !important;
}

.Event2-section1 {
  line-height: 50px;
  width: fit-content;
  max-width: 70%;
}

.my-event-event2-wapper-date {
  padding: 30px 20px;
}

.Event2-section1 > p {
  color: var(--Black, #000);
  font-size: var(--desktop-fz21);
  font-style: normal;
  font-weight: var(--desktop-fw600);
}

/* Add styles for Event2-section2 and other elements as needed */

.Event2-badge-container {
  display: flex;
  gap: 15px;
}

.Event2-group1-badge {
  display: flex;
  flex-direction: column;
  justify-content: end;
  gap: 10px;
}

.Event2-group1-badge > label {
  color: #191a1a;
  font-size: var(--desktop-fz12);
  font-style: normal;
  font-weight: var(--desktop-fw700);
  line-height: normal;
}

.Event2-date-badge {
  border-radius: 18.115px;
  background: #000;
  display: inline-flex;
  padding: 5.328px 10.656px !important;
  justify-content: center;
  align-items: center;
}

.Event2-common-text-white {
  color: #fff;
  text-align: center;
  font-size: 12.787px;
  font-style: normal;
  font-weight: var(--desktop-fw700);
  line-height: normal;
}

.Event2-location-badge {
  border-radius: 5.742px;
  background: #828282 !important;
}

.Event2-common-badge {
  border-radius: 4.845px;
  background: var(--Gray-4, #bdbdbd);
  display: flex;
  width: 77px;
  height: 27px;
  padding: 2.422px 9.689px !important;
  justify-content: center;
  align-items: center;
  gap: 12.112px;
  flex-shrink: 0;
  width: auto;
}

.Event2-event-dis-container {
  display: flex;
  margin-top: 40px;
}

.Event2-event-dis-container > p {
  margin: 0 !important;
  padding: 0 !important;
  color: #111010;
  font-size: var(--desktop-fz18);
  font-style: normal;
  font-weight: var(--desktop-fw450);
  line-height: 162%;
}

.Event2-event-dis-title {
  color: #111010;
  font-size: var(--desktop-fz18);
  font-style: normal;
  font-weight: var(--desktop-fw700);
  line-height: 162%;
  margin-right: 10px;
}

/* Add styles for other elements as needed */

.Event2-section2 {
  gap: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
}

/* Button Styles */
.Event2-section2 > button {
  border-radius: 6px;
  background: var(--pbss-p1);
  display: inline-flex;
  padding: 8px 20px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  color: var(--White, var(--White, #fff));
  font-size: var(--desktop-fz16);
  font-style: normal;
  font-weight: var(--desktop-fw700);
  line-height: 150%;
  border: none;
  cursor: pointer;
}

.Event2-section2 > p {
  color: #505050;
  font-size: 22.004px;
  font-style: normal;
  font-weight: var(--desktop-fw600);
  line-height: 162%;
  /* 38.887px */
}

/* Price Badge Styles */
.Event2-price-badge {
  border-radius: 14.768px;
  border: 1.207px solid #000;
  background: #fff;
  display: flex;
  width: 125px;
  height: 27px;
  padding: 4.344px 8.687px;
  justify-content: center;
  align-items: center;
  gap: 5.212px;
  flex-shrink: 0;
}

.Event2-side-border {
  border-left: 2px solid #b7b7b7;
  width: 100%;
  display: flex;
  justify-content: center;
}

.Event2-more-details {
  margin-top: 20px;
}

.black {
  background: black;
}

.white {
  background: white;
  color: var(--pbss-p1);
}

.draft {
  position: absolute;
  padding: 0px 30px;
  color: #061178;
}

.to-be-paid {
  position: absolute;
  padding: 0px 10px;
  color: #127f16;
}

.approved {
  position: absolute;
  padding: 0px 10px;
  color: #127f16;
}

.in_review {
  position: absolute;
  padding: 0px 10px;
  color: #780650;
}

.rejected {
  position: absolute;
  padding: 0px 15px;
  color: #96511f;
}

@media screen and (max-width: 900px) {
  .Event2-main-container {
    flex-direction: column;
  }

  .Event2-section1 {
    width: auto;
  }

  .Event2-section2 {
    display: grid;
    margin: 20px 0px;
  }
}

@media screen and (max-width: 450px) {
  .Event2-badge-container {
    flex-direction: column;
    gap: 0px;
  }
}

@media screen and (max-width: 1400px) {
  .my-events-2container {
    flex-direction: column-reverse;
    align-items: end;
  }

  .Event2-section2 > button {
    margin-right: 20px;
  }
}

/*--------------------------------------------------------------------------------
  New Styles
---------------------------------------------------------------------------------*/

.common-events-listing-card-content-wrapper {
  display: flex;
  width: 100%;
  /* height: 100%; */
  padding: 20px;
}

.common-events-listing-card {
  display: flex;
  gap: 40px;
  width: 100%;
  min-height: max-content;
  border: 0.5px solid #a1b6df;
  filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.09))
    drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.04));
  position: relative;
}

.common-events-listing-card-logo-container {
  min-height: 100%;
  min-width: 185px;
  max-width: 185px;
  padding: 0px 10px;
  background-color: #f8faff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.common-events-listing-card-textAndButton {
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: space-between;
}

.common-events-listing-card-textDetails {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.common-events-listing-card-textDetails-heading {
  font-size: var(--desktop-fz18);
  font-weight: var(--desktop-fw700);
}

.common-events-listing-card-textDetails-description {
  font-size: var(--desktop-fz16);
  font-weight: var(--desktop-fw450);
  line-height: 170%;
}

.link-no-style {
  text-decoration: none;
  color: white;
}

.common-events-listing-card-button {
  font-size: 14px;
  font-weight: 450;
  color: white;
  background-color: var(--pbss-p3);
  padding: 8px 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
}

.common-events-listing-card-button:hover {
  color: white !important;
  background-color: var(--pbss-p3) !important;
}

.common-events-listing-card-content-wrapper > div {
  gap: 15px;
}

.common-events-listing-card-status-container {
  position: absolute;
  top: 10px;
  right: 0;
}

.common-events-listing-card-buttons-container {
  display: flex;
  align-items: center;
  gap: 10px;
  min-width: 200px;
  flex-direction: column;
}

.common-events-listing-card-refund-button-container {
  height: 100%;
}

.common-events-listing-card-download-button-container button {
  border-radius: 6px;
  background: var(--pbss-p1);
  display: inline-flex;
  padding: 5px 20px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  min-width: 190px;
  color: var(--White, var(--White, #fff));
  font-size: var(--desktop-fz14);
  font-style: normal;
  font-weight: var(--desktop-fw500);
  border: none;
  cursor: pointer;
  height: 100%;
}

.myevents-complete-payment-btn-container {
  padding: 10px 20px !important;
}

.myEvents-sponsor-text-mobile {
  display: none;
}

.date-text-myevents {
  font-size: var(--desktop-fz18);
  font-weight: var(--desktop-fw600);
  color: #60759f;
}

.common-events-listing-card-refund-button-container button {
  border-radius: 6px;
  background: transparent;
  display: inline-flex;
  padding: 8px 20px;
  min-width: 190px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  color: var(--pbss-p1);
  font-size: var(--desktop-fz14);
  font-style: normal;
  font-weight: var(--desktop-fw500);
  border: 1px solid var(--pbss-p1);
  cursor: pointer;
  height: 100%;
}

.myEvents-listing-card-status-text {
  position: absolute;
  top: 11px;
  right: 35px;
  color: #fff;
  font-size: var(--desktop-fz14);
  font-weight: var(--desktop-fw500);
}

.myEvents-listing-card-status-approved {
  color: #145417;
}

.common-event-card-title-description-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.myEvents-listing-card-status-in_review {
  right: 20px;
}

.status-big-text {
  right: 5px;
  padding: 0px;
  font-size: var(--desktop-fz13);
}

.status-bigger-text {
  right: 5px;
  top: 5px;
  left: 25px;
  font-size: var(--desktop-fz13);
}

.myEvents-sponsor-text-desktop,
.myEvents-sponsor-text-mobile {
  border-radius: 5px;
  padding: 5px 10px;
  /* border: 0.5px solid #2f57a4; */
  background-color: #eff4ff;
}

.event-details-table-cell > h4 {
  display: flex;
  justify-content: center;
  align-items: center;
}

.event-details-table-thead > tr > th {
  background: var(--table-background) !important;
  border-radius: 0px !important;
  color: #fff !important;
  font-size: 15px;
  font-style: normal;
  font-weight: 600 !important;
  line-height: 100% !important;
  text-align: center !important;
}

.event-details-table-measure-row {
  display: block;
}

.event-details-table-thead ::before {
  position: fixed !important;
}

.event-details-modal-container {
  padding: 20px;
}

.evente-details-modal-wapper {
  display: flex;
  flex-direction: column;
}

.evente-details-modal-wapper > div {
  flex: 0.4;
}

.attendee-label {
  padding: 10px 0px !important;
}

.modal-container {
  border-top: 0.5px solid #a1b6df;
}

.modal-title-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* padding-right: 4rem; */
  border-bottom: 1px rgb(236, 236, 236) solid;
}

.modal-title-buttons {
  display: flex;
  align-items: center;
  gap: 1em;
  justify-content: center;
}

.event-registration-modal {
  height: 80% !important;
  width: 80% !important;
}

@media screen and (max-width: 1024px) {
  .date-text-myevents {
    font-size: var(--desktop-fz16);
  }
}

@media screen and (max-width: 600px) {
  .common-events-listing-card {
    padding: 20px !important;
  }
}

@media screen and (max-width: 900px) {
  .common-events-listing-card-buttons-container {
    /* flex-direction: row; */
  }

  .event-details-modal-container {
    padding: 1em 0px;
  }

  .common-events-listing-card-content-wrapper > div {
    gap: 10px;
  }

  .common-events-listing-card-refund-button {
    padding: 10px 20px !important;
  }

  .common-events-listing-card {
    flex-direction: column;
    gap: 20px;
    padding: 25px 20px 10px 20px;
  }

  .common-events-listing-card-logo-container {
    padding: 10px 0px;
    min-width: 100%;
  }

  .common-events-listing-card-content-wrapper {
    padding: 0px;
    padding-bottom: 15px;
    min-width: 100%;
  }

  .common-events-listing-card-button {
    width: 100%;
    padding: 20px 0px;
  }

  .common-events-listing-card-status-container {
    top: 0;
  }

  .date-text-myevents {
    font-size: var(--laptop-fz14);
  }

  .borderLeft {
    border-left: none;
  }
}

.table-total-cost {
  font-size: var(--desktop-fz16);
  font-weight: var(--desktop-fw700) !important;
  margin-bottom: 10px;
}

.table-edit-container {
  display: flex;
  align-items: center;
  padding-bottom: 0.8em;
  justify-content: space-between;
  gap: 10px;
  flex-wrap: wrap;
}

.table-cost-amount {
  display: flex;
  column-gap: 20px;
  row-gap: 10px;
  justify-content: space-between;
  flex-wrap: wrap;
}

.user-event-card
  .user-event-card-tab.user-event-card-tab-active
  .user-event-card-tab-btn {
  color: var(--pbss-p1) !important;
  font-weight: var(--desktop-fw700) !important;
  text-shadow: none !important;
}
.user-event-card-tab {
  padding-top: 0px !important;
}

.user-event-card-tab-btn {
  font-weight: var(--desktop-fw700) !important;
  text-shadow: none !important;
}

.user-event-card-tab-btn:hover {
  color: var(--pbss-p1) !important;
}

.user-event-card .user-event-card-ink-bar {
  background: var(--pbss-p1) !important;
}

@media screen and (max-width: 600px) {
  .event-registration-modal {
    height: auto !important;
    width: auto !important;
  }

  .modal-title-wrapper {
    display: flex;
    align-items: baseline;
    gap: 10px;
    flex-direction: column !important;
  }
}

@media screen and (max-width: 786px) {
  .common-events-listing-card-buttons-container {
    flex-direction: column;
  }
}

@media screen and (max-width: 480px) {
  .common-events-listing-card-content-wrapper {
    display: block;
  }

  .common-events-listing-card-buttons-container {
    flex-direction: column;
    width: 100%;
  }

  .common-events-listing-card-download-button-container button {
    width: 100%;
  }

  .common-events-listing-card-refund-button-container button {
    width: 100%;
  }

  .common-events-listing-card-refund-button-container {
    width: 100%;
  }

  .common-events-listing-card-download-button-container {
    width: 100%;
  }

  .myEvents-sponsor-text-desktop {
    display: none;
  }

  .myEvents-sponsor-text-mobile {
    display: block;
  }

  .details-for-mobile {
    display: flex;
    justify-content: space-between;
  }

  .date-text-myevents {
    font-size: var(--mobile-fz14);
  }
}

@media screen and (max-width: 375px) {
  .common-events-listing-card {
    gap: 10px;
  }

  .common-events-listing-card-textDetails {
    gap: 5px;
  }

  .common-events-listing-card-textDetails-heading {
    font-size: var(--mobile-fz16);
  }

  .common-events-listing-card-textDetails-description {
    font-size: var(--mobile-fz14);
  }

  .common-events-listing-card-button {
    font-size: var(--mobile-fz14);
  }

  .date-text-myevents {
    font-size: var(--mobile-fz12);
  }

  .myEvents-sponsor-text-mobile {
    font-size: var(--mobile-fz10);
    padding: 5px 20px;
  }

  .docs {
    font-size: var(--mobile-fz12);
  }

  .myevents-complete-payment-btn {
    font-size: var(--mobile-fz12);
  }

  .details-for-mobile {
    gap: 30px;
  }
}
