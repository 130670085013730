.static-container > p {
  color: #505050;
  font-size: var(--desktop-fz18);
  font-style: normal;
  font-weight: var(--desktop-fw450);
  line-height: 162%;
}

.common-image-text {
  padding: 1.5em 4em;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.1);
}

.common-image-text > p {
  color: #505050;
  font-size: var(--desktop-fz18);
  font-style: normal;
  font-weight: var(--desktop-fw500);
  line-height: 162%;
}

.static-container > label {
  color: var(--Black, #000);
  font-size: var(--desktop-fz20);
  font-style: normal;
  font-weight: var(--desktop-fw600);
  line-height: 162%;
}

.static-container > p > label {
  color: var(--Black, #000);
  font-size: var(--desktop-fz20);
  font-style: normal;
  font-weight: var(--desktop-fw600);
  line-height: 162%;
  margin-left: 10%;
}

.static-container {
  margin: 0px 0px !important;
  margin-bottom: 0 !important;
  margin-top: 20px !important;
}

.time-text {
  display: flex;
  margin: 20px 0px !important;
}

.vendor-show-card-container > div > li {
  font-size: 16px;
  line-height: 160%;
}

@media screen and (max-width: 375px) {
  .vendor-show-card-container > div > li {
    font-size: var(--mobile-fz14);
    font-weight: var(--mobile-fw400);
  }
}
