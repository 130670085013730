.sponsor-grid,
.sponsor-scroll {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(140px, 1fr));
  gap: 22px; /* Adjust the spacing between cards */
  justify-content: center;
}

.no-logo-scroll{
  display: flex !important;
  justify-content: start;
}

.sr-main-conatiner {
  padding: 1% 8%;
  background: var(--pbss-p1-light);
  background-image: url('../../../Assets/svgs/waves.svg');
  background-repeat: no-repeat;
  background-size: cover;
}

.btn-conatiner {
  visibility: hidden;
  display: none;
}

@media screen and (max-width: 550px) {
  .btn-conatiner {
    visibility: visible;
    display: flex;
    justify-content: center;
    padding: 20px;
  }
  .sponsor-grid {
    gap: 18px;
    justify-content: center;
    justify-items: center;
    width: 100%;
  }
  .sponsor-scroll {
    scrollbar-width: none;
    justify-content: start;
    scroll-behavior: smooth;
    display: flex;
    align-items: center;
    overflow: scroll;
    gap: 18px;
  }
  .sponsor-scroll::-webkit-scrollbar {
    width: 0;
  }
  .sponsor-scroll::-ms-scrollbar {
    display: none;
  }
}

@media screen and (max-width: 780px) {
  .sr-main-conatiner {
    padding: 4% 8% 5% 8%;
  }
}
@media screen and (max-width: 550px) {
  .sponsor-scroll {
    display: flex;
    align-items: center;
    overflow: scroll;
    gap: 22px;
  }
  .sr-main-conatiner {
    padding: 5% 8% 6% 8%;
  }
}

@media screen and (max-width: 480px) {
  .sr-main-conatiner {
    padding: 6% 8% 7% 8%;
  }
}
@media screen and (max-width: 350px) {
  .sponsor-single-column {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

/* // marque styling */

.sponsor-marquee {
  width: 100%;
  height: 105px;
  overflow: hidden;
  box-sizing: border-box;
  position: relative;
}

.sponsor-marquee-inner {
  width: 200%;
  position: absolute;
  display: flex;
  gap: 20px;
  animation: SponsorMarquee 30s linear infinite;
}

/* .sponsor-marquee-inner:hover {
  animation-play-state: paused;
} */

.sponsor-marquee-inner > span {
  float: left;
  width: 100%;
}

@keyframes SponsorMarquee {
  0% {
    left: 0;
  }
  100% {
    left: -100%;
  }
}
