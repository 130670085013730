.event-highlight-conatiner {
  display: flex;
  flex-direction: column;
}

.event-highlight-section1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.highlight-heading-conatiner {
  /* Add styles for the highlight heading container */
}

.highlight-heading-conatiner p {
  color: var(--Black, #000);
  font-size: 34.036px;
  font-style: normal;
  font-weight: var(--desktop-fw500);
  line-height: 150%;
  /* 51.055px */
}

.highlight-heading-conatiner span {
  color: #919191;
  font-size: 23.4px;
  font-style: normal;
  font-weight: var(--desktop-fw450);
  line-height: 150%;
  /* 35.1px */
}

.slider-action-conatiner {
  gap: 10px;
  display: flex;
}

.event-highlights-image-carousel {
  border-radius: 12.764px;
  width: 100%;
  height: 717.736px;
  object-fit: cover;
}
.event-highlight-section2 {
  display: flex;
  width: 100%;
  align-items: center;
  gap: 20px;
  justify-content: center;
}
.event-highlight-carousel {
  width: 100%;
}
.prev-button {
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: auto;
  margin-top: -22px;
  padding: 13px 16px;
  color: white;
  font-weight: bold;
  font-size: 21px;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 34px;
  border: none;
  right: 87%;
  user-select: none;
  z-index: 4;
}
/* .prev-button:hover,
.next-button:hover {
  background-color: rgba(255, 255, 255, 0.8);
} */
.next-button {
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: auto;
  margin-top: -22px;
  padding: 13px 16px;
  color: white;
  font-weight: bold;
  font-size: 21px;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 34px;
  border: none;
  left: 87%;
  user-select: none;
  z-index: 4;
}
img {
  /* Add styles for the image */
}

.highlight-inner-text {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  bottom: 0;
  color: white;
  width: 100%;
  border-bottom-left-radius: 12.764px;
  border-bottom-right-radius: 12.764px;
  background: var(--pbss-p1);
  padding: 15px 30px;
}

.highlight-inner-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  flex: 1;
}

.highlight-inner-title {
  color: var(--White, #fff);
  font-size: var(--desktop-fz28);
  font-style: normal;
  font-weight: var(--desktop-fw500);
  line-height: 115%;
}

.highlight-inner-container p {
  color: var(--White, #fff);
  font-size: var(--desktop-fz18);
  font-style: normal;
  font-weight: var(--desktop-fw700);
  line-height: 19.357px;
}

.highlight-badge {
  border-radius: 53.182px;
  border: 1.064px solid var(--White, #fff);
  padding: 4.344px 8.687px;
  width: fit-content;
  font-size: var(--desktop-fz16);
  font-style: normal;
  font-weight: var(--desktop-fw700);
  line-height: 24.676px;
  text-align: center;
  align-items: center;
}

.highlight-dis-text {
  color: #222;
  font-size: var(--desktop-fz18);
  font-style: normal;
  font-weight: var(--desktop-fw400);
  line-height: var(--desktop-lh170);
  margin-top: 10px !important;
  text-align: justify;
}

@media screen and (max-width: 1440px) {
  .prev-button {
    right: 86%;
  }
  .next-button {
    left: 86%;
  }
  .event-highlights-image-carousel {
    height: 620px;
  }
}
@media screen and (max-width: 1100px) {
  .prev-button {
    right: 84%;
  }
  .next-button {
    left: 84%;
  }
  .highlight-inner-text {
    flex-direction: column;
    align-items: start;
    gap: 20px;
  }
}

@media screen and (max-width: 550px) {
  .event-highlight-section1 {
    flex-direction: column;
    align-items: start;
    gap: 15px;
  }
}

@media screen and (max-width: 668px) {
  .event-highlights-carousel {
    height: 230px;
  }
  .event-highlight-conatiner {
    gap: 0px;
  }
  .highlight-dis-text {
    margin-top: 20px;
    font-size: 12.08px !important;
    font-style: normal;
    font-weight: var(--desktop-fw700);
    line-height: 21.283px;
  }
  .highlight-inner-text {
    padding: 10px 22px;
  }
  .highlight-inner-title {
    font-size: var(--desktop-fz16) !important;
    font-style: normal;
    font-weight: var(--desktop-fw700);
    line-height: 20px;
  }
  .highlight-inner-container p {
    font-size: var(--desktop-fz14) !important;
    font-style: normal;
    font-weight: var(--desktop-fw700);
    line-height: 15px;
  }
  .highlight-badge {
    font-size: 13.971px !important;
    font-style: normal;
    padding: 6px 15px;
    font-weight: var(--desktop-fw700);
    line-height: 15.024px;
  }
}

@media screen and (max-width: 768px) {
  .prev-button {
    padding: 4px 7px;
    right: 82%;
    top: 55%;
  }
  .next-button {
    padding: 4px 7px;
    left: 82%;
    top: 55%;
  }
  .event-highlights-image-carousel {
    height: 430px;
  }
  .highlight-dis-text {
    line-height: 25px;
  }
  .highlight-inner-title {
    font-size: var(--desktop-fz25);
    font-style: normal;
    font-weight: var(--desktop-fw700);
    line-height: 26px;
  }
  .highlight-inner-container p {
    font-size: var(--desktop-fz18);
    font-style: normal;
    font-weight: var(--desktop-fw700);
    line-height: 15px;
  }
  .highlight-badge {
    font-size: 18.971px;
    font-style: normal;
    font-weight: var(--desktop-fw700);
    line-height: 15.024px;
  }
}
@media screen and (max-width: 450px) {
  .highlight-inner-title {
    line-height: 20px;
  }
  .event-highlights-image-carousel {
    object-fit: contain;
    height: 100%;
  }
  .prev-button {
    font-size: 22px;
  }
  .next-button {
    font-size: 22px;
  }
}
@media screen and (max-width: 320px) {
  .prev-button {
    font-size: 22px;
    right: 78%;
    top: 60%;
  }
  .next-button {
    font-size: 22px;
    left: 78%;
    top: 60%;
  }
}
