.payment-form-container {
  border-radius: 8px;
  padding: 8%;
  background: rgba(255, 255, 255, 0.08);
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.11);
  margin-bottom: 20px;
}

.payment-sub-container {
  margin: 20px 0;
}

.payment-card-element {
  flex: 0.4;
}

.card-divider {
  flex: 0.1;
  align-items: center;
  display: flex;
  justify-content: center;
  height: 100%;
}

.card-divider .ant-divider {
  height: 44vh;
  font-size: 20px;
}

.payment-address-element {
  flex: 0.4;
}

.payment-form-heading > p {
  color: #5e5e5e;
  font-family: Inter;
  font-size: var(--desktop-fz16);
  font-style: normal;
  font-weight: 400;
  width: 80%;
  line-height: 135%;
  /* 21.6px */
}

.payment-form-heading > label {
  font-size: var(--desktop-fz22);
  font-style: normal;
  font-weight: 600;
  line-height: 160%;
}

.stripe-logo > img {
  width: 170px;
  height: 127px;
  border-radius: 8px;
}

@media screen and (max-width: 480px) {
  .payment-form-heading {
    margin: 10px 0;
  }
  .payment-form-heading > p {
    font-size: var(--desktop-fz14);
    width: 100%;
  }

  .stripe-logo > img {
    width: 100px;
    height: 57px;
  }
  .payment-form-container {
    padding: 2rem;
  }
  .payment-form-heading > label {
    font-size: var(--desktop-fz18);
  }
}

@media screen and (min-width: 716px) {
  .payment-form-heading-container {
    display: flex !important;
    justify-content: space-between;
  }
  .payment-form-container {
    padding: 3rem;
    padding-top: 2.6rem;
  }
}

@media screen and (max-width: 716px) {
  .stripe-logo > img {
    width: 120px;
    height: 77px;
  }
  .payment-form-heading-container {
    display: flex !important;
    flex-direction: column-reverse;
  }
}

@media screen and (max-width: 1085px) {
  .payment-sub-container {
    display: flex !important;
    flex-direction: column;
  }
  .card-divider .ant-divider {
    visibility: hidden;
    height: 0;
  }
  .card-divider {
    flex: 1;
    display: block;
  }
}
