@import './../../../Scss/shared.scss';
.layout {
  .ant-layout-header,
  .ant-layout-footer {
    color: #000;
    background-color: $blobstationWhite;
  }
  .ant-layout {
    width: 80%;
    background: #f5f5f5 !important;
  }
  .logo {
    float: left;
    width: 180px;
    height: 55px;
    margin: 4px 10px;
  }
  .small-logo {
    width: 50px;
    height: 50px;
    text-align: center;
    background: transparent;
  }
  .demo-logo-vertical {
    // background: #F7FDFC;
    // background-color: $blobstationWhite;
    padding-top: 15px;
    cursor: pointer;
    text-align: center;
    .divider {
      border-color: $blobstationBlack;
      margin: 0%;
    }
  }
  // .site-layout-content {
  //   padding: 10px;
  // }

  .footer {
    text-align: center;
  }
  .user-profile {
    text-align: center;
    background-color: #2e8096;
    color: $blobstationWhite;
    border-radius: 10px;
    .user-avatar {
      margin: 5px;
    }
    #components-dropdown-demo-arrow .ant-btn {
      margin-right: 18px;
      margin-bottom: 18px;
    }
    .ant-row-rtl #components-dropdown-demo-arrow .ant-btn {
      margin-right: 0;
      margin-bottom: 18px;
      margin-left: 18px;
    }
  }
}

@media screen and (max-width: 1440px) {
  // .ant-layout {
  //   width: 70% !important;
  // }
}
.loading-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.loading-dot {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin: 0 5px;
  box-shadow: 1px 1px 20px lightgray;
  animation: bounce 2s ease-in-out infinite, colorChange 4s ease-in-out infinite;
}

.loading-dot:nth-child(2) {
  animation-delay: 0.5s;
}

.loading-dot:nth-child(3) {
  animation-delay: 1s;
}

.loading-dot:nth-child(4) {
  animation-delay: 1.5s;
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}

@keyframes colorChange {
  0%,
  100% {
    background-color: #3498db; /* Blue */
  }
  25% {
    background-color: #e74c3c; /* Red */
  }
  50% {
    background-color: #2ecc71; /* Green */
  }
  75% {
    background-color: #f39c12; /* Orange */
  }
}
