@import './../../../Scss/shared.scss';
.main-layout {
  .ant-layout-header,
  .ant-layout-footer {
    color: #000;
    background-color: $blobstationWhite;
  }

  .logo {
    float: left;
    width: 200px;
    height: 50px;
    margin: 10px 24px;
  }

  .site-layout-content {
    background-color: transparent;
    // padding: 50px;

    .site-layout-content-box {
      // padding: 24px;
      width: 100%;
      background: #fff;
    }
  }

  .footer {
    height: 100px;
    text-align: center;
  }
}

.pbss-in-header{
  background-image: url('./../../../Assets/pbssBackground.png') !important;
}

.main-layout-container {
  margin-top: 100px;
}

.wapper-main-footer {
  width: 100%;
}

.pbss-hader-menu-light.pbss-hader-menu-horizontal > .pbss-hader-menu-submenu {
  display: flex;
  align-items: center;
  border-radius: 8px;
}
.pbss-hader-menu-submenu > .pbss-hader-menu .pbss-hader-menu-submenu-arrow {
  inset-inline-end: 4px;
}
.pbss-hader-menu-submenu-popup
  .pbss-hader-menu-vertical
  .pbss-hader-menu-submenu-title {
  padding-inline-end: 16px;
  padding-left: 8px;
  padding-right: 16px;
}

@media screen and (min-width: 1920px) {
  .intial-footer-background {
    background: #fbfbfb;
    border-top: 1px solid #dcdfe1;
  }
  .sm-footer-background {
    background: var(--pbss-p1);
  }
  .footer-main-container {
    display: flex;
    justify-content: center;
  }
  .wapper-main-footer {
    max-width: var(--page-size) !important;
    width: 100%;
  }
  .site-layout-content-box {
    max-width: var(--page-size) !important;
    width: 100%;
  }
  .main-layout {
    max-width: var(--page-size) !important;
  }
  .main-layout-container {
    justify-content: center;
    display: flex;
  }
}
@media screen and (max-width: 480px) {
  .main-layout-container {
    margin-top: 80px;
  }
}
@media screen and (min-width: 1300px) {
  .pbss-header-nav {
    padding: 15px 100px !important;
  }
}

@media screen and (max-width: 1520px) {
  .nav-bar {
    justify-content: space-between;
    padding: 15px 3.5% !important;
  }
  .pbss-hader-menu {
    width: 60% !important;
  }
}

@media screen and (max-width: 1120px) {
  .pbss-hader-menu {
    width: 60% !important;
  }
}

@media screen and (min-width: 2430px) {
  .nav-bar {
    padding: 15px 4% !important;
  }
}
