.timezoneContainer {
  margin-top: 10px;
  font-size: 16px;
  /* font-weight: bold; */
}

.timezoneLabel {
  font-size: 14px;
}

.timezoneValue {
  font-size: 14px;
}
.timeZone-warper {
  display: flex;
  gap: 20px;
}
