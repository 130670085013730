.promo-sub-button {
  color: var(--pbss-p1, #005d61) !important;
  font-size: var(--desktop-fz14);
  font-style: normal;
  font-weight: var(--desktop-fw700);
}

.promo-code-container > p {
  color: var(--Black, #000);
  font-size: var(--desktop-fz18);
  margin-bottom: 8px !important;
  font-style: normal;
  font-weight: var(--desktop-fw500);
  line-height: 150%; /* 24px */
}
.promo-sub-form {
  display: flex !important;
}

.promo-sub-conatiner .ant-input {
  border-color: white !important;
}
.promo-sub-conatiner .ant-input[disabled] {
  background-color: transparent !important;
}

.promo-sub-conatiner .ant-input:focus {
  box-shadow: none;
}
.promo-sub-conatiner {
  display: flex;
  width: fit-content;
  border-radius: 6px;
  border: 0.882px solid #b6b6b6;
}
.cart {
  padding: 10px 12px;
}

.promo-sub-conatiner .ant-input:hover {
  border-color: #ffffff;
  border-inline-end-width: 1px;
}

.promo-code-container {
  margin: 20px 0px;
}
