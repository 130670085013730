/* MajorInfoCard.css */

.majorInfo-container {
  display: flex;
  flex-direction: column;
  border-radius: var(--Count, 0px);
  border: 1px solid #d5d5d5;
  background: #fbfbfb;
  box-shadow: -20px 4px 54px -10px rgba(0, 0, 0, 0.06);
  margin-bottom: 15px;
}

.majorInfo-container .ant-divider-horizontal {
  margin-top: 0px;
  border-block-start: 2px solid rgba(5, 5, 5, 0.06);
}

.card-heading {
  color: var(--pbss-p1, #005d61);
  font-size: 32.06px;
  font-style: normal;
  font-weight: var(--desktop-fw700);
  margin-bottom: 15px;
}

.majorInfo-image {
  object-fit: cover;
  height: 381px;
}

.majorInfo-details {
  flex: 1;
  display: flex;
  justify-content: space-between;
  padding: 15px 40px;
}

.fz-16 {
  font-size: var(--desktop-fz16);
  color: #282828;
  font-weight: var(--desktop-fw400);
}

.majorInfo-description {
  color: #505050;
  font-size: var(--desktop-fz20);
  width: 80%;
  font-style: normal;
  font-weight: var(--desktop-fw500);
  line-height: 162%;
  display: flex;
  align-items: center;
  /* 40.5px */
}

.majorInfo-price {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.majorInfo-price p {
  color: var(--Black, #000);
  font-size: var(--desktop-fz20);
  font-style: normal;
  font-weight: var(--desktop-fw450);
  line-height: normal;
}

.majorInfo-price span {
  color: var(--Black, #000);
  font-size: 35.184px;
  font-style: normal;
  font-weight: var(--desktop-fw500);
  line-height: normal;
}

.info-body {
  padding: 0px 40px;
  padding-bottom: 30px;
  line-height: 50px;
}

.info-body p {
  color: var(--pbss-p1);
  font-size: var(--desktop-fz22);
  font-style: normal;
  font-weight: var(--desktop-fw500);
  line-height: 150%;

  /* 42px */
}
.for-major-sponsor {
  margin-bottom: 15px !important;
}

.info-body-text {
  color: var(--Black) !important;
  font-size: var(--desktop-fz18) !important;
  font-style: normal;
  font-weight: var(--desktop-fw500) !important;
  line-height: 20px;
  margin-bottom: 15px;
}

.info-body label {
  color: var(--Black);
  font-size: var(--desktop-fz18);
  font-style: normal;
  font-weight: var(--desktop-fw500);
  line-height: 20px;
  /* 35.64px */
}

.info-body > li {
  color: #282828;
  font-size: var(--desktop-fz16);
  font-style: normal;
  font-weight: var(--desktop-fw400);
  line-height: 222%;
  /* 38.88px */
}

.info-body > div {
  color: #636363;
  font-size: var(--desktop-fz18);
  font-style: normal;
  font-weight: var(--desktop-fw500);
  line-height: 150%;
  margin-top: 10px;
  /* 27px */
}

.sponsor-nav-container > button {
  min-width: fit-content;
  width: 100%;
  display: flex;
  padding: 10.272px 25.681px;
  justify-content: center;
  align-items: center;
  gap: 10.272px;
  flex-shrink: 0;
  border-radius: 7.704px;
  background: var(--pbss-p1);
  color: var(--white);
  font-size: var(--desktop-fz14);
  border: none;
  font-style: normal;
  font-weight: var(--desktop-fw500);
  line-height: 150%;
  border: 1px solid var(--pbss-p1);
  cursor: pointer;
  /* 34.933px */
}

.sponsor-nav-container > button:hover {
  background: var(--white);
  color: var(--pbss-p1);
  border: 1px solid var(--pbss-p1);
}

/* Add more styles as needed */

.sponsor-nav-container {
  margin: 20px 0;
}

@media screen and (max-width: 500px) {
  .info-body-text {
    font-size: var(--desktop-fz14) !important;
  }
  .info-body > li {
    font-size: var(--desktop-fz14) !important;
  }
  .majorInfo-price p {
    font-size: var(--desktop-fz18);
  }
  .majorInfo-image {
    height: 200px;
  }
  .sponsor-nav-container {
    display: flex;
    flex-direction: column;
    gap: 10px !important;
  }
  .majorInfo-details {
    flex-direction: column;
    padding: 20px;
    gap: 20px;
  }

  .majorInfo-description {
    width: 100%;
    font-size: var(--desktop-fz14);
  }

  .majorInfo-price {
    align-items: start;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .info-body > div {
    font-size: var(--desktop-fz14);
    gap: 15px;
    display: flex;
    flex-direction: column;
  }

  .majorInfo-price span {
    font-size: var(--desktop-fz25);
  }

  .info-body {
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding: 0 20px !important;
    line-height: normal;
  }
  .fz-16 {
    font-size: var(--desktop-fz14);
  }
}

@media screen and (min-width: 500px) {
  .sponsor-nav-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(240px, 3fr));
    /* gap: 20px; */
    grid-gap: 15px;
  }
}
