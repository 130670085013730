.sponsor-perk-table-thead > tr > .sponsor-perk-table-cell {
  background-color: #2d55a2 !important;
  color: #fff !important;
  font-size: 15px !important;
  font-style: normal;
  font-weight: 600;
  line-height: 100%; /* 15px */
  border-radius: 0px !important;
  height: 55px;
}
.sponsor-perk-table-thead > tr > .sponsor-perk-table-cell::before {
  display: none !important;
}
.sponsor-perk-table-thead > tr > .sponsor-perk-table-cell {
  background-color: #2d55a2 !important;
}
.sponsor-perk-container {
  padding: 100px 0px;
}
.sponsor-perk-table .ant-input::placeholder {
  font-size: 15px;
  font-weight: 600;
  color: var(--pbss-p1) !important;
}
.sponsor-perk-table .ant-input {
  background-color: #f2f6ff;
  border-color: #d8e5ff;
}
.sponsor-perk-table .ant-select-selector {
  background-color: #f2f6ff !important;
  border-color: #d8e5ff !important;
}
.sponsor-perk-table .anticon {
  color: var(--pbss-p1) !important;
}
.sponsor-perk-table .ant-select-selection-placeholder {
  font-size: 14px;
  font-weight: 600;
  color: var(--pbss-p1) !important;
}
.price-text {
  color: var(--black, #000);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%; /* 16px */
}
.free-container {
  display: flex;
  max-width: 74.001px;
  width: 100%;
  height: 27px;
  padding: 8px;
  justify-content: center;
  align-items: center;
  gap: 8px;
}
.free-icon {
  display: flex;
  align-items: center;
}
.sponsor-pricing {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
}
.sponsor-table-heading {
  border-radius: 8px var(--Count, 0px) var(--Count, 0px) var(--Count, 0px);
  background: #fb915a;
  max-width: 224px;
  width: 100%;
  height: 54px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.sponsor-table-heading-text {
  display: flex;
  align-items: center;
  color: #fff;
  font-size: 23px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%; /* 23px */
}
.sponsor-table-tag-red {
  border-radius: 4px;
  background: #ffe8e8;
  border-color: #ffe8e8 !important;
  color: #c57676;
  font-size: 12.42px;
  font-style: normal;
  font-weight: 500;
  line-height: 162%; /* 20.12px */
}
