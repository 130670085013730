.ads-container {
  width: 100%;
}
.ads-header-container {
  margin: 20px 0px;
}
.ads-header-heading {
  display: flex;
  align-items: center;
  color: #0e1c1d;
  font-size: var(--desktop-fz28);
  font-style: normal;
  font-weight: var(--desktop-fw500);
  line-height: 150%;
}
.ads-header-subheading {
  color: #7c7d7d;
  font-size: var(--desktop-fz16);
  font-style: normal;
  font-weight: var(--desktop-fw500);
  line-height: 150%;
}
.ads-listing-card {
  width: 100%;
  box-shadow: none !important;
}
.ads-content-container {
  width: 100%;
}

.ads-content-container .ant-alert-content {
  flex: none;
}

.ads-content-heading {
  display: flex;
}
.ads-content-text {
  color: #005d61;
  font-size: var(--desktop-fz26);
  font-style: normal;
  font-weight: var(--desktop-fw450);
  line-height: 150%;
  max-width: 1650px;
}
.ads-listing-button {
  display: flex;
  align-items: center;
  width: 100%;
  gap: 10px;
}
.ads-content-buttons {
  display: flex;
  justify-content: space-between;
  margin: 40px 0px 30px;
}
.ads-listing-button-container {
  display: flex;
  justify-content: flex-start;
}
.ads-search-box {
  display: flex;
  gap: 12px;
}
.ads-search-input::placeholder {
  color: #005d61 !important;
  font-size: var(--desktop-fz15);
  font-style: normal;
  font-weight: var(--desktop-fw450);
  line-height: 25.08px;
  padding-left: 10px;
}
.ads-search-input {
  background: none !important;
  display: flex;
  align-items: center;
  border-radius: 0px;
  border-radius: 6px;
}
.ads-search-input:focus {
  border-color: #000;
  box-shadow: none !important;
}
.ads-search-input-affix-wrapper:hover {
  border-color: #000 !important;
  box-shadow: none !important;
}
.ads-search-input-affix-wrapper {
  background: linear-gradient(
    47deg,
    rgba(188, 232, 234, 1) -85.95%,
    rgba(255, 255, 255, 0) 216.03%
  ) !important;
  border: 1px solid rgb(0, 0, 0);
}
.ads-filter-button {
  display: flex;
  padding: 23px 20px;
  justify-content: center;
  align-items: center;
  background: linear-gradient(
    47deg,
    rgba(188, 232, 234, 1) -85.95%,
    rgba(255, 255, 255, 0) 216.03%
  );
  border: 1px solid rgb(0, 0, 0);
}
.ads-filter-content {
  color: var(--pbss-p1);
  font-size: 17.96px;
  font-style: normal;
  font-weight: var(--desktop-fw450);
  line-height: 25.08px;
}
.ads-filter-icon {
  color: var(--pbss-p1);
  font-size: var(--desktop-fz18);
  display: flex !important;
  align-items: center;
}
.ads-filter-button:hover {
  border-color: var(--black) !important;
}
.ads-filter-apply {
  background: var(--pbss-p1) !important;
}
.ads-filter-apply:hover {
  background: var(--pbss-p1) !important;
}
.ads-filter-cancel {
  border-color: var(--pbss-p1) !important;
  margin-right: 8px;
  color: var(--pbss-p1) !important;
}
.ads-filter-cancel:hover {
  border-color: var(--pbss-p1) !important;
  color: var(--pbss-p1) !important;
}
.no-ads-alert {
  color: var(--pbss-p1);
  font-weight: var(--desktop-fw500);
  justify-content: center;
  height: 300px;
  flex-direction: column;
  align-items: center;
  display: flex;
  padding: 10px;
  border: 1px solid #ffffff !important;
  background: #ceeeef4d !important;
  display: flex !important;
}
.no-ads-alert .ant-alert-message {
  font-size: var(--desktop-fz20);
}
.no-ads-alert .ant-alert-icon {
  font-size: var(--desktop-fz20);
  color: var(--pbss-p1) !important;
}
@media screen and (max-width: 768px) {
  .ads-listing-buttons {
    justify-content: space-evenly !important;
  }
  .ads-listing-details-container {
    padding: 0px 48px !important;
  }
}

@media screen and (max-width: 425px) {
  .ads-content-buttons {
    display: grid;
    gap: 20px;
  }
  .ads-listing-buttons {
    display: grid !important;
  }
  .ads-listing-position-container {
    display: grid !important;
  }
  .ads-listing-heading {
    color: var(--pbss-p1);
    font-size: var(--desktop-fz21) !important;
    text-align: left;
    margin-top: 40px;
  }
  .ads-listing-subHeading {
    font-size: var(--desktop-fz19) !important;
  }
  .ads-listing-desc {
    font-size: var(--desktop-fz16) !important;
  }
}
