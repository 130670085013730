.jobs-details-container {
  width: 100%;
}
.jobs-details-header-container {
  margin: 20px 0px;
}
.jobs-details-heading {
  display: flex;
  align-items: center;
  color: #0e1c1d;
  font-size: var(--desktop-fz28);
  font-style: normal;
  font-weight: var(--desktop-fw500);
  line-height: 150%;
}
.jobs-details-subheading {
  color: #7c7d7d;
  font-size: var(--desktop-fz16);
  font-style: normal;
  font-weight: var(--desktop-fw500);
  line-height: 150%;
}
.jobs-details-listing-card {
  width: 100%;
  box-shadow: none !important;
}
.details-position-heading {
  color: var(--pbss-p1, #005d61);
  font-size: var(--desktop-fz31);
  font-style: normal;
  font-weight: var(--desktop-fw700);
  line-height: normal;
}
.details-position-text {
  color: var(--pbss-p1, #005d61);
  font-size: var(--desktop-fz31);
  font-style: normal;
  font-weight: var(--desktop-fw450);
  line-height: normal;
  margin: 0px 10px;
}
.details-date-container {
  display: flex;
  gap: 40px;
  margin: 10px 0px;
  align-items: center;
}
.details-date {
  display: flex;
  gap: 10px;
}
.calender-icon {
  font-size: var(--desktop-fz25);
  stroke-width: 1.797px;
  stroke: var(--black, #000);
}
.details-date-text {
  color: #575757;
  font-size: 19.269px;
  font-style: normal;
  font-weight: var(--desktop-fw500);
  line-height: 132%;
  display: flex;
  align-items: center;
}
.details-location-container {
  display: inline-flex;
  padding: 2.268px 9.071px;
  justify-content: center;
  align-items: center;
  border-radius: 21.615px;
  border: 0.899px solid #a0dadc !important;
}
.details-location-container:hover {
  border: 0.899px solid #a0dadc !important;
}
.details-location-text {
  color: var(--pbss-p1, #005d61);
  text-align: center;
  font-size: 13.607px;
  font-style: normal;
  font-weight: var(--desktop-fw700);
  line-height: normal;
  align-items: center;
}
.details-carousel-container {
  position: relative;
}
.details-location-text:hover {
  color: var(--pbss-p1, #005d61) !important;
}
.details-banner {
  margin: 35px 0px;
  border-radius: 13px;
  position: relative;
  width: fit-content;
}
.details-banner-image-container .ant-image {
  width: 100%;
  max-height: 450px;
}

.details-banner-image {
  width: 100%;
  border-radius: 13px;
  object-fit: cover;
  background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.4) 0%,
      rgba(0, 0, 0, 0.4) 100%
    ),
    lightgray 0px -301.666px / 99.941% 228.876% no-repeat;
}
.details-employer {
  bottom: 0px;
  border-radius: var(--Count, 0px) var(--Count, 0px) 13px 13px;
  background: linear-gradient(
    93deg,
    #0b5457 7.96%,
    rgba(11, 177, 184, 0.65) 311.47%
  );
  opacity: 0.9;
  padding: 20px 0px;
  width: 100%;
  position: absolute;
}
.details-employer-text {
  color: var(--Gray-6, #f2f2f2);
  font-size: var(--desktop-fz28);
  font-style: normal;
  margin-left: 20px;
  font-weight: var(--desktop-fw500);
  line-height: 24px; /* 85.714% */
}
.details-jobs-content {
  margin-top: 40px;
  display: flex;
}
.apply {
  justify-content: flex-end;
}
.details-description {
  display: grid;
  gap: 20px;
}
.details-requirement {
  display: grid;
  gap: 20px;
}
.details-description-heading {
  color: var(--pbss-p1, #005d61);
  font-size: 32.06px;
  font-style: normal;
  font-weight: var(--desktop-fw700);
  line-height: normal;
}
.details-description-text {
  color: #505050;
  font-size: var(--desktop-fz18);
  font-style: normal;
  font-weight: var(--desktop-fw450);
  line-height: 150%; /* 31.5px */
}
.details-requirement-heading {
  color: var(--pbss-p1, #005d61);
  font-size: var(--desktop-fz24);
  font-style: normal;
  font-weight: var(--desktop-fw700);
  line-height: normal;
}
.details-requirement-text {
  color: var(--black-black-20, #49505b);
  font-size: var(--desktop-fz15);
  font-style: normal;
  font-weight: var(--desktop-fw450);
  line-height: 24px; /* 160% */
}
.details-requirement-subHeading {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 7px;
  border-radius: var(--Count, 0px);
  background: var(--pbss-p1, #005d61);
  margin: 30px 0px;
  box-shadow: 0px 4px 8px 0px rgba(60, 60, 60, 0.06);
}
.subHeading-text {
  color: var(--Gray-6, #f2f2f2);
  display: flex;
  align-items: center;
  font-size: var(--desktop-fz28);
  font-style: normal;
  font-weight: var(--desktop-fw500);
  padding: 20px 0px 20px 40px;
  line-height: 24px; /* 85.714% */
}
.details-button {
  border-radius: 7.35px;
  background: var(--pbss-p1, #005d61);
  display: flex;
  padding: 20px 24px;
  justify-content: center;
  align-items: center;
  gap: 9.8px;
  flex-shrink: 0;
}
.details-button:hover {
  background: var(--pbss-p1, #005d61) !important;
}
.details-button-text {
  color: var(--White, var(--White, #fff));
  font-size: 19.6px;
  font-style: normal;
  font-weight: var(--desktop-fw500);
  line-height: 150%; /* 29.4px */
}

/* ----------------------------------------------- */

.job-qualification-content-row,
.details-jobs-content {
  border: 1px solid #a1b6df;
  padding: 20px;
  border-radius: 7px;
}

.employer-position-applyNow-wrapper {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.employer-position-wrapper {
  display: flex;
  gap: 5px;
  margin-bottom: 7px;
}

.employer-name {
  font-size: var(--desktop-fz24);
  font-weight: var(--desktop-fw700);
}

.job-position {
  font-size: var(--desktop-fz24);
  font-weight: var(--desktop-fw400);
}

.jobs-details-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.job-view-details-location-date-wrapper {
  display: flex;
  gap: 10px;
}

.job-view-details-description-text {
  font-size: var(--desktop-fz16);
  font-weight: var(--desktop-fw400);
}

.job-details-upper-box-column {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.job-view-details-requirement-text {
  font-size: var(--desktop-fz16);
  font-weight: var(--desktop-fw400);
}

.job-qualification-content-row {
  margin-bottom: 32px;
}

.details-button-container-mobile {
  display: none;
}

@media screen and (max-width: 768px) {
  .employer-name {
    font-size: var(--desktop-fz20);
  }
  .job-position {
    font-size: var(--desktop-fz20);
  }
  .details-button-container {
    display: none;
  }
  .details-button-container-mobile {
    display: flex;
    justify-content: end;
  }
  .job-small-details-wrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .details-button {
    border-radius: 7.35px;
    background: var(--pbss-p1, #005d61);
    display: flex;
    padding: 10px 15px;
    justify-content: center;
    align-items: center;
    gap: 9.8px;
    flex-shrink: 0;
  }
}

@media screen and (max-width: 550px) {
  .employer-name {
    font-size: var(--desktop-fz20);
  }
  .job-position {
    font-size: var(--desktop-fz20);
  }

  .details-jobs-content {
    padding: 10px;
  }
  .details-button-text {
    color: var(--White, var(--White, #fff));
    font-size: var(--desktop-fz18);
    font-style: normal;
    font-weight: var(--desktop-fw500);
  }
}

@media screen and (max-width: 450px) {
  .employer-name,
  .job-position {
    font-size: var(--desktop-fz18);
  }

  .details-requirement-heading {
    font-size: var(--desktop-fz18) !important;
  }
}
@media screen and (max-width: 400px) {
  .employer-position-wrapper {
    display: flex;
    flex-direction: column;
  }
}

/* --------------------------------------------------- */

@media screen and (max-width: 1440px) {
  .details-banner-image-container .ant-image {
    max-height: 357px !important;
  }
}
@media screen and (max-width: 1024px) {
  .details-banner-image-container .ant-image {
    max-height: 238px !important;
  }
}
@media screen and (max-width: 768px) {
  .details-requirement-heading {
    font-size: 26.06px;
  }
  .details-description-heading {
    font-size: 26.06px;
  }
  .details-location-container {
    padding: 2.268px 37.071px !important;
  }
  .details-position-text {
    font-size: var(--desktop-fz27);
  }
  .details-position-heading {
    font-size: var(--desktop-fz27);
  }
  .details-banner-image {
    height: 180px !important;
  }
  .details-employer-text {
    font-size: var(--desktop-fz18);
  }
  .details-banner-image-container .ant-image {
    max-height: 175px !important;
  }
}
@media screen and (max-width: 425px) {
  .details-date-container {
    display: grid !important;
    gap: 15px !important;
  }
  .apply {
    justify-content: center !important;
  }
  .details-requirement-heading {
    font-size: var(--desktop-fz20);
  }
  .details-description-heading {
    font-size: 21.06px;
  }
  .details-position-text {
    font-size: var(--desktop-fz20);
  }
  .details-position-heading {
    font-size: var(--desktop-fz20);
  }
  .details-employer-text {
    font-size: var(--desktop-fz14);
  }
}
