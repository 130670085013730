.heading-title {
  color: var(--pbss-v2);
  font-size: var(--desktop-fz25);
  font-style: normal;
  font-weight: var(--desktop-fw700);
  line-height: 150%;
  margin-bottom: 25px;
  box-shadow: 0 7px 14px 0 rgba(65, 69, 88, 0.1),
    0 3px 6px 0 rgba(0, 0, 0, 0.07) !important;
  background-color: white;
  padding: 20px 40px;
  align-items: center;
  border-radius: 10px;
}

.sub-heading-admin-table {
  font-size: var(--desktop-fz20);
  font-style: normal;
  font-weight: var(--desktop-fw700);
}
