.jobs-container {
  width: 100%;
}
.header-container {
  margin: 20px 0px;
}
.header-heading {
  display: flex;
  align-items: center;
  color: #0e1c1d;
  font-size: var(--desktop-fz28);
  font-style: normal;
  font-weight: var(--desktop-fw500);
  line-height: 150%;
}
.header-subheading {
  color: #7c7d7d;
  font-size: var(--desktop-fz16);
  font-style: normal;
  font-weight: var(--desktop-fw500);
  line-height: 150%;
}
.jobs-listing-card {
  width: 100%;
  box-shadow: none !important;
}
.content-container {
  width: 100%;
}
.content-heading {
  display: flex;
}
.content-text {
  color: #005d61;
  font-size: var(--desktop-fz26);
  font-style: normal;
  font-weight: var(--desktop-fw450);
  line-height: 150%;
  max-width: 1650px;
}
.listing-button {
  display: flex;
  align-items: center;
  width: 100%;
  gap: 10px;
}
.customized-modal-v2 .ant-card-head {
  border-style: none !important;
}

.customized-modal-v2 .ant-form-item {
  margin-bottom: 15px !important;
}

.customized-modal-v2-body .ant-card-body {
  padding: 20px !important;
}

.customized-modal-v2-body .ant-modal-body::-webkit-scrollbar-thumb {
  border-radius: 10px; /* Adjust as needed */
}

.customized-modal-v2-body .ant-modal-body::-webkit-scrollbar {
  width: 5px; /* Width of the scrollbar */
}

.common-input-container-v2 .ant-upload-select {
  padding: 20px 0 !important;
}

.form-input-text-area-class {
  height: 100px;
  max-height: 100px;
}

.content-buttons {
  display: flex;
  justify-content: space-between;
  margin: 40px 0px 30px;
}
.listing-button-container {
  display: flex;
  justify-content: flex-start;
}
.search-box {
  display: flex;
  gap: 12px;
}
.search-input::placeholder {
  color: #005d61 !important;
  font-size: var(--desktop-fz15);
  font-style: normal;
  font-weight: var(--desktop-fw450);
  line-height: 25.08px;
  padding-left: 10px;
}
.search-input {
  background: none !important;
  display: flex;
  align-items: center;
  border-radius: 0px;
  border-radius: 6px;
}
.search-input:focus {
  border-color: #000;
  box-shadow: none !important;
}
.search-input-affix-wrapper:hover {
  border-color: #000 !important;
  box-shadow: none !important;
}
.search-input-affix-wrapper {
  background: linear-gradient(
    47deg,
    rgba(188, 232, 234, 1) -85.95%,
    rgba(255, 255, 255, 0) 216.03%
  ) !important;
  border: 1px solid rgb(0, 0, 0);
}
.jobs-filter-button {
  display: flex;
  padding: 23px 20px;
  justify-content: center;
  align-items: center;
  background: linear-gradient(
    47deg,
    rgba(188, 232, 234, 1) -85.95%,
    rgba(255, 255, 255, 0) 216.03%
  );
  border: 1px solid rgb(0, 0, 0);
}
.jobs-filter-content {
  color: var(--pbss-p1);
  font-size: 17.96px;
  font-style: normal;
  font-weight: var(--desktop-fw450);
  line-height: 25.08px;
}
.filter-select .ant-select-selector {
  border-color: var(--pbss-p1) !important;
}
.filter-select .ant-select-selector:hover {
  border-color: var(--pbss-p1) !important;
}
.jobs-filter-icon {
  color: var(--pbss-p1);
  font-size: var(--desktop-fz18);
  display: flex !important;
  align-items: center;
}
.jobs-filter-button:hover {
  border-color: var(--black) !important;
}
.jobs-filter-apply {
  background: var(--pbss-p1) !important;
}
.jobs-filter-apply:hover {
  background: var(--pbss-p1) !important;
}
.jobs-filter-cancel {
  border-color: var(--pbss-p1) !important;
  margin-right: 8px;
  color: var(--pbss-p1) !important;
}
.jobs-filter-cancel:hover {
  border-color: var(--pbss-p1) !important;
  color: var(--pbss-p1) !important;
}
.no-jobs-alert {
  color: var(--pbss-p1);
  font-weight: var(--desktop-fw500);
  justify-content: center;
  height: 300px;
  flex-direction: column;
  align-items: center;
  display: flex;
  padding: 10px;
  border: 1px solid #ffffff !important;
  background: #ceeeef4d !important;
  display: flex !important;
}
.no-jobs-alert .ant-alert-message {
  font-size: var(--desktop-fz20);
}
.no-jobs-alert .ant-alert-icon {
  font-size: var(--desktop-fz20);
  color: var(--pbss-p1) !important;
}
.no-jobs-alert .ant-alert-content {
  flex: none;
}

.jobListingPageContentHeading {
  font-size: var(--desktop-fz22);
  font-weight: var(--desktop-fw500);
  line-height: 150%;
  color: #282828;
}

@media screen and (max-width: 768px) {
  .job-listing-buttons {
    display: grid !important;
    justify-content: space-evenly !important;
  }
  .content-buttons {
    flex-direction: column;
    gap: 10px;
  }
  .jobListingPageContentHeading {
    font-size: var(--desktop-fz18);
  }
}

@media screen and (max-width: 480px) {
  .jobListingPageContentHeading {
    font-size: var(--desktop-fz16);
  }
}

@media screen and (max-width: 425px) {
  .jobListingPageContentHeading {
    font-size: var(--desktop-fz14);
  }
  .content-buttons {
    display: grid;
    gap: 20px;
  }
  .job-listing-buttons {
    display: grid !important;
  }
  .job-listing-position-container {
    display: grid !important;
  }
  .position-button {
    padding: 25px 52px !important;
  }
  .job-listing-heading {
    color: var(--pbss-p1);
    font-size: var(--desktop-fz21) !important;
    text-align: left;
    margin-top: 40px;
  }
  .job-listing-subHeading {
    font-size: var(--desktop-fz19) !important;
  }
  .job-listing-desc {
    font-size: var(--desktop-fz16) !important;
  }
  .header-heading {
    font-size: var(--desktop-fz17);
  }
  .jobs-listing-card .ant-card-head {
    padding: 0px !important;
  }
}
