.add-attendee {
  border-radius: 6px;
  border: 0.872px solid var(--pbss-p1) !important;
  width: fit-content;
  height: 39px;
  color: var(--pbss-p1) !important;
  font-size: var(--desktop-fz14);
  font-style: normal;
  font-weight: var(--desktop-fw600);
  line-height: 150%;
  padding: 6.978px 17.445px;
  justify-content: center;
  align-items: center;
  gap: 6.978px;
  flex-shrink: 0;
}
.option-tag-container {
  display: flex;
  gap: 10px;
}

.add-attendee-container {
  display: flex;
  justify-content: end;
  border-radius: var(--Count, 0px);
  padding: 43px 78px;
  border: 1px solid #d6e3ff;
  background: #fff;
  box-shadow: 0px 4px 28px 0px rgba(204, 204, 204, 0.25);
}

.addAttendeeWrapperEventUserComponent {
  display: flex;
  justify-content: end;
}

.attendees-table .ant-table-footer {
  padding-top: 50px !important;
  padding: 10px 0px;
}

.food-options-selection {
  border: none;
  display: flex;
  padding: 5px 10px;
  justify-content: center;
  align-items: center;
  gap: var(--Count, 10px);
  flex-shrink: 0;
  background: rgba(128, 128, 128, 0.147);
  border-radius: 5.833px;
  font-size: 13.817px;
  font-style: normal;
  font-weight: var(--desktop-fw450);
  border-color: none;
  line-height: 120%; /* 16.58px */
}

.food-options-selection:hover {
  display: flex;
  padding: 5px 10px;
  justify-content: center;
  align-items: center;
  gap: var(--Count, 10px);
  flex-shrink: 0;
  border-radius: 5.833px;
  color: var(--pbss-p1, #005d61) !important;
  font-size: 13.817px;
  font-style: normal;
  font-weight: var(--desktop-fw450);
  line-height: 120%; /* 16.58px */
}

.optionactive {
  background: #e5f8ed;
}

.sponsor-navigate-container > p {
  color: #505050;
  font-size: var(--desktop-fz20);
  font-style: normal;
  font-weight: var(--desktop-fw450);
  line-height: 162%; /* 38.88px */
}

.sponsor-navigate-container > button {
  display: inline-flex;
  padding: 8px 20px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 6px;
  background: var(--pbss-p1, #005d61);
  color: var(--White, var(--White, #fff));
  font-size: var(--desktop-fz16);
  font-style: normal;
  border: none;
  font-weight: var(--desktop-fw400);
  line-height: 150%; /* 24px */
  cursor: pointer;
}

.add-attendee-container > button:hover {
  background: var(--pbss-p1, #000606) !important;
  color: var(--White, var(--White, #fff));
}

.sponsor-navigate-container {
  display: flex;
  align-items: center;
  gap: 20px;
}

.ant-select-show-search.ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  height: auto;
}
.ant-select-single.ant-select-show-arrow .ant-select-selection-item {
  white-space: normal;
  word-break: break-all;
}

.regularAttendeesTag {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 5px;
  /* color: var(--attendee-table-tag-text); */
  color: var(--attendee-table-tag-text);
  background-color: var(--attendee-table-tag);
  border: 1px solid var(--attendee-table-tag-border);
  text-wrap: auto;
}

.addAttendees-button-wrapper {
  display: flex;
  flex-direction: row;
  column-gap: 15px;
}

@media screen and (max-width: 950px) {
  .sponsor-navigate-container {
    flex-direction: column;
    align-items: start;
  }
}

@media screen and (max-width: 785px) {
  .addAttendees-button-wrapper {
    display: flex;
    flex-direction: column;
    row-gap: 15px;
  }

  .addAttendees-button-wrapper > button {
    width: 340px;
  }
}

@media screen and (max-width: 475px) {
  .addAttendees-button-wrapper {
    display: flex;
    flex-direction: column;
    row-gap: 15px;
  }

  .addAttendees-button-wrapper > button {
    width: 100%;
  }
}
.mandatory-formItem-textarea {
  width: auto;
  height: auto;
  border: 1px solid #d9d9d9;
  display: flex;
  border-radius: 7px;
  padding-left: 10px;
  column-gap: 2px;
  color: red;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.075);
}

.mandatory-formItem-textarea:hover {
  border: 1px solid #4095fe;
}

.mandatory-formItem-textarea:focus-within {
  border: 1px solid #4095fe;
  box-shadow: 0 1px 3px rgba(93, 122, 255, 0.5);
}

.mandatory-formItem-textarea > textarea {
  border: none;
  border-radius: 7px;
}
.contactus-textarea-item-has-error .contactus-textarea-item-control-input {
  border: 1px solid #ff4d4f;
  border-radius: 7px;
}

.contactus-textarea-item-has-error
  .contactus-textarea-item-control-input:hover {
  border: none;
  border-radius: 7px;
}

.contactus-textarea-item-has-error
  .contactus-textarea-item-control-input:focus-within {
  border: none;
  border-radius: 7px;
}

.contactus-textarea-item-has-error
  .contactus-textarea-item-control-input
  .mandatory-formItem-textarea {
  border: none;
}

.contactus-textarea-item-has-error
  .contactus-textarea-item-control-input
  .mandatory-formItem-textarea:hover {
  border: 1px solid #ffa39e !important;
  border-radius: 7px;
}

.contactus-textarea-item-has-error
  .contactus-textarea-item-control-input
  .mandatory-formItem-textarea:focus-within {
  border: 1px solid #ffa39e !important;
  box-shadow: 0 1px 2px 3px #fff3f1;
  border-radius: 7px;
}

.mandatory-formItem-textarea > textarea:focus {
  outline: none !important;
}

.mandatory-formItem-textarea > textarea::placeholder {
  /* outline: none !important; */
  color: #d6d6d6;
  font-weight: 400;
}

.remove-boxshadow {
  box-shadow: none !important;
}
