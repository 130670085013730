.confirmation-cart-container {
  color: #f5f8ff;
  background-color: #f5f8ff !important;
  align-items: center;
  display: flex;
  justify-content: space-between;
  box-shadow: 0px 4px 4px rgba(160, 160, 160, 0.22);
  padding: 20px 30px;
}
.confirmation-content {
  padding-bottom: 10px;
}
.confirmation-buttons {
  display: flex;
  gap: 10px;
  justify-content: flex-end;
}
.confirmation-heading {
  color: #2e56a4;
  font-size: var(--desktop-fz18);
  font-style: normal;
  font-weight: var(--desktop-fw700);
  line-height: 150%; /* 27px */
}
.confirmation-price {
  color: #2e56a4;
  font-size: var(--desktop-fz28);
  font-style: normal;
  font-weight: var(--desktop-fw700);
  line-height: 150%; /* 42px */
}
.confirmation-payment {
  border-radius: 6px;
  background: #2f57a4;
  color: var(--White, #fff);
  font-size: var(--desktop-fz14);
  font-style: normal;
  font-weight: var(--desktop-fw450);
  line-height: 150%; /* 21px */
}
.confirmation-payment:hover {
  background: #2f57a4 !important;
  color: var(--White, #fff) !important;
}
.confirmation-cancel {
  border-radius: 6px;
  border: 1px solid #2f57a4;
  color: #2f57a4 !important;
  font-weight: var(--desktop-fw500);
}
.confirmation-cancel:hover {
  border-color: #2f57a4 !important;
}
@media screen and (max-width: 425px) {
  .confirmation-cart-container {
    display: block;
    width: 100%;
  }
  .confirmation-payment {
    width: 100%;
  }
  .confirmation-cancel {
    width: 100%;
  }
}
