.listingWithFilterContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;
}

.filtered {
  justify-content: flex-end;
}

.add-new-listing-button {
  background-color: var(--pbss-p3);
  display: flex;
  gap: 5px;
  justify-content: center;
  align-items: center;
  font-size: var(--desktop-fz14);
  font-weight: var(--desktop-fw400);
  padding: 0px 20px;
}

.event-filter-type-container {
  display: flex;
}

.add-new-listing-button:hover {
  background-color: var(--pbss-p3) !important;
}

.add-new-listing-search-input {
  height: 35px;
}

.add-new-listing-search-input-affix-wrapper {
  border-radius: 0px;
  padding: 0px;
}

.add-new-listing-search-input-affix-wrapper:hover {
  border-color: var(--pbss-p3) !important;
}

.add-new-listing-search-input-affix-wrapper-focused {
  border-color: var(--pbss-p3) !important;
}

.add-new-listing-search-input-affix-wrapper input {
  font-size: var(--desktop-fz12);
  font-weight: var(--desktop-fw700);
  color: #000;
  margin-left: 10px;
}

.add-new-listing-search-input-suffix {
  background-color: var(--pbss-p3);
  width: 60px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.add-new-listing-search-input-suffix svg {
  width: 20px;
  height: 20px;
}

.add-new-listing-filter-with-label-container {
  display: flex;
}

.filter-search-container {
  display: flex;
  gap: 15px;
}

.filter-search-container-myEventsCart {
  width: 100%;
  justify-content: space-between;
}

.flex-spaceBetween-w100 {
  justify-content: space-between;
  width: 100%;
}

.mn-w-400px {
  min-width: auto;
}

.myevents-filter-buttons-container {
  display: flex;
  gap: 20px;
}

.buttonMyEvents {
  background-color: transparent !important;
  color: var(--pbss-p3) !important;
  border-style: none;
  box-shadow: none;
}

.buttonMyEvents-active {
  background-color: var(--pbss-p1-light) !important;
  color: white !important;
  border: 1px solid var(--pbss-p3);
}

.myevents-filter-buttons-container button {
  background-color: transparent;
  color: var(--pbss-p3);
  padding: 10px 20px;
  border-radius: 6px;
  border: 1px solid var(--pbss-p3);
  font-size: var(--desktop-fz14);
  font-weight: var(--desktop-fw400);
}

.active {
  background-color: var(--pbss-p3);
  color: white;
}

.registration-filter {
  margin-left: 20px;
}

.reg-navigation-btn.register-for-new-event {
  margin-left: 20px;
  font-size: var(--desktop-fz12) !important;
  font-weight: var(--desktop-fw700);
  justify-content: center;
  height: 37px !important;
  padding: 0 15px 0 15px !important;
  width: 250px;
}

@media screen and (max-width: 1500px) {
  .reg-navigation-btn.register-for-new-event {
    margin-left: 0px;
  }
  .event-filter-type-container {
    flex-direction: column-reverse;
    width: 100%;
    gap: 15px;
  }

  .registration-filter {
    margin-left: 0px;
  }
  .mn-w-400px {
    min-width: 400px;
  }
}
@media screen and (max-width: 1150px) {
  .reg-navigation-btn.register-for-new-event {
    margin-left: 0;
    justify-content: center;
  }
  .event-filter-type-container {
    width: auto;
  }

  .event-filter-type-container {
    display: flex;
    gap: 15px;
  }
  .registration-filter {
    margin-left: 0px;
  }
}

@media screen and (max-width: 450px) {
  .user-group {
    flex-direction: column;
    display: flex;
  }
  .user-button-wrapper {
    width: 100% !important;
  }
}

@media screen and (max-width: 968px) {
  .filter-search-container {
    width: 100%;
  }

  .filter-search {
    justify-content: end;
    width: auto;
  }

  .add-new-listing-search-container {
    flex: 1;
  }

  .add-new-listing-filter-with-label-container {
    flex: 1;
  }
}

@media screen and (max-width: 768px) {
  .add-new-listing-search-container {
    min-width: 280px;
  }
  .event-filter-type-container {
    width: 100%;
    gap: 15px;
    display: flex;
  }
  .user-button-wrapper {
    width: 50%;
  }

  .filter-search-container {
    flex-wrap: wrap;
  }

  .add-new-listing-filter-with-label-container {
    width: 100%;
    display: flex;
  }

  .add-new-listing-filter-with-label-container .event_type_select {
    flex: 1.5 !important;
  }

  .event_type_select_year {
    flex: 1 !important;
  }
}
