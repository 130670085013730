/* profile-view.css */

.profile-view-container {
  width: 240px;
  padding: 0px 20px;
  padding-bottom: 0px;
}

.profile-view-container > label {
  font-weight: bold;
  color: gray;
}

.profile-user-wapper {
  display: flex;
  align-items: center;
  margin: 10px 0;
}

.profile-user-wapper > span {
  height: 32px;
  width: 32px;
  align-items: center;
  background-color: orange;
  border-radius: 50%;
  box-sizing: content-box;
  cursor: inherit;
  display: flex;
  flex-direction: column;
  justify-content: center;
  outline: none;
  overflow: hidden;
  position: static;
  transform: translateZ(0px);
  transition: transform 200ms ease 0s, opacity 200ms ease 0s;
  box-shadow: 0 0 0 2px var(--ds-surface-overlay, #ffffff);
  border: none;
  margin: var(--ds-space-025, 2px);
  padding: var(--ds-space-0, 0px);
  font-size: inherit;
  font-family: inherit;
}

.profile-email-text {
  margin-left: 10px;
}
.profile-email-text > label {
  font-size: var(--desktop-fz12) !important;
  color: gray;
}
.profile-email-text p {
  margin: 0;
}

.main-profile-container > li {
  padding: 8px 20px !important;
  list-style-type: none;
  cursor: pointer;
  padding: 5px 0;
  font-weight: bold;
}

.main-profile-container {
  padding: 15px;
  display: flex;
  flex-direction: column;
}

.main-profile-container > a > li {
  padding: 8px 20px !important;
  list-style-type: none;
  cursor: pointer;
  padding: 5px 0;
  font-weight: bold;
}
.main-profile-container > a {
  color: black;
}

.main-profile-container > li:hover {
  background: rgba(234, 234, 234, 0.612);
}
.main-profile-container > a > li:hover {
  background: rgba(234, 234, 234, 0.612);
}
.profile-divider {
  margin: 10px 0;
}
.profile-view-container div {
  font-weight: bold;
  padding: 5px 0;
  overflow-wrap: anywhere;
}

.profile-view-container li:last-child {
  color: red;
}

.ms-auto {
  margin: 0 !important;
}

@media screen and (max-width: 340px) {
  .profile-view-container {
    padding: 0px !important;
    width: auto !important;
  }
  .main-profile-container > a > li {
    padding-left: 10px !important;
  }
}
