.slider-action-container {
  gap: 10px;
  display: flex;
}

.common-title-section1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  margin: 0px 0px 20px;
  line-height: 73%;
}

.past-event-title {
  margin: 0px;
  /* flex: 1; */
  /* margin-right: 40px; */
}

.current-opening-chap-page-margin {
  margin: 15px 0px;
}

/* .current-opning-responsive-for-title {
  margin-bottom: 20px !important;
} */

.common-title-container-chapPage p {
  font-size: var(--desktop-fz22) !important;
}

.common-title-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.common-title-container p {
  color: var(--white);
  font-size: var(--desktop-fz28);
  font-style: normal;
  font-weight: var(--desktop-fw500);
  line-height: 100%;
}

.common-title-container > span {
  color: var(--black);
  font-size: var(--desktop-fz18);
  font-style: normal;
  font-weight: var(--desktop-fw500);
  line-height: 162%; /* 30.94px */
}

/* @media screen and (max-width: 990px) {
  .past-event-title {
    margin: 0px 0px 20px;
  }
} */

@media screen and (max-width: 780px) {
  .common-title-container > span {
    color: var(--black);
    font-size: 15.08px;
    font-style: normal;
    font-weight: var(--desktop-fw400);
    line-height: 21.283px;
  }
  .common-title-container p {
    font-size: var(--desktop-fz25);
  }

  .common-title-section1 {
    align-items: center;
    /* margin: 30px 0px; */
    /* margin: 0px 0 20px 0; */
  }
}

@media screen and (max-width: 550px) {
  .common-title-container > span {
    color: var(--black);
    font-size: 12.08px;
    font-style: normal;
    font-weight: var(--desktop-fw400);
    line-height: 21.283px;
  }
  .common-title-container p {
    font-size: var(--desktop-fz20) !important;
    font-style: normal;
    font-weight: var(--desktop-fw500);
    line-height: 75%;
  }
  .common-title-section1 {
    display: flex;
    /* margin: 0px 0px 30px; */
  }
  .pricing-card-container {
    width: auto !important;
  }
  .current-opning-responsive-for-title {
    flex-direction: row !important;
    align-items: center;
    margin: 30px 0px;
  }
  .current-opening-responsive-for-buttons {
    width: 34px !important ;
    height: 24px !important ;
    font-size: var(--desktop-fz12) !important ;
    border-radius: 4px !important ;
  }
}

@media screen and (max-width: 375px) {
  .common-title-container p {
    font-size: var(--mobile-fz18) !important;
  }
}

/* @media screen and (min-width: 1050px) {
  .common-title-container p {
    min-width: 250px;
  }
} */
