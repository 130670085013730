@media print {
  .sponsor-table {
    font-size: 14px;
  }
  .my-receipt {
    font-family: Arial, Helvetica, sans-serif !important;
    display: contents !important;
    padding: 40px 20px !important;
  }

  .receipt-logo {
    width: 240px;
    height: 80px;
  }

  .event-wapper {
    display: flex;
    justify-content: space-between;
  }

  .detail > .value {
    font-weight: 600;
    width: fit-content;
  }
}
.detail > .value {
  max-width: 300px;
  line-height: normal;
  width: fit-content;
}

.sponsor-table {
  width: 100%;
  border-collapse: collapse;
}

.sponsor-table th,
.sponsor-table td {
  border: 1px solid #ddd;
  padding: 8px;
}

.sponsor-table th {
  background-color: #f2f2f2;
}


.sponsor-table td.col-cell-name {
  word-break: break-all; /* Breaks long name */
  width: 180px;
  max-width: 180px !important; /* Set max-width for name to avoid breaking the layout */
}

.sponsor-table th.col-span-name,
.sponsor-table td.col-cell-name {
  width: 180px;
  max-width: 180px !important; /* Fixed width for the name column */
}

.sponsor-table td.col-cell-email {
  word-break: break-all; /* Breaks long emails */
  width: 220px;
  max-width: 220px !important; /* Set max-width for emails to avoid breaking the layout */
}

.sponsor-table th.col-span-email,
.sponsor-table td.col-cell-email {
  width: 220px;
  max-width: 220px !important; /* Fixed width for the email column */
}




.recept-container {
  padding: 40px 40px;
}

/* styles.css */
.receipt-divider {
  margin: 10px 0px;
}

.event-details {
  width: 50%;
  line-height: 15px;
  margin: 0px auto;
}

.sponsor-detail-table-heading {
  margin: 10 auto;
}

.sponsor-text {
  margin-top: 5px;
}

.event-name {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: start;
  gap: 20px;
  justify-content: start;
}

.event-name > label {
  font-size: var(--desktop-fz26);
}

.event-name > p {
  font-size: var(--desktop-fz24);
  color: gray;
}

.event-details-container {
  display: flex;
  gap: 40px;
  justify-content: space-between;
}

.detail {
  display: flex;
  justify-content:start;
  gap: 10px;
  margin-bottom: 10px;
}

.center-container {
  text-align: center;
  margin: 20px auto;
}

.center-flex-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.payment-details-container {
  text-align: center;
  margin: 20px auto;
}

.payment-details {
  margin-top: 20px;
  width: 40%;
  align-items: center;
  float: right;
}

.payment-details p {
  font-size: var(--desktop-fz16);
}

.total-price,
.discount,
.discounted-price {
  display: flex;
  justify-content: space-between;
}

.download-button {
  position: relative;
  border-width: 0;
  color: white;
  font-size: var(--desktop-fz15);
  font-weight: var(--desktop-fw600);
  border-radius: 4px;
  z-index: 1;
}

.download svg polyline,
.download svg line {
  animation: docs 1s infinite;
}

@keyframes docs {
  0% {
    transform: translateY(0%);
  }

  50% {
    transform: translateY(-15%);
  }

  100% {
    transform: translateY(0%);
  }
}
