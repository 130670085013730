.no-event-sponsor-alert {
  color: var(--pbss-p1);
  font-weight: var(--desktop-fw500);
  justify-content: center;
  height: 300px;
  flex-direction: column;
  align-items: center;
  display: flex;
  padding: 10px;
  border: 1px solid #ffffff !important;
  background: #ceeeef4d !important;
  display: flex !important;
}
.no-event-sponsor-alert .ant-alert-message {
  font-size: var(--desktop-fz20);
  text-align: center !important;
}
.no-event-sponsor-alert .ant-alert-icon {
  font-size: var(--desktop-fz20);
  color: var(--pbss-p1) !important;
}
.no-event-sponsor-alert .ant-alert-content {
  flex: none;
}
