.mj-sponsor-grid,
.mj-sponsor-scroll {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 18px; /* Adjust the spacing between cards */
  justify-content: center;
}

.mj-main-conatiner {
  padding: 2% 8% 4% 8%;
  background: rgba(241, 246, 255, 0.42);
}

.btn-conatiner {
  visibility: hidden;
  display: none;
}

/* @media screen and (max-width: 550px) {
  .mj-sponsor-grid {
    grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  }
  .btn-conatiner {
    visibility: visible;
    display: flex;
    justify-content: center;
    padding: 20px;
  } */

@media screen and (max-width: 550px) {
  .btn-conatiner {
    visibility: visible;
    display: flex;
    justify-content: center;
    padding: 20px;
  }
  .mj-sponsor-grid {
    gap: 18px;
    justify-content: center;
    justify-items: center;
    width: 100%;
  }
  .mj-sponsor-scroll {
    scrollbar-width: none;
    justify-content: start;
    scroll-behavior: smooth;
    display: flex;
    align-items: center;
    overflow: scroll;
    gap: 18px;
  }
  .mj-sponsor-scroll::-webkit-scrollbar {
    width: 0;
  }
  .mj-sponsor-scroll::-ms-scrollbar {
    display: none;
  }
}
@media screen and (max-width: 350px) {
  .mj-sponsor-single-column {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
