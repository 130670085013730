.login-divider {
  height: 50vh;
  width: 2px;
}

.login-container {
  width: auto;
  margin-top: 0px;
}
/* .login-form {
  padding-right: 150px;
} */
.main-opening-container {
  gap: 20px;
  padding: 30px;
  padding-right: 100px;
  max-width: 60%;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.common-input-user-login {
  border-radius: 6px;
  border: 0.882px solid #d6d6d6;
  background: rgba(247, 247, 247, 0.9);
  width: 495px;
  padding: 15px 20px;
  display: flex !important;
  align-items: center;
  font-size: var(--desktop-fz18) !important;
  font-weight: var(--desktop-fw500) !important;
}
.common-input-user-login .ant-input-affix-wrapper > input.ant-input {
  font-size: var(--desktop-fz18) !important;
  font-weight: var(--desktop-fw500) !important;
}
.common-input-user-login > input {
  background: none;
}

.common-input-user-login::placeholder {
  color: var(--Black, #000);
  font-style: normal;
  font-size: var(--desktop-fz17);
  font-weight: var(--desktop-fw500);
  line-height: 150%; /* 26.471px */
  display: flex;
  align-items: center;
}
.common-input-user-login .ant-input::placeholder {
  color: var(--Black, #000);
  font-style: normal;
  font-size: var(--desktop-fz17);
  font-weight: var(--desktop-fw500);
  line-height: 150%; /* 26.471px */
  display: flex;
  align-items: center;
}

.password-options-login > a {
  color: #6c6c6c;
  font-size: var(--desktop-fz18);
  font-weight: var(--desktop-fw400);
  font-style: normal;
  line-height: 150%; /* 26.471px */
  text-decoration-line: underline;
}

.password-options-login {
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
}

.common-input-user-login:hover {
  border-color: var(--pbss-p1) !important;
}

.common-input-user-login:focus {
  border-color: var(--pbss-p1) !important;
}
.login-page-divider {
  margin-bottom: 46px !important;
  margin-top: 49px !important;
}

.submit-login-page {
  border-radius: 6px;
  background: var(--pbss-p1) !important;
  display: flex;
  width: 495px;
  height: 52px;
  padding: 11.644px 29.111px;
  justify-content: center;
  align-items: center;
  gap: 11.644px;
  flex-shrink: 0;
  color: var(--White, var(--White, #fff));
  font-size: var(--desktop-fz22);
  font-style: normal;
  font-weight: var(--desktop-fw500);
  line-height: 150%;
  /* 34.933px */
}

.confirmation-action-button {
  border-radius: 6px;
  background: var(--pbss-p1) !important;
  display: flex;
  width: fit-content;
  height: 46px;
  padding: 8px 29.111px !important;
  justify-content: center;
  align-items: center;
  gap: 11.644px;
  flex-shrink: 0;
  color: var(--white) !important;
  font-size: var(--desktop-fz16);
  font-style: normal;
  font-weight: var(--desktop-fw500);
  line-height: 150%;
  cursor: pointer;
}

.confirmation-action-button:hover {
  border: 1px solid var(--pbss-p1);
  background: var(--white) !important;
  color: var(--pbss-p1) !important;
}

.submit-login-page:hover {
  background: var(--pbss-p1) !important;
}

.signup-link-description > a {
  color: #787b7b;
  font-size: var(--desktop-fz18);
  font-style: normal;
  font-weight: var(--desktop-fw400);
  line-height: 150%;
  text-decoration: underline;
}

.signup-link-login {
  color: var(--Black, #000);
  font-size: var(--desktop-fz18);
  font-style: normal;
  font-weight: var(--desktop-fw500);
  line-height: 150%; /* 34.5px */
  margin-top: 10px;
}

.re-captcha-login-item {
  margin-top: 21px !important;
}

.re-captcha-label-login {
  color: #5b5b5b;
  font-size: var(--desktop-fz14);
  font-style: normal;
  font-weight: var(--desktop-fw450);
  line-height: 150%; /* 20.475px */
  margin-bottom: 17px !important;
}

.re-captcha-login-item-control-input-content {
  line-height: 40%;
}

.form-container {
  display: flex;
  height: fit-content;
}

.main-opening-con-login {
  padding: 0 !important;
}

@media screen and (max-width: 330px) {
  .submit-login-page {
    font-size: var(--desktop-fz18);
    padding: 11.644px 94px;
  }
}

@media screen and (max-width: 768px) {
  .submit-login-page {
    width: 100%;
    margin-bottom: 0px;
  }
  .common-input-user-login {
    width: 100%;
  }
  .signup-link-login {
    font-size: var(--desktop-fz18);
  }
  .signup-link-description > a {
    font-size: var(--desktop-fz18);
  }
}
@media screen and (max-width: 480px) {
  .signup-link-login {
    font-size: var(--desktop-fz18);
  }
  .signup-link-description > a {
    font-size: var(--desktop-fz18);
  }

  .login-form {
    padding-right: 0px !important;
  }
}

@media screen and (max-width: 1200px) {
  .password-options-login {
    flex-direction: column;
    gap: 10px;
  }
  .password-options-login > a {
    margin-top: 0px !important;
  }
}

@media screen and (max-width: 1300px) {
  .main-opening-container {
    padding: 0;
  }

  .login-divider {
    visibility: hidden;
  }
}
@media screen and (min-width: 1000px) {
  .extend-login-form {
    flex-direction: row !important;
  }
}

@media screen and (max-width: 1300px) {
  .form-container {
    flex-direction: column;
    gap: 20px;
  }
  .main-opening-container {
    max-width: 100% !important;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(334px, 1fr));
    grid-gap: 20px;
  }
  .login-form {
    padding-right: 20px !important;
  }
}

@media screen and (min-width: 1700px) {
  .main-opening-container {
    max-width: 45%;
  }
}
@media screen and (min-width: 1440px) {
  .login-form {
    padding-right: 30px !important;
  }
}
@media screen and (min-width: 2560px) {
  .login-form {
    padding-right: 220px !important;
  }
}

@media screen and (min-width: 1100px) {
  .extend-login-form {
    flex-direction: row;
  }
  .main-opening-con-login {
    display: flex !important;
  }
}

@media screen and (min-width: 1150px) {
  .extend-login-form {
    flex-direction: row !important;
  }
}

@media screen and (max-width: 1150px) {
  .main-opening-con-login {
    grid-template-columns: repeat(auto-fill, minmax(334px, 1fr)) !important;
  }
}
