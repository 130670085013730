.adminSpecialPayment-button-container {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.adminspecialPayment-template-div > div {
  box-shadow: none !important;
  margin: 0px !important;
}
