/* Event Card Styles */
.Event2-main-container {
  width: 100%;
  padding: 30px 40px;
  padding-bottom: 40px;
  display: flex;
  justify-content: space-between;
}

.Event2-section1 {
  line-height: 50px;
  width: fit-content;
  max-width: 70%;
}

.Event2-section1 > p {
  color: var(--Black, #000);
  font-size: var(--desktop-fz21);
  font-style: normal;
  font-weight: var(--desktop-fw600);
}

/* Add styles for Event2-section2 and other elements as needed */

.Event2-badge-container {
  display: flex;
  gap: 15px;
}

.Event2-group1-badge {
  display: flex;
  flex-direction: column;
  justify-content: end;
  gap: 10px;
}

.Event2-group1-badge > label {
  color: #191a1a;
  font-size: var(--desktop-fz12);
  font-style: normal;
  font-weight: var(--desktop-fw700);
  line-height: normal;
}

.Event2-date-badge {
  border-radius: 18.115px;
  background: #000;
  display: inline-flex;
  padding: 5.328px 10.656px !important;
  justify-content: center;
  align-items: center;
}

.Event2-common-text-white {
  color: #fff;
  text-align: center;
  font-size: 12.787px;
  font-style: normal;
  font-weight: var(--desktop-fw700);
  line-height: normal;
}

.Event2-location-badge {
  border-radius: 5.742px;
  background: #828282 !important;
}

.Event2-common-badge {
  border-radius: 4.845px;
  background: var(--Gray-4, #bdbdbd);
  display: flex;
  width: 77px;
  height: 27px;
  padding: 2.422px 9.689px !important;
  justify-content: center;
  align-items: center;
  gap: 12.112px;
  flex-shrink: 0;
  width: auto;
}

.Event2-event-dis-container {
  display: flex;
  margin-top: 40px;
}

.Event2-event-dis-container > p {
  margin: 0 !important;
  padding: 0 !important;
  color: #111010;
  font-size: var(--desktop-fz18);
  font-style: normal;
  font-weight: var(--desktop-fw450);
  line-height: 162%;
}

.Event2-event-dis-title {
  color: #111010;
  font-size: var(--desktop-fz18);
  font-style: normal;
  font-weight: var(--desktop-fw700);
  line-height: 162%;
  margin-right: 10px;
}

/* Add styles for other elements as needed */

.Event2-section2 {
  gap: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
}

/* Button Styles */
.Event2-section2 > button {
  border-radius: 6px;
  background: var(--pbss-p1);
  display: inline-flex;
  padding: 8px 20px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  color: var(--White, var(--White, #fff));
  font-size: var(--desktop-fz16);
  font-style: normal;
  font-weight: var(--desktop-fw700);
  line-height: 150%;
  border: none;
  cursor: pointer;
}

.Event2-section2 > p {
  color: #505050;
  font-size: 22.004px;
  font-style: normal;
  font-weight: var(--desktop-fw600);
  line-height: 162%;
  /* 38.887px */
}

/* Price Badge Styles */
.Event2-price-badge {
  border-radius: 14.768px;
  border: 1.207px solid #000;
  background: #fff;
  display: flex;
  width: 125px;
  height: 27px;
  padding: 4.344px 8.687px;
  justify-content: center;
  align-items: center;
  gap: 5.212px;
  flex-shrink: 0;
}

.Event2-side-border {
  border-left: 2px solid #b7b7b7;
  width: 100%;
  display: flex;
  justify-content: center;
}

.Event2-more-details {
  margin-top: 20px;
}

.black {
  background: black;
}

.white {
  background: white;
  color: var(--pbss-p1);
}

@media screen and (max-width: 900px) {
  .Event2-main-container {
    flex-direction: column;
  }

  .Event2-section1 {
    width: auto;
  }

  .Event2-section2 {
    display: grid;
    margin: 20px 0px;
  }
}

@media screen and (max-width: 450px) {
  .Event2-badge-container {
    flex-direction: column;
    gap: 0px;
  }
}

/*
----------------------------------------------------------------------------------------
  New Upcoming Event Cards styles
----------------------------------------------------------------------------------------
*/

.upcoming-event-card {
  background-color: white;
  width: 100%;
  min-height: max-content;
  /* height: fit-content; */
  /* min-height: 15vh; */
  display: flex;
  flex-direction: column;
  /* gap: 15px; */
  /* justify-content: space-between; */
  fill: #fff;
  transition-delay: 0.8ms;
  stroke-width: 0.573px;
  stroke: #a1b6df;
  filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.09))
    drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.04));
}

.upcoming-event-card-content-wrapper {
  display: flex;
  justify-content: space-between;
}

.upcoming-event-card-content-wrapper > a > button {
  margin: 10px;
}

.cardLeftGroup {
  padding: 25px 20px;
  display: flex;
  flex: 8;
  flex-direction: column;
  justify-content: start;
  gap: 10px;
}

.timeLocationDetails {
  display: flex;
  gap: 24px;
  align-items: center;
  /* margin-bottom: 10px; */
}

.timeExtraDetails {
  display: flex;
}

.locationDateDetails {
  display: flex;
  align-items: center;
  gap: 25px;
}

.locationText {
  font-size: var(--desktop-fz14);
  font-weight: var(--desktop-fw700);
  background-color: var(--pbss-p3);
  color: #fff;
  padding: 5px 8px;
  min-width: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dateText {
  font-size: var(--desktop-fz14);
  font-weight: var(--desktop-fw700);
  color: #60759f;
}

.timeText {
  display: flex;
  align-items: center;
  gap: 5px;
  color: var(--pbss-p3);
  font-size: var(--desktop-fz14);
  font-weight: var(--desktop-fw700);
  margin-left: 30px;
}

.upcoming-event-card-title {
  font-size: var(--desktop-fz18);
  color: black;
  font-weight: var(--desktop-fw500);
}

.upcoming-event-card-description {
  color: #6c6c6c;
  font-size: var(--desktop-fz14);
  font-weight: var(--desktop-fw400);
}

.cardRightGroup {
  padding: 25px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 2;
  flex-direction: column;
  gap: 10px;
}

.cardRightGroup a {
  padding: 10px;
}

.borderLeft {
  border-left: 1px solid #9ab8f1;
  padding-left: 20px;
  height: 100%;
  align-self: center;
}

.usedForMyEvents {
  margin-top: 30px;
}

.cardRightGroupChapter {
  padding: 0 0 0 10px;
  margin: 0 0 0 10px;
}

.registerButtonUsedForChapPage {
  padding: 10px 5px;
}

.registerButton {
  padding: 10px 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--pbss-p3);
  border-radius: 5px;
  font-size: var(--desktop-fz16);
  font-weight: var(--desktop-fw500);
  background-color: var(--pbss-p3);
  cursor: pointer;
  color: white;
}

.registerButton:hover {
  box-shadow: 0 0 10px #96b7f4;
}

.extraDetails-desktop {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  padding: 5px 20px;
  font-size: var(--desktop-fz14);
  font-weight: var(--desktop-fw600);
  background-color: #eff4ff;
  color: #2f57a4;
}

.extraDetails-mobile {
  display: none;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  padding: 5px 20px;
  font-size: var(--desktop-fz14);
  font-weight: var(--desktop-fw600);
  background-color: #eff4ff;
  color: #2f57a4;
}

.cardLeftGroup:hover,
.cardRightGroup:hover,
.cardLeftGroup:visited,
.cardRightGroup:visited {
  background-color: #2f58a413;
  cursor: pointer;
  box-shadow: 1px 1px 10px #2f58a435;
  transition: all 0.5ms ease-in;
}

/* Responsive styles for tablets */
@media screen and (max-width: 1600px) {
  .timeLocationDetails {
    /* margin-bottom: 15px; */
  }
}

@media screen and (min-width: 1300px) and (max-width: 1600px) {
  .cardRightGroupChapter {
    flex: 3;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1300px) {
  .cardLeftGroupForChapterpage {
    flex: 7;
  }

  .cardRightGroupChapter {
    flex: 3;
  }

  .registerButtonUsedForChapPage {
    padding: 10px 5px;
  }
}

@media screen and (max-width: 1200px) {
  .event2-listing-container-margin-for-chapPage {
    margin-right: 0px;
  }
}

@media only screen and (min-width: 1024px) {
  .upcoming-event-card-content-wrapper > a > button {
    width: auto;
  }
}

@media only screen and (max-width: 1024px) {
  .upcoming-event-card-content-wrapper > a > button {
    margin: 10px;
    min-width: fit-content;
    width: 100%;
  }
  .timeLocationDetails {
    /* margin-bottom: 15px; */
    gap: 15px;
  }

  .locationText {
    font-size: var(--laptop-fz12);
  }

  .dateText {
    font-size: var(--laptop-fz16);
  }

  .timeText {
    font-size: var(--laptop-fz12);
    margin-left: 0px;
  }

  .upcoming-event-card-title {
    font-size: var(--laptop-fz20);
  }

  .upcoming-event-card-description {
    font-size: var(--laptop-fz16);
  }

  .registerButton {
    font-size: var(--laptop-fz16);
  }

  .extraDetails-desktop {
    font-size: var(--laptop-fz14);
  }

  /* .borderLeft {
    margin-left: 50px;
  } */

  .locationDateDetails {
    gap: 15px;
  }

  .registerButton {
    padding: 10px 20px;
  }
}

@media only screen and (max-width: 768px) {
  .cardLeftGroup,
  .cardRightGroup {
    padding: 0px;
  }

  .upcoming-event-card-content-wrapper {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .cardLeftGroup,
  .cardRightGroup {
    width: 100%;
    text-align: start;
  }

  .cardLeftGroup {
    margin-bottom: 10px;
    padding-top: 20px;
    padding-bottom: 0px !important;
    padding: 15px;
  }

  .cardRightGroup {
    padding: 20px 15px !important;
    padding-top: 0px !important;
  }
  .cardRightGroup > button {
    margin: 0px !important;
  }

  .cardLeftGroup {
    gap: 15px;
  }

  .timeLocationDetails {
    width: 100%;
    justify-content: start;
    /* padding: 15px 0 0 0; */
    gap: 10px;
  }

  .extraDetails-desktop {
    padding: 1rem 0;
    width: 100%;
    display: none;
  }

  .dateText {
    min-height: fit-content;
  }

  .extraDetails-mobile {
    display: flex;
    width: fit-content;
  }

  .registerButton {
    width: 100%;
  }

  .usedForMyEvents {
    margin-top: 0px;
  }

  .borderLeft {
    border-left: none;
    padding-left: 0;
    margin-left: 0;
  }

  .timeExtraDetails {
    gap: 15px;
  }
}

/* Responsive styles for mobile */
@media only screen and (max-width: 550px) {
  .upcoming-event-card {
    flex-direction: column;
    align-items: center;
    min-height: auto;
  }

  .upcoming-event-card-content-wrapper {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .cardLeftGroup,
  .cardRightGroup {
    width: 100%;
    text-align: start;
    /* margin-bottom: 1rem; */
  }

  .cardLeftGroup {
    gap: 10px;
  }

  .timeLocationDetails {
    width: 100%;
    justify-content: start;
    padding: 0px 0 0 0;
    gap: 0px;
  }

  .usedForMyEvents {
    margin-top: 20px;
  }

  .extraDetails-desktop {
    padding: 1rem 0;
    width: 100%;
    display: none;
  }

  .cardRightGroup > button {
    font-size: var(--mobile-fz14);
  }

  .locationText {
    font-size: var(--mobile-fz10);
  }

  .dateText {
    font-size: var(--mobile-fz12);
    min-height: fit-content;
  }

  .timeText {
    font-size: var(--mobile-fz10);
  }

  .extraDetails-mobile {
    display: flex;
    width: fit-content;
    font-size: var(--mobile-fz10);
    font-weight: var(--mobile-fw700);
  }

  .upcoming-event-card-title {
    font-size: var(--mobile-fz12);
    font-weight: var(--mobile-fw700);
  }

  .upcoming-event-card-description {
    font-size: var(--mobile-fz12);
    font-weight: var(--mobile-fw500);
  }

  .registerButton {
    font-size: var(--mobile-fz16);
    font-weight: var(--mobile-fw700);
    width: 100%;
  }

  .borderLeft {
    border-left: none;
    padding-left: 0;
    margin-left: 0;
  }
}

@media screen and (max-width: 480px) {
  .timeLocationDetails {
    gap: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .timeExtraDetails {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .locationDateDetails {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .dateText {
    font-size: var(--mobile-fz10);
    min-width: max-content;
  }

  .timeText {
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
}

.hyperlink {
  cursor: pointer;
}

.hyperlink:hover {
  color: var(--pbss-p3);
}
