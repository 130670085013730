.past-events-listing-cards-container {
  width: 100%;
  border: 0.5px solid #bce;
  border-bottom: none;
}

.past-event-container {
  gap: 20px !important;
}
/* Pagination Styles */

.pagination-past-events-col {
  min-height: 50px;
}

.pagination-past-events-item-link {
  /* border: 1px solid var(--pbss-p3) !important; */
  min-height: 40px !important;
  width: 58px !important;
}

.pagination-past-events-item-link:disabled {
  border: 1px solid var(--pbss-p3) !important;
}

.pagination-past-events-next > button {
  border: 0.6px solid var(--pbss-p3) !important;
}

.pagination-past-events-prev > button {
  border: 0.6px solid var(--pbss-p3) !important;
}

.pagination-past-events-item-link span svg {
  color: black;
}

.pagination-past-events-item-link:disabled span svg {
  color: black;
}

.pagination-past-events-item {
  height: 40px !important;
  width: 58px !important ;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  border-radius: 6px !important;
  border: 1px solid #c4d6ff !important;
}

.pagination-past-events-item > a {
  font-size: var(--desktop-fz16) !important;
  font-weight: var(--desktop-fw700) !important;
  color: var(--pbss-p3) !important;
}

.pagination-past-events-item-active {
  border: none;
  background-color: var(--pbss-p3) !important;
}
.pagination-past-events-item-active > a {
  color: #fff !important;
}

.pagination-past-events {
  height: 100% !important;
  display: flex !important;
  justify-content: flex-start !important;
}

.pagination-past-events-item > a {
  font-size: var(--desktop-fz16) !important;
  font-weight: var(--desktop-fw700) !important;
}

.pagination-past-events-item-ellipsis {
  font-size: var(--desktop-fz16) !important;
  height: 40px !important;
  width: 100% !important;
  display: flex !important;
  justify-content: center !important;
  align-items: end !important;
}

.pagination-past-events-options-size-changer {
  display: none !important;
}

@media screen and (max-width: 768px) {
  .pagination-past-events-item {
    width: 40px !important;
  }
  .pagination-past-events-item-link {
    width: 40px !important;
  }
}

@media screen and (max-width: 650px) {
  .past-events-listing-cards-container {
    border: none;
    display: grid;
    gap: 20px;
  }
}

@media screen and (max-width: 520px) {
  .pagination-past-events-item > a {
    font-size: var(--mobile-fz12) !important;
    font-weight: var(--mobile-fw700) !important;
  }
  .pagination-past-events-item {
    /* width: calc(100% / 3.5) !important; */
    width: 34px !important;
  }
  .pagination-past-events-item-link {
    /* width: calc(100% / 3) !important; */
    max-width: 25px !important;
  }
  .pagination-past-events {
    max-width: 100% !important;
    justify-content: space-between !important;
  }
}

@media screen and (max-width: 426px) {
  .pagination-past-events-item > a {
    font-size: var(--mobile-fz12) !important;
    font-weight: var(--mobile-fw700) !important;
  }
  .pagination-past-events-item {
    /* min-width: 30px !important; */
    margin: 1px !important;
    display: flex !important;
    justify-content: center;
    align-items: center;
    /* padding: 0px 20px !important; */
  }
  .pagination-past-events-item-link {
    width: 25px !important;
    display: flex !important;
    justify-content: center;
    align-items: center;
    /* padding: 0px 20px !important; */
  }
  .pagination-past-events-prev {
    min-width: 20px !important;
  }
  .pagination-past-events-next {
    min-width: 20px !important;
    margin-left: 8px !important;
  }
  .pagination-past-events .pagination-past-events-jump-prev,
  .pagination-past-events .pagination-past-events-jump-next {
    min-width: 20px !important;
    margin-right: 0px !important;
    margin: 0px 2px !important;
  }
}

@media screen and (max-width: 376px) {
  .pagination-past-events-item > a {
    font-size: var(--mobile-fz10) !important;
    font-weight: var(--mobile-fw700) !important;
  }
  .pagination-past-events-item {
    min-width: 8vw !important;
    width: 8vw !important;
    margin: 1px !important;
    height: 32px !important;
  }
  .pagination-past-events-item-link {
    width: 8vw !important;
    min-height: 20px !important;
  }
  .pagination-past-events-item-link span svg {
    width: 8px !important;
    height: 8px !important;
  }
  .pagination-past-events-prev {
    min-width: 20px !important;
    margin-right: 5px !important;
  }
  .pagination-past-events-next {
    min-width: 20px !important;
    margin-left: 5px !important;
  }
}
