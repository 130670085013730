.login-form-container {
  margin-bottom: 100px;
}
.login-divider {
  height: 50vh;
  width: 2px;
  margin: 39px 84px;
}

.login-container {
  width: auto;
  margin-top: 0px;
}
.wapper-main-container {
  display: flex;
  width: 100%;
  justify-content: end;
}
.main-opening-container {
  max-width: 70%;
  gap: 20px;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 100px;
}
.common-input-user {
  border-radius: 6px;
  font-family: 'inter';
  font-size: var(--desktop-fz14);
  border: 0.882px solid #d6d6d6;
  height: 52px;
  display: flex;
  align-items: center;
}

.more-approved-listing {
  display: flex;
  margin-top: 0px !important;
  text-decoration: none !important;
  justify-content: center;
}
.more-approved-button {
  border-color: lightgray;
  color: rgb(67, 67, 67);
  font-size: var(--desktop-fz16);
  border-radius: 50px;
  font-weight: var(--desktop-fw600);
  display: flex;
  align-items: center;
}
.more-approved-button:hover {
  color: var(--pbss-p1) !important;
  border-color: var(--pbss-p1) !important;
}
.common-input-user > input {
  background: none;
}

.common-input-user::-moz-placeholder {
  color: var(--Black, #000);
  font-size: var(--desktop-fz14);
  font-style: normal;
  font-family: 'inter';
  font-weight: var(--desktop-fw450);
  line-height: 150%;
  /* 26.471px */
}

.password-options > a {
  color: #6c6c6c;
  font-size: 17.647px;
  font-style: normal;
  font-weight: var(--desktop-fw450);
  line-height: 150%;
  /* 26.471px */
  text-decoration-line: underline;
  text-underline-offset: 5px;
}

/* .common-input-user:hover {
  border-color: var(--pbss-p1) !important;
} */

.common-submit-login {
  border-radius: 6px;
  background: var(--PBSS--Primary) !important;
  display: flex;
  height: 52px;
  padding: 11.644px 29.111px;
  justify-content: center;
  align-items: center;
  gap: 11.644px;
  flex-shrink: 0;
  color: var(--White, var(--White, #fff));
  font-size: 23.289px;
  font-style: normal;
  font-weight: var(--desktop-fw500);
  line-height: 150%;
  /* 34.933px */
}

.common-submit-login:hover {
  background: var(--white) !important;
  border-color: var(--PBSS--Primary);
  color: var(--PBSS--Primary) !important;
}

.user-common-submit-login {
  border-radius: 6px;
  background: var(--pbss-p1) !important;
  display: flex;
  height: 52px;
  padding: 11.644px 29.111px;
  justify-content: center;
  align-items: center;
  gap: 11.644px;
  flex-shrink: 0;
  color: var(--White, var(--White, #fff));
  font-size: 23.289px;
  font-style: normal;
  font-weight: var(--desktop-fw500);
  line-height: 150%;
  /* 34.933px */
}

.user-common-submit-login:hover {
  background: var(--white) !important;
  border-color: var(--pbss-p1);
  color: var(--pbss-p1) !important;
}
.common-cancel-login {
  border-radius: 12px;
  display: flex;

  height: 52px;
  padding: 11.644px 29.111px;
  justify-content: center;
  align-items: center;
  gap: 11.644px;
  flex-shrink: 0;
  color: red !important;
  border-color: red;
  font-size: 23.289px;
  font-style: normal;
  font-weight: var(--desktop-fw500);
  line-height: 150%;
  /* 34.933px */
}

.common-cancel-login:hover {
  border-color: var(--pbss-p1) !important;
}

.signup-link > a {
  color: var(--Black, #787b7b);
  font-size: var(--desktop-fz18);
  font-style: normal;
  font-weight: var(--desktop-fw450);
  line-height: 150%;
  text-decoration-line: underline;
  text-underline-offset: 5px;
}

.signup-link {
  color: var(--Black, #000);
  font-size: var(--desktop-fz18);
  font-style: normal;
  font-weight: var(--desktop-fw450);
  line-height: 150%;
  /* 34.5px */
}

.re-captcha-item {
  margin: 25px 0px !important;
}

.re-captcha-label {
  color: #5b5b5b;
  font-size: 13.65px;
  font-style: normal;
  font-weight: var(--desktop-fw450);
  line-height: 150%;
}

.re-captcha-item-control-input-content {
  line-height: 40%;
}

.form-container {
  display: flex;
  height: fit-content;
}

@media screen and (max-width: 768px) {
  .common-submit-login {
    width: 100%;
  }
  .user-common-submit-login {
    width: 100%;
  }
  .common-input-user {
    width: 100%;
  }
}

@media screen and (max-width: 1300px) {
  .main-opening-container {
    padding: 0;
  }

  .login-divider {
    margin: 0px;
    width: 0;
    height: 0px;
    visibility: hidden;
  }
}

@media screen and (max-width: 1300px) {
  .form-container {
    flex-direction: column;
    gap: 20pxs;
  }
  .main-opening-container {
    max-width: 100% !important;
    display: flex;
    grid-template-columns: repeat(auto-fill, minmax(334px, 1fr));
    grid-gap: 20px;
  }
}

@media screen and (min-width: 1440px) {
  .login-divider {
    margin: 39px 64px;
  }
  .main-opening-container {
    padding: 0px !important;
    padding-right: 0px !important;
  }
}
