.common-ads-listing-card-content-wrapper {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: space-between;
  padding: 22px 20px 20px 0px;
}

.common-ads-listing-card {
  display: flex;
  gap: 40px;
  width: 100%;
  min-height: max-content;
  border: 0.5px solid #a1b6df;
  filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.09))
    drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.04));
  position: relative;
}

.common-ads-listing-card-button-contianer {
  display: flex;
  gap: 10px;
}

.common-ads-listing-card-logo-container {
  min-height: 100%;
  min-width: 185px;
  max-width: 185px;
  padding: 0px 10px;
  background-color: #f8faff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.common-ads-listing-card-textAndButton {
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: space-between;
}

.common-ads-listing-card-textDetails {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.common-ads-listing-card-textDetails-heading {
  font-size: var(--desktop-fz18);
  font-weight: var(--desktop-fw700);
  color: #000000;
}

.common-ads-listing-card-textDetails-description {
  font-size: var(--desktop-fz16);
  font-weight: var(--desktop-fw450);
  line-height: 170%;
  overflow-wrap: anywhere;
}

.link-no-style {
  text-decoration: none;
  color: white;
}

.common-ads-listing-card-button {
  font-size: 14px;
  font-weight: 450;
  color: white;
  background-color: var(--pbss-p3);
  padding: 8px 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
}
.common-ads-listing-card-button:hover {
  color: white !important;
  background-color: var(--pbss-p3) !important;
}

.common-ads-listing-card-status-container {
  position: absolute;
  top: 10px;
  right: 0;
}

.ads-listing-card-status-text {
  position: absolute;
  top: 11px;
  right: 35px;
  color: #fff;
  font-size: var(--desktop-fz14);
  font-weight: var(--desktop-fw500);
}

.ads-listing-card-status-approved {
  color: #145417;
}

.ads-listing-card-status-in_review {
  right: 20px;
}
.common-ads-listing-card-button-contianer {
  display: flex;
  gap: 10px;
}

.ads-listing-card-action-button-container {
  display: flex;
  justify-content: end;

  /* align-items: center; */
}
.ads-listing-card-action-button {
  padding: 20px 30px;
  padding-left: 60px;
  margin-top: 20px;
  min-width: 240px;
  flex-direction: column;
  border-left: 1px solid #9ab8f1;
  /* align-items: center; */
  display: flex;
}

.common-ads-edit-icon {
  position: fixed;
  left: 10px;
  top: 10px;
  border: #9ab8f1;
  background-color: var(--pbss-p1);
  border-radius: 4px;
  padding: 4px 6px;
  color: white;
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .common-ads-listing-card {
    flex-direction: column;
    gap: 20px;
    padding: 25px 20px 10px 20px;
  }
  .common-ads-listing-card-logo-container {
    padding: 10px 0px;
    min-width: 100%;
  }
  .common-ads-listing-card-content-wrapper {
    display: block;
    padding: 0px;
    min-width: 100%;
  }
  .common-ads-listing-card-button {
    width: 100%;
    padding: 20px 0px;
  }
  .common-ads-listing-card-status-container {
    top: 0;
  }
  .ads-listing-card-action-button {
    border-left: none;
    width: 100%;
    margin-top: 0px;
    padding: 0px;
  }
}

@media screen and (max-width: 375px) {
  .common-ads-listing-card {
    gap: 10px;
  }
  .common-ads-listing-card-textDetails {
    gap: 5px;
  }
  .common-ads-listing-card-textDetails-heading {
    font-size: var(--mobile-fz16);
  }
  .common-ads-listing-card-textDetails-description {
    font-size: var(--mobile-fz14);
  }
  .common-ads-listing-card-button {
    font-size: var(--mobile-fz14);
  }
}
