.common-cart-table {
  margin-top: 57px !important;
  background: #fafbff !important;
  box-shadow: 0px 4px 4px -1px rgba(160, 160, 160, 0.22);
}
.common-cart-table-tbody > tr > td {
  border-bottom: none !important;
}
.common-cart-table-cell .ant-form-item {
  margin-bottom: 0px !important;
}
.common-cart-table-thead th {
  background-color: #d8e5ff !important;
  color: #2f57a4 !important;
  font-size: var(--desktop-fz16) !important;
  font-style: normal;
  font-weight: var(--desktop-fw600);
  line-height: 150%; /* 24px */
}
.common-cart-table-cell::before {
  display: none !important;
}
.common-cart-table-row td {
  color: #282828;
  font-size: var(--desktop-fz16);
  font-weight: var(--desktop-fw400);
  font-style: normal;
  line-height: 150%; /* 24px */
}
.vendor-name {
  font-weight: var(--desktop-fw600) !important;
}
.cart-fees {
  color: #2e56a4;
  font-size: var(--desktop-fz20);
  font-weight: var(--desktop-fw700);
  font-style: normal;
  line-height: 150%; /* 33px */
}
.cart-adType {
  border-radius: 6px;
  border: 1px solid #2f57a4 !important;
  max-width: 200px;
  width: 100%;
}
.cart-adType:hover {
  border-color: #2f57a4 !important;
}
.cart-adType-arrow {
  color: #2f57a4 !important;
}
.cart-adType-selection-item {
  color: #2f57a4 !important;
  font-weight: var(--desktop-fw600);
}
.cart-desc {
  max-width: 409px;
  width: 100%;
}
.cart-action {
  display: flex;
  border-bottom: none !important;
}
.common-cart-action {
  border-bottom: none !important;
}
