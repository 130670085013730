.active-panel {
  background-color: #b0b0b0;
}

.custom-collapse-item-active > .custom-collapse-header {
  border-radius: var(--Count, 0px);
  background: var(--pbss-p1);
  color: white !important;
  padding: 18px 16px !important;
  font-size: var(--desktop-fz16);
}
.custom-collapse-header {
  border: none;
  box-shadow: 1px 1px 10px lightgray;
  margin-top: 10px;
  padding: 18px 16px !important;
  font-size: var(--desktop-fz16);
  align-items: center !important;
}
.list-refund {
  margin-bottom: 10px;
}
.list-refund-note {
  display: flex;
  align-items: center;
  gap: 3px;
  justify-content: flex-start;
}
.refund-content {
  margin-top: 10px;
}
.refund-title {
  font-weight: bolder;
}
.custom-collapse {
  border: none !important;
  margin-bottom: 1rem;
  font-size: 14px;
}

.custom-collapse > .custom-collapse-item:last-child > .custom-collapse-header {
  border-radius: 0px;
}

.custom-collapse > .custom-collapse-item {
  border: none;
}

.custom-collapse .custom-collapse-content {
  border: none !important;
  box-shadow: 1px 1px 10px lightgray;
}

.custom-collapse .collapse-content {
  display: grid;
  gap: 10px;
}

/* .custom-collapse-expand-icon {
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-items: center;
  width: 40px;
  padding: 0px;
  padding-inline-end: 12px;
  margin-inline-start: 4px;
  background: var(--white);
  border-radius: 50%;
  padding: 20px 46px;
  color: var(--pbss-p1);
  box-shadow: 0px 0px 2px 1px var(--pbss-p1);
} */

/* .custom-collapse-expand-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  padding: 0;
  margin: 0;
} */
/* .custom-collapse-arrow {
  margin-left: 2px;
} */

.custom-collapse-expand-icon {
  background: white;
  border: 1px solid var(--pbss-p1);
  display: flex;
  align-items: center;
  justify-content: center;
  padding-inline-start: 0 !important;
  padding: 15px 10px !important;
  border-radius: 50%;
}
.custom-collapse-arrow {
  color: var(--pbss-p1) !important;
}

.custom-collapse-item-active
  > .custom-collapse-header
  > .custom-collapse-expand-icon
  > div
  > svg {
  position: absolute;
  top: 50%;
  right: 2%;
  transform: translateY(-50%);
  /* border: 1px solid black; */
}
.custom-collapse-expand-icon > div > svg {
  position: absolute;
  top: 50%;
  right: 1%;
  transform: translateY(-50%);
  /* border: 1px solid black; */
}

.collapse-title {
  color: var(--black);
  font-size: var(--desktop-fz28);
  font-style: normal;
  font-weight: var(--desktop-fw700);
  line-height: 150%;
}

.collapse-container-e23 {
  margin-top: 15px;
  padding: 15px 0px;
}

@media screen and (max-width: 450px) {
  .collapse-title {
    font-size: var(--desktop-fz20);
  }
  .custom-collapse-header-text {
    max-width: 80%;
  }
  .list-padding {
    padding-inline-start: 20px;
  }
  .custom-collapse .collapse-content {
    overflow-x: scroll;
  }
}
