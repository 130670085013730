.add-attendee-container {
  display: flex;
  justify-content: end;
}

.food-options-selection {
  border: none;
  display: flex;
  padding: 5px 10px;
  justify-content: center;
  align-items: center;
  gap: var(--Count, 10px);
  flex-shrink: 0;
  background: rgba(128, 128, 128, 0.147);
  border-radius: 5.833px;
  font-size: 13.817px;
  font-style: normal;
  font-weight: var(--desktop-fw450);
  line-height: 120%; /* 16.58px */
}

.food-options-selection:hover {
  display: flex;
  padding: 5px 10px;
  justify-content: center;
  align-items: center;
  gap: var(--Count, 10px);
  flex-shrink: 0;
  border-radius: 5.833px;
  color: var(--pbss-p1, #005d61) !important;
  font-size: 13.817px;
  font-style: normal;
  font-weight: var(--desktop-fw450);
  line-height: 120%; /* 16.58px */
}

.optionactive {
  background: #e5f8ed;
}
