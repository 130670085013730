* {
  font-family: var(--font-inter);
}

@font-face {
  font-family: 'inter';
  src: url('./../../../Assets/fonts/Inter/static/Inter-Regular.ttf')
    format('woff2');
}

body {
  margin: 0;
  font-family: 'inter';
  line-height: normal;
}

:root {
  --page-size: 1920px;
  --max-width: 1536px;
  --gutter: 1rem;
  --z-index-main-header: 700;
  /* fonts */
  --font-sf-pro-display: 'SF Pro Display';
  --font-inter: 'inter';
  /* font sizes */
  --font-size-9xl-9: 28.9px;
  --font-size-3xl-6: 22.6px;
  --font-size-smi-9: 12.9px;
  --font-size-mid-2: 17.2px;
  --font-size-sm-7: 13.7px;
  --font-size-sm: 14px;
  --font-size-lg: 18px;
  --font-size-xs: 12px;
  --font-size-base: 16px;
  --font-size-5xl: 24px;
  --font-size-16xl-3: 35.3px;

  /* New Font Sizes */
  /* Normal */
  --desktop-fz8: 8px;
  --desktop-fz10: 10px;
  --desktop-fz12: 12px;
  --desktop-fz13: 13px;
  --desktop-fz14: 14px;
  --desktop-fz15: 15px;
  --desktop-fz16: 16px;
  --desktop-fz17: 17px;
  --desktop-fz18: 18px;
  --desktop-fz19: 19px;
  --desktop-fz20: 20px;
  --desktop-fz21: 21px;
  --desktop-fz22: 22px;
  --desktop-fz23: 23px;
  --desktop-fz24: 24px;
  --desktop-fz25: 25px;
  --desktop-fz26: 26px;
  --desktop-fz27: 27px;
  --desktop-fz28: 28px;
  --desktop-fz30: 30px;
  --desktop-fz31: 31px;
  --desktop-fz32: 32px;
  --desktop-fz33: 33px;
  --desktop-fz34: 34px;
  --desktop-fz35: 35px;
  --desktop-fz36: 36px;
  --desktop-fz38: 38px;
  --desktop-fz40: 40px;
  --desktop-fz43: 43px;

  /* max-width: 1024 */
  --laptop-fz10: 10px;
  --laptop-fz12: 12px;
  --laptop-fz14: 14px;
  --laptop-fz16: 16px;
  --laptop-fz18: 18px;
  --laptop-fz20: 20px;
  --laptop-fz22: 22px;
  --laptop-fz24: 24px;
  --laptop-fz26: 26px;
  --laptop-fz28: 28px;
  --laptop-fz30: 30px;
  --laptop-fz32: 32px;
  --laptop-fz34: 34px;
  --laptop-fz36: 36px;
  --laptop-fz38: 38px;

  /* max-width: 480px */
  --mobile-fz8: 8px;
  --mobile-fz9: 9px;
  --mobile-fz10: 10px;
  --mobile-fz11: 11px;
  --mobile-fz12: 12px;
  --mobile-fz14: 14px;
  --mobile-fz16: 16px;
  --mobile-fz18: 18px;
  --mobile-fz20: 20px;
  --mobile-fz22: 22px;
  --mobile-fz24: 24px;
  --mobile-fz26: 26px;
  --mobile-fz28: 28px;
  --mobile-fz30: 30px;
  --mobile-fz32: 32px;
  --mobile-fz34: 34px;
  --mobile-fz36: 36px;
  --mobile-fz38: 38px;

  /* New Font Weight */

  /* Normal */
  --desktop-fw400: 400;
  --desktop-fw450: 450;
  --desktop-fw500: 500;
  --desktop-fw600: 600;
  --desktop-fw700: 700;
  --desktop-fw900: 900;
  /* max-weight: 1024 */
  --laptop-fw400: 400;
  --laptop-fw500: 500;
  --laptop-fw600: 600;
  --laptop-fw700: 700;

  /* max-weight: 480px */
  --mobile-fw400: 400;
  --mobile-fw500: 500;
  --mobile-fw600: 600;
  --mobile-fw700: 700;

  /* New Colors */
  --pbss-p1: #2e56a4;
  --pbss-p2: #eaf1ff;
  --pbss-p3: #2f57a4;
  --pbss-p1-light: #2e56a4f2;
  --pbss-p1-dark: #04369a;

  /* version 0.2 */
  --pbss-v2: #005d61;
  /* line height */
  --desktop-lh170: 170%;

  /* Colors */
  --attendee-table-tag: #f2f6ff;
  --attendee-table-tag-border: #d8e5ff;
  --attendee-table-tag-text: #13326c;
  --table-background: #2d55a2;
  --PBSS-PrimaryMenuBackground: #f6fcfc;
  --PBSS--PrimaryV2: #2e56a4;
  --border-primary: #efefef;
  --white: whitesmoke;
  --offWhite: rgb(183, 183, 183);
  --mapColor: #ff993b;
  --black: #000;
  --PBSS--Primaryv2: #2f57a4;
  --PBSS--Primary: #005d61;
  --pbss-black: #3c3c3c;
  --color-darkslategray-100: #005d61;
  --color-cadetblue-100: #63989b;
  --color-dimgray-100: #747474;
  --color-dimgray-200: #717171;
  --color-dimgray-300: #6c6c6c;
  --color-dimgray-400: #6c6868;
  --color-gray-100: #f7fdfd;
  --color-gray-200: #919191;
  --color-gray-300: #868686;
  --color-gray-400: #818080;
  --neutral-400: #9ca3af;
  --color-darkgray-100: #9b9b9b;
  --success-500: #22c55e;
  --color-gainsboro-100: #e6e6e6;
  --color-gainsboro-200: #d6e5e6;
  --color-gainsboro-300: #d9d9d9;
  --color-whitesmoke-100: #f2f5f7;
  --gray-6: #f2f2f2;
  --black-600: #bbb;
  --color-powderblue: #a8cfd0;
  --PBSS-primary-border: #efefef;
  --PBSS-Primary-menu-text: #57647a;
  --PBSS-BG-LI: #f6f9fc;
  /* Gaps */
  --gap-6xs-2: 6.2px;
  --gap-base: 16px;
  --gap-5xs: 8px;
  --gap-13xl: 32px;
  /* Paddings */
  --padding-7xs-2: 5.2px;
  --padding-2xs-3: 10.3px;
  --padding-61xl: 80px;
  --padding-xs: 12px;
  --padding-5xs: 8px;
  --padding-xl: 20px;
  /* Border radiuses */
  --br-mid-6: 17.6px;
  --br-7xs: 6px;
  --br-6xs: 5.914px;
}

.ant-select-selection-placeholder {
  font-weight: 400 !important;
}

.user-event-card-top > .user-event-card-nav::before {
  border-bottom: none;
}

.attendees-table-container-wrapper table .ant-input {
  border-color: #898989 !important;
}

.attendees-table-container-wrapper table .ant-select-selector {
  border-color: #898989 !important;
}

.attendees-table-container-wrapper table .ant-input::placeholder {
  color: #898989 !important;
}

.attendees-table-container-wrapper table .ant-select-selection-placeholder {
  font-size: small;
  color: #898989 !important;
}
.chapter-sponsorship-info {
  float: none !important;
  width: 77% !important;
}
.payment-modal {
  padding: 10px 0px;
  text-align: justify;
  line-height: 20px;
  word-spacing: 1px;
}

.pbss-divider {
  margin: 4px 0 !important;
  background: #9ab8f1;
  height: 1px;
}

.event-flyer-img {
  margin-top: 20px;
  width: 100%;
}

.regularAttendees-price {
  display: flex;
  justify-content: center;
}

.pbss-hader-menu-horizontal > .pbss-hader-menu-submenu::before {
  border-bottom: 2px solid var(--pbss-p1) !important;
}

.date-common-tag {
  font-size: var(--desktop-fz15);
  margin-bottom: 5px;
}

.full-width {
  width: 100% !important;
}

.view-button-eye {
  display: flex;
  align-items: center;
  padding: 0px 10px;
}

.full-width .ant-upload.ant-upload-select {
  width: 100%;
}

.view-button-edit {
  color: white;
  background-color: var(--PBSS--Primary) !important;
}

.view-button-edit:hover {
  background-color: var(--PBSS--Primary) !important;
}

.view-button-cancel {
  color: var(--PBSS--Primary) !important;
  border-color: var(--PBSS--Primary) !important;
}

.view-button {
  color: var(--PBSS--Primary) !important;
  border-color: var(--PBSS--Primary) !important;
}

.view-button:hover {
  color: var(--PBSS--Primary) !important;
  border-color: var(--PBSS--Primary) !important;
}

.view-button-cancel:hover {
  border-color: var(--PBSS--Primary) !important;
}

.view-description-item-title {
  font-weight: 700;
}

.image-item {
  display: flex;
  flex-direction: column;
  gap: 9px;
}

.view-details-drawer-content-wrapper {
  overflow-x: scroll;
}

.pbss-hader-menu
  .pbss-hader-menu-submenu-title
  .pbss-hader-menu-item-icon
  + span {
  margin-inline-start: 0px;
}

.filter-filterSearch-select-selector:hover {
  border-color: var(--PBSS--Primary) !important;
}
.row-gap-0 {
  row-gap: 0 !important;
}
.ant-checkbox-inner {
  border-color: black !important;
}
.ant-checkbox-disabled .ant-checkbox-inner {
  border-color: black !important;
}
.custom-tag {
  display: flex;
  /* width: 74.001px; */
  height: 27px;
  padding: 8px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  color: #c57676;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 162%;
  flex-shrink: 0;
  border-radius: 4px;
  background: #ffe8e8;
}

.custom-tag-text {
  color: C57676 !important;
  font-size: 16px !important;
  font-weight: 600 !important;
}

.reg-navigation-btn {
  border-radius: 6px;
  box-shadow: 0px 0px 20px 0px rgba(79, 112, 176, 0.36);
  display: flex;
  padding: 22px !important;
  justify-content: center;
  align-items: center;
  gap: 7.882px;
  flex-shrink: 0;
  background-color: var(--pbss-p1);
  color: #fff;
  font-size: var(--desktop-fz18);
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  cursor: pointer;
}

.reg-navigation-btn.upload-height {
  height: 34px;
  border: 1px solid;
}

.reg-navigation-btn:hover {
  color: var(--pbss-p1) !important;
  background-color: #fff !important;
  border: 1px solid var(--pbss-p1);
}

/* .common-container-section {
  margin: 25px 0px;
} */
.attendees-save {
  font-size: 15px;
  color: var(--pbss-p1);
  font-weight: bold;
}

.attendees-save:hover {
  color: var(--pbss-p1) !important;
}
.saveAndCancelLink:hover {
  text-decoration: underline !important;
}

.attendees-save-icon-only .anticon {
  font-size: 20px !important;
}

.admin-save-attendees {
  font-size: 16px;
  color: var(--PBSS--Primary);
}

.admin-save-attendees:hover {
  color: var(--PBSS--Primary) !important;
}
.saveAndCancelWrapperForAttendees {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.admin-save-attendees-icon-only .anticon {
  font-size: 20px !important;
}

.attendees-close {
  display: flex;
  align-items: center;
  color: #e07945;
  font-weight: bold;
}

.attendees-close:hover {
  color: #e07945 !important;
}

.attendees-close-icon-only .anticon {
  font-size: 20px !important;
}

.align-center {
  align-items: center;
}

.max-area-length {
  max-height: 100px;
  height: 54px;
  min-height: 54;
}

.footer-logo {
  width: 150px;
  object-fit: contain;
}

.footer-logo-icon {
  display: flex;
  justify-content: flex-start;
  text-decoration: none !important;
}

.main-section-conatiner {
  padding: 0px 8%;
  max-width: 100%;
  overflow: hidden;
}

.main-section-conatiner.uploadStyle {
  display: flex;
  justify-content: flex-end;
}

/* admin panel */
.fees-container {
  margin: 15px 0px;
  display: flex;
  justify-content: flex-end;
}

.clear-btn {
  margin: 0px 10px;
}

.row-container {
  justify-content: flex-end;
  margin: 0px 0px 10px;
}

.search-container {
  margin: 0px 10px 10px 0px;
}

.flyer-button {
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  height: 52px !important;
}

.remove-org {
  margin-top: 10px;
}

.attendees-label {
  border-radius: 8px var(--Count, 0px) var(--Count, 0px) var(--Count, 0px);
  font-size: 23px;
  color: #fff;
  width: fit-content;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
  /* 23px */
  background: #fb915a;
  padding: 15px 22px;
}

.attendees-content-container {
  display: flex;
  gap: 30px;
  align-items: center;
}

.attendees-description-alert-message {
  font-size: var(--desktop-fz15);
  font-weight: var(--desktop-fw600);
  color: var(--pbss-p1);
}

.attendees-description-alert-info {
  background-color: #ffffff !important;
  border: 2px solid #2e56a4 !important;
}

.attendees-description-alert-icon {
  color: var(--pbss-p1) !important;
}

.attendees-description-alert-close-icon {
  font-size: var(--desktop-fz14) !important;
}

.attendees-description-alert-close-icon .anticon-close {
  color: var(--pbss-p1) !important;
}
.chapter-heading-underline {
  font-size: 21px !important;
}
.chapter-content-span {
  font-size: 16px !important;
  font-weight: var(--desktop-fw400);
  display: flex;
  text-align: justify;
}
.chapter-margin {
  margin-bottom: 15px;
}

.input-container {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin: 10px 0px;
}

.form-column {
  height: fit-content;
  flex: 1;
}

.detailLabelAndTextMessageContainer {
  display: flex;
  gap: 20px;
}

.detailsTextMessage {
  font-size: var(--desktop-fz14);
  font-weight: var(--desktop-fw500);
  color: gray;
}

.detailsTextMessage {
  font-size: var(--desktop-fz14);
  font-weight: var(--desktop-fw500);
  color: gray;
}
.sponsor-button-container {
  gap: 10px;
  display: flex;
  width: 77%;
  justify-content: space-between;
  align-items: center;
}
.input-container .ant-input-number {
  width: 100%;
}
.sponsorshipinfo-item {
  width: 77%;
}
.search-container {
  margin: 15px 0px;
  position: absolute;
  top: 20px;
  right: 20px;
  display: flex;
  margin: 25px 60px 20px 0;
}

.common-button {
  border: 1px solid var(--PBSS--Primary);
  background-color: var(--PBSS--Primary);
  display: flex;
  align-items: center;
}

.common-button a {
  color: var(--white) !important;
}

.common-button:hover {
  background-color: var(--PBSS--Primary) !important;
}

.add-content {
  color: var(--white) !important;
}

.common-cancel-button {
  border-color: var(--PBSS--Primary) !important;
  color: var(--PBSS--Primary) !important;
}

.common-cancel-button:hover {
  border-color: var(--PBSS--Primary) !important;
  color: var(--PBSS--Primary) !important;
}
.event-form-want-to-leave-popup-footer .ant-btn-primary {
  background-color: var(--PBSS--Primary) !important;
  color: var(--white) !important;
}

.event-form-want-to-leave-popup-footer .ant-btn-default:hover {
  border-color: var(--PBSS--Primary) !important;
  color: var(--PBSS--Primary) !important;
}

.admin-table .ant-pagination-total-text {
  position: absolute;
  right: 10px;
  border: 1px solid lightgray;
  border-radius: 5px;
  padding: 5px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.listing-container .ant-table-thead .ant-table-cell {
  background-color: var(--PBSS--Primary) !important;
  color: var(--white) !important;
  border: none !important;
  padding: 10px !important;
}

.listing-container {
  box-shadow: 1px 2px 10px #d3d3d3ab;
  padding-bottom: 1px;
  border-bottom: 4px;
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.06);
}

.listing-container .ant-table-cell {
  color: var(--black) !important;
  padding: 5px !important;
  font-size: 12px;
  font-weight: 500;
  border-bottom: 1px solid rgba(211, 211, 211, 0.704) !important;
}

.listing-container .ant-table-column-sorter {
  color: var(--white) !important;
}

.search-container .ant-btn:active {
  color: var(--PBSS--Primary) !important;
  border-color: var(--PBSS--Primary) !important;
}

.search-container .ant-btn:hover {
  color: var(--PBSS--Primary) !important;
  border-color: var(--PBSS--Primary) !important;
}

.reset-button:active {
  color: var(--PBSS--Primary) !important;
  border-color: var(--PBSS--Primary) !important;
}

.reset-button:hover {
  color: var(--PBSS--Primary) !important;
  border-color: var(--PBSS--Primary) !important;
}
.admin-buttons {
  background-color: var(--PBSS--Primary) !important;
  border-color: var(--PBSS--Primary) !important;
}

.ant-table-thead ::before {
  position: fixed !important;
}

.common-stay-tuned-container-icon > .anticon {
  color: var(--pbss-p1) !important;
}

.common-stay-tuned-button {
  background-color: var(--pbss-p1) !important;
}

/* .ant-popover-content {
  display: flex;
  justify-content: center;
}
.ant-popover-inner {
  width: 50%;
} */
/* color of active sorter icon */
/* .listing-container .ant-table-column-sorter-down.active{
  color: #0C6366 !important;
 }
 .listing-container .ant-table-column-sorter-up.active{
  color: #0C6366 !important;
 } */
.common-pbss-keyword-tooltip {
  max-width: 700px !important;
  width: 100%;
  max-height: 150px;
  min-height: 100px;
  overflow-y: scroll;
  overflow-x: scroll;
}

.common-pbss-keyword-tooltip-inner {
  background-color: var(--white) !important;
  color: var(--PBSS--Primary) !important;
  font-weight: 650;
}

.common-pbss-keyword-tooltip-arrow::before {
  background-color: var(--white) !important;
}

.common-pbss-tooltip-inner {
  background-color: var(--white) !important;
  color: var(--PBSS--Primary) !important;
  font-weight: 650;
}

.common-pbss-tooltip-arrow::before {
  background-color: var(--white) !important;
}

.map-tooltip {
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--pbss-p1);
  font-size: var(--desktop-fz17);
  color: white;
  padding: 2px 5px;
  border-radius: 4px;
  border-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.listing-container .ant-pagination-item-active a {
  color: var(--PBSS--Primary) !important;
}

.listing-container .ant-pagination-item-active {
  border-color: var(--PBSS--Primary) !important;
  border-radius: 4px !important;
  box-shadow: 1px 4px 5px rgba(211, 211, 211, 0.588);
}

.listing-container .ant-select-selector {
  border-radius: 4px;
}

.listing-container .ant-select-selector:hover {
  border-color: var(--PBSS--Primary) !important;
  border-radius: 4px;
}

.listing-container .ant-select-selector:focus {
  border-color: var(--PBSS--Primary) !important;
  border-radius: 0px;
}

.action-container {
  display: flex;
  align-items: center;
  gap: 4px;
  width: 100%;
}

.color-white {
  color: white;
}

.icon-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 3px 3px;
  gap: 3.281px;
  border-radius: 5.46px;
  color: #344050 !important;
  cursor: pointer;
  gap: 5px;
}

.adminActionsDropdown {
  color: black;
  font-size: var(--desktop-fz16);
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 0.15em;
  /* box-shadow: 1px 1px 10px rgb(255, 255, 255); */
}

.adminActionsDropdown2 {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 2px 8px;
  border: 1px solid transparent;
}

.adminActionsDropdown2:hover {
  box-shadow: 1px 4px 10px lightgray;
  border: 1px solid lightgray;
  padding: 2px 8px;
  border-radius: 5px;
}

.common-form-submit {
  width: fit-content;
  padding-right: 25px;
  padding-left: 25px;
  height: 40px;
  font-size: var(--desktop-fz17);
}

.flyer-event-details {
  border-radius: 6px;
  background: var(--pbss-p1) !important;
  display: flex;
  height: 52px;
  padding: 11.644px 29.111px;
  justify-content: center;
  align-items: center;
  gap: 11.644px;
  flex-shrink: 0;
  color: var(--White, var(--White, #fff));
  font-size: 23.289px;
  width: 100%;
  max-width: 300px;
  font-style: normal;
  font-weight: var(--desktop-fw500);
  line-height: 150%;
  /* 34.933px */
}

.flyer-event-details:hover {
  background: var(--white) !important;
  border: 1px solid var(--pbss-p1) !important;
  color: var(--pbss-p1) !important;
}

.user-common-form-cancel {
  background: white !important;
  color: var(--pbss-p1) !important;
  border: 1.456px solid var(--Red, #d9d9d9) !important;
}

.user-common-form-cancel:hover {
  background: var(--pbss-p1) !important;
  color: white !important;
}

.common-form-cancel {
  background: white !important;
  color: var(--PBSS--Primary) !important;
  border: 1.456px solid var(--Red, #d9d9d9) !important;
}

.common-form-cancel:hover {
  background: var(--PBSS--Primary) !important;
  color: white !important;
}

/* .view-history {
  background: #000000b3;
  padding: 4.2px;
  color: white !important;
}
.edit {
  background: #7f8c8d;
  padding-left: 6px;
}
.status {
  background: #6c83cb;
  padding-left: 6px;
}
.delete {
  background: #c76f55;
  padding: 3px 4px;
} */
.action-text {
  font-style: normal;
  font-weight: var(--desktop-fw500);
  line-height: 10.043px;
  color: var(--white);
}

.perks-key .anticon-close {
  color: red;
  font-size: 23px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon {
  width: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon-plus {
  width: 18px;
  margin-right: 5px;
}

.status-container {
  margin: 10px 0px;
  display: flex;
}

.common-button-header {
  border-radius: 6px;
  border: 1px solid var(--white);
  background: var(--PBSS--PrimaryV2);
  display: flex;
  min-width: 130px;
  font-size: 16.03px;
  font-style: normal;
  font-weight: var(--desktop-fw700);
  color: var(--white);
  height: 40px;
  padding: 8px 23px;
  gap: 5px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.date-picker {
  width: 100%;
}

.switch-container {
  display: flex;
  align-items: center;
  gap: 5p;
  width: 25%;
  justify-content: space-between;
}

.active-text {
  color: lightgray;
  transition: color 0.3s;
}

.disabled-text {
  color: lightgray;
  transition: color 0.3s;
}

/* Apply styles when switch is ON */
.active {
  color: var(--PBSS--Primary);
  color: white;
}

.active-dis {
  color: var(--PBSS--Primary);
}

/* Apply styles when switch is OFF */
.ant-switch-checked .ant-switch-inner {
  background-color: var(--PBSS--Primary);
}

.ant-switch-handle {
  width: 13px !important;
  height: 16px !important;
}

.ant-switch.ant-switch-small {
  width: 37px !important;
  height: 20px !important;
}

.pbss-loder {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9;
  top: 39%;
  left: 49%;
}

.admin-layout {
  /* background-color: var(--white) !important; */
  margin-top: 2px;
  padding: 20px 20px;
  padding-left: 0px;
  margin-left: 15.625rem;
  position: relative;
}

.small-side-bar {
  margin-left: 7.5rem;
  transition: all 0.3ms ease-in;
}

.place-icon {
  /* align-items: center; */
  gap: 10px;
}

.admin-layout .ant-divider-horizontal {
  margin: 10px 0px;
}

.common-select-register-item-option-content,
.ant-select-item-option-content {
  white-space: wrap !important;
}

.common-select-register .common-select-register-selection-item {
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  padding-right: 10px;
  white-space: nowrap;
  overflow-x: scroll;
  background: none !important;
}

.common-select-contact-selector {
  padding-right: 30px !important;
  background: white !important;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  display: flex;
  align-items: center;
}

.common-select-register-selector {
  padding-right: 30px !important;
  background: white !important;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  display: flex;
  align-items: center;
}

.common-select-register-selection-search {
  display: flex;
  align-items: center;
}

.search-input-filter {
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075) !important;
  font-family: 'inter';
}

.search-input-filter:hover {
  border: 1px solid #005d61;
}

.search-input-filter:focus {
  border: 1px solid #005d61;
}

.filter-search-select-selector {
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075) !important;
  border-color: #d9d9d9 !important;
}

.date-range-focused {
  border-color: #005d61 !important;
}

.date-range-active-bar {
  background: #005d61 !important;
}

.filter-search-select-focused {
  border-color: #005d61 !important;
}

.filter-search-select-selector:focus {
  border: 1px solid #005d61 !important;
  border-color: #005d61 !important;
}

.filter-search-select-selector:hover {
  border: 1px solid #005d61 !important;
}
.chapter-sponsorship-info {
  float: none !important;
}
.extend-popover-inner {
  width: 100% !important;
  height: 60% !important;
  max-width: 400px;
  overflow-y: scroll;
  overflow-x: hidden;
  cursor: pointer;
}

.extend-popover-inner::-webkit-scrollbar,
.view-details-drawer-body::-webkit-scrollbar,
.customized-modal-v2-body::-webkit-scrollbar {
  width: 8px;
  /* Adjust the width of the scroll bar */
  visibility: none;
}

.extend-popover-inner::-webkit-scrollbar-thumb,
.view-details-drawer-body::-webkit-scrollbar-thumb,
.customized-modal-v2-body::-webkit-scrollbar-thumb {
  background-color: #a3a3a3;
  /* Thumb color */
  border-radius: 10px;
  box-shadow: 1px 1px 10px lightgray;
}

.extend-popover-inner::-webkit-scrollbar-track,
.customized-modal-v2-body::-webkit-scrollbar-track,
.view-details-drawer-body::-webkit-scrollbar-track {
  background-color: #eee;
  /* Track color */
}

.extend-popover-event-inner {
  width: 100% !important;
  height: 80% !important;
  overflow-y: scroll;
  overflow-x: hidden;
  cursor: pointer;
}

.extend-popover-event-inner::-webkit-scrollbar {
  width: 10px;
  /* Adjust the width of the scroll bar */
  visibility: none;
}

.extend-popover-event-inner::-webkit-scrollbar-thumb {
  background-color: #a3a3a3;
  /* Thumb color */
  border-radius: 10px;
  box-shadow: 1px 1px 10px lightgray;
}

.extend-popover-event-inner::-webkit-scrollbar-track {
  background-color: #eee;
  /* Track color */
}

.pop-over-scroll {
  max-height: 400px;
  max-width: 800px;
}

.extend-popover-inner-content > div {
  max-height: 400px;
  max-width: 60vw;
  /* min-width: 40vw; */
  /* min-height: 200px; */
}

.extend-popover-content {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.logo-list-container {
  max-width: auto;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
  gap: 16px;
}

.extend-logo-container {
  display: flex;
  margin-top: -30px;
  flex-direction: column-reverse;
}

.extend-logo-container > button {
  width: fit-content;
  position: relative;
  top: 40px;
  border: none;
  border-radius: 0px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  padding: 5px 6px;
}

.extend-popover-event-temaplate {
  max-height: 400px;
  max-width: 1200px;
}

.extend-popover-event-temaplate > div {
  max-height: 400px;
  max-width: 60vw;
  /* min-width: 40vw; */
  /* min-height: 200px; */
}

.extend-popover-event-temaplate-inner {
  width: 100% !important;
  height: 60% !important;
  overflow-y: scroll;
  overflow-x: hidden;
  cursor: pointer;
}

.extend-popover-event-temaplate-inner::-webkit-scrollbar {
  width: 10px;
  /* Adjust the width of the scroll bar */
  visibility: none;
}

.extend-popover-event-temaplate-inner::-webkit-scrollbar-thumb {
  background-color: #a3a3a3;
  /* Thumb color */
  border-radius: 10px;
  box-shadow: 1px 1px 10px lightgray;
}

.extend-popover-event-temaplate-inner::-webkit-scrollbar-track {
  background-color: #eee;
  /* Track color */
}

.extend-popover-event-temaplate {
  max-height: 400px;
  max-width: 800px;
}

.common-select-register
  .common-select-register-selection-item::-webkit-scrollbar {
  display: none;
}

.total-cost {
  padding-left: 20px;
}

.fixed-size-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
}

.currency-text {
  padding: 0px 42px;
}

.sidebar-menu-light {
  background: none !important;
}

.sidebar-menu {
  border-inline-end: none !important;
  margin-top: 10px;
  padding: 0px 10px;
}

.sidebar-menu-item-selected {
  background-color: var(--color-darkslategray-100) !important;
  border: 1px solid var(--color-darkslategray-100) !important;
  color: var(--white) !important;
}

.sidebar-menu-mini-light {
  background: none !important;
}

.sidebar-menu-mini {
  border-inline-end: none !important;
  margin-top: 10px;
  padding: 0px 10px;
}

.sidebar-menu-mini-item-selected {
  background-color: var(--color-darkslategray-100) !important;
  border: 1px solid var(--color-darkslategray-100) !important;
  color: var(--white) !important;
}

.head-main-container::-webkit-scrollbar {
  width: 8px;
}

.row-main-container {
  width: 100%;
}

.dynamic-checkbox-wrapper {
  display: flex;
  justify-content: center;
}

.head-main-container::-webkit-scrollbar-thumb {
  background-color: #6a737d98;
  border-radius: 10px;
}

.menu-sidebar-component-children {
  width: inherit;
  /* display: flex; */
  height: 93.2vh;
  /* flex-direction: column;
  justify-content: space-between; */
}

.option-details {
  width: 100%;
}

.head-main-container::-webkit-scrollbar-track {
  background-color: #f1f1f19e;
}

.head-main-container {
  background-color: white;
  border-radius: 8px;
  max-height: 84vh;
  overflow-y: scroll;
  box-shadow: 0 7px 14px 0 rgba(65, 69, 88, 0.1),
    0 3px 6px 0 rgba(0, 0, 0, 0.07) !important;
}

.sidebar-menu-item,
.sidebar-menu-submenu-title {
  background-color: var(--white);
  margin-block: 8px !important;
  color: var(--color-darkslategray-100);
  border: 1px solid #d5f2f3;
}

.sidebar-menu-title-content {
  text-wrap: wrap;
  line-height: 18px;
}

.sidebar-menu-submenu-title {
  color: var(--color-darkslategray-100) !important;
}

.sidebar-menu-sub {
  background: none !important;
  padding: 0px 5px !important;
  height: fit-content;
}

.sidebar-menu-sub > .sidebar-menu-item {
  padding-left: 28px !important;
}

.sidebar-menu-bottom {
  background: none !important;
  border-inline-end: none !important;
  margin-top: 90%;
  padding: 0px 10px;
}

.sidebar-menu-bottom-item,
.sidebar-menu-bottom-submenu-title {
  background-color: var(--white);
  margin-block: 8px !important;
  color: var(--color-darkslategray-100);
  border: 1px solid #d5f2f3;
}

.sidebar-menu-mini-item,
.sidebar-menu-mini-submenu-title {
  background-color: var(--white);
  margin-block: 8px !important;
  color: var(--color-darkslategray-100);
  border: 1px solid #d5f2f3;
}

.sidebar-menu-mini-title-content {
  line-height: 18px;
}

.sidebar-menu-mini-submenu-title {
  color: var(--color-darkslategray-100) !important;
}

.sidebar-menu-mini-sub {
  background: none !important;
  padding: 0px 5px !important;
  height: fit-content;
  margin-bottom: -34px !important;
}

.sidebar-menu-mini-sub > .sidebar-menu-mini-item {
  padding-left: 28px !important;
}

.sidebar-menu-mini-bottom {
  background: none !important;
  border-inline-end: none !important;
  margin-top: 90%;
  padding: 0px 10px;
}

.sidebar-menu-mini-bottom-item,
.sidebar-menu-mini-bottom-submenu-title {
  background-color: var(--white);
  margin-block: 8px !important;
  color: var(--color-darkslategray-100);
  border: 1px solid #d5f2f3;
}

.menu-container {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 80vh;
}

.menu-sidebar-component-trigger > span {
  position: fixed;
  display: flex;
  justify-content: end;
  padding: 10px;
  background: rgb(255, 255, 255);
  width: fit-content;
  box-shadow: 1px 1px 10px lightgray;
  border-radius: 4px;
  float: right;
  margin: -28px 20px;
  margin-bottom: 0px;
  cursor: pointer;
}

.menu-sidebar-component-trigger {
  position: relative;
  padding-bottom: 20px;
  width: 0px !important;
}

.menu-sidebar-component {
  position: fixed;
  flex: 0 0 200px;
  max-width: 220px;
  min-width: 220px;
  max-height: 100vh;
  overflow-y: scroll;
  width: 200px;
  background-color: white;
  margin: 20px;
  border-radius: 8px;
  box-shadow: 0 7px 14px 0 rgba(65, 69, 88, 0.1),
    0 3px 6px 0 rgba(0, 0, 0, 0.07) !important;
  /* transition: transform 0.3s ease; */
}

.sidebar-menu-submenu-popup.sidebar-menu-submenu {
  background: white !important;
  max-height: 62vh;
}

.menu-sidebar-component-children .sidebar-menu-submenu {
  margin-bottom: 200px;
}

.sidebar-menu-mini-submenu-popup.sidebar-menu-mini-submenu {
  background: white !important;
  max-height: 62vh;
}

.menu-sidebar-component-children .sidebar-menu-mini-submenu {
  margin-bottom: 200px;
}

.sidebar-botton-container {
  display: flex;
  flex-direction: column;
  /* height: 40vh; */
  border-radius: 10px;
  position: fixed;
  gap: 8px;
  width: 200px;
  bottom: 24px;
  padding: 18px 17px;
  padding-bottom: 74px;
  background: white;
  justify-content: end;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.046);
}

.jodit-add-new-line::after {
  background-color: white !important;
}

.refund-admin-input-affix-wrapper {
  width: max-content;
  min-width: 100px;
}

.refund-admin-input-affix-wrapper:hover {
  border-color: var(--PBSS--Primary) !important;
}

.refund-admin-input-affix-wrapper:focus {
  border-color: var(--PBSS--Primary) !important;
}

.refund-admin-container {
  display: flex;
  justify-content: flex-end;
  gap: 12px;
}

.admin-registration-container {
  display: flex;
  justify-content: flex-end;
  gap: 12px;
}

.sidebar-extend-mini {
  padding: 8px;
  background: rgba(255, 255, 255, 0);
  padding-bottom: 71px;
  width: auto;
}

.ant-table-pagination.ant-pagination {
  margin: 0 !important;
  padding: 16px 0;
  background-color: white;
  border-bottom-left-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
}

.sidebar-botton-container > button > img,
.switch-to-user-link > button > img {
  margin-right: 5px !important;
}

.switch-to-user-link-collapsed > button {
  margin: 0px 4px;
  height: 40px;
  width: 80%;
  line-height: 40px;
  display: flex;
  align-items: center;
  color: var(--color-darkslategray-100);
  border: 1px solid #d5f2f3 !important;
  cursor: pointer;
}

.sidebar-botton-container > button,
.switch-to-user-link > button {
  margin: 0px 4px;
  width: 100%;
  height: 40px;
  line-height: 40px;
  display: flex;
  align-items: center;
  color: var(--color-darkslategray-100);
  border: 1px solid #d5f2f3 !important;
  cursor: pointer;
}

.sidebar-botton-container > button:hover,
.switch-to-user-link > button:hover,
.switch-to-user-link-collapsed > button:hover {
  color: var(--color-darkslategray-100) !important;
  background-color: rgba(0, 0, 0, 0.06);
  border: 1px solid #d5f2f3 !important;
}

.login-form-button {
  width: 100%;
  background: var(--PBSS--Primary) !important;
  font-size: var(--desktop-fz15);
}

.forgot-auth {
  display: flex;
  justify-content: flex-end;
}

.auth-links {
  color: var(--PBSS--Primary);
}

.auth-links:hover {
  color: var(--PBSS--Primary);
}

.send-email-success .ant-result-icon > .anticon {
  color: var(--pbss-p1) !important;
}

.send-email-success .ant-result-title {
  color: var(--pbss-p1) !important;
  font-weight: var(--desktop-fw600);
}

.goto_signin_button {
  background: var(--pbss-p1) !important;
  font-weight: var(--desktop-fw600);
}

.goto_signin_button > a {
  color: var(--white) !important;
  display: flex;
  align-items: center;
}

.reset_back_button {
  border-color: var(--pbss-p1) !important;
  color: var(--pbss-p1) !important;
  font-weight: var(--desktop-fw600);
}

.reset_back_button .ant-btn-default {
  border-color: var(--PBSS--Primary) !important;
  color: var(--PBSS--Primary) !important;
}

.reset_back_button > a {
  color: var(--PBSS--Primary) !important;
  display: flex;
  align-items: center;
}

.listing-container .ant-checkbox-checked {
  background-color: var(--PBSS--Primary) !important;
  color: #898989 !important;
}

.editable-options {
  /* min-width: fit-content;
  max-width: 300px !important;
  width: 220px !important; */
  width: 100%;
}

.table-skeleton-title {
  width: 100% !important;
  height: 60px !important;
}

.table-skeleton-paragraph > li {
  width: 100% !important;
  height: 30px !important;
}

/* admin upload form */
.upload-text-logos-admin {
  color: var(--black);
  font-size: var(--desktop-fz20);
  font-style: normal;
  font-weight: var(--desktop-fw500);
  line-height: 150%;
  display: flex;
  justify-content: center;
}

.upload-type-admin {
  text-align: center;
}

.upload-cross-admin {
  color: #408689;
  font-size: var(--desktop-fz15);
  font-style: normal;
  font-weight: var(--desktop-fw500);
  line-height: 150%;
  margin: 0px 10px;
}

.upload-desc-admin {
  color: rgba(27, 30, 31, 0.49);
  font-size: 14.5px;
  font-style: normal;
  font-weight: var(--desktop-fw500);
  line-height: 150%;
  /* 17.821px */
}

.upload-logo-admin {
  font-size: var(--desktop-fz40);
  color: rgba(44, 120, 123, 1);
  display: flex;
  justify-content: center;
}

.upload-logo-banner-admin {
  font-size: var(--desktop-fz25);
  color: rgba(44, 120, 123, 1);
}

.upload-admin .ant-upload-list-item-done {
  border-color: rgba(18, 127, 22, 1) !important;
}

.upload-admin .ant-upload {
  width: 100%;
  display: flex;
  padding: 20px;
  justify-content: center;
  cursor: pointer;
}

.upload-admin .ant-upload-list-item-done .anticon-delete {
  color: rgba(18, 127, 22, 1) !important;
  font-size: var(--desktop-fz19) !important;
}

.upload-admin .ant-upload-list-item-name {
  color: rgba(18, 127, 22, 1) !important;
  font-size: var(--desktop-fz15) !important;
}

.upload-admin .ant-upload-select {
  border: 1px solid #d6d6d6 !important;
  width: 100% !important;
  display: flex !important;
  justify-content: center;
  align-items: center;
  padding: 20px 0px;
}

/* .upload .ant-upload-select {
  border-radius: 9px;
} */
.upload-button-admin {
  border-radius: 4.623px;
  border: 0.925px solid #c1baba;
  background: var(--white);
  height: 48px;
  padding: 22px 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* user panel */
.user-common-button {
  display: flex;
  padding: 23px 20px;
  align-items: center;
  gap: 9.194px;
  border-radius: 6px;
  font-size: var(--desktop-fz20);
  background: linear-gradient(
    47deg,
    var(--PBSS--Primary)-85.95%,
    var(--PBSS--Primary) 216.03%
  );
}

.user-plus-icon {
  display: flex;
  align-items: center;
  font-size: var(--desktop-fz21);
}

.width-100 {
  width: 100% !important;
}

/* user  Jobs and Ads Listing Forms */
.common-form-modal {
  width: 70% !important;
  height: 100% !important;
  /* padding: 50px 0px; */
}

.modal-container-v2 {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 0px;
}

.modal-heading {
  color: var(--PBSS--Primary);
  font-size: var(--desktop-fz30);
  font-style: normal;
  font-weight: var(--desktop-fw700);
  line-height: normal;
}

.modal-heading-v2 {
  color: var(--pbss-p1);
  font-size: var(--desktop-fz22);
  font-style: normal;
  font-weight: var(--desktop-fw700);
  line-height: normal;
  text-wrap: wrap;
}

.modal-desc {
  display: flex;
  color: #747474;
  font-size: var(--desktop-fz17);
  font-style: normal;
  font-weight: var(--desktop-fw450);
  line-height: var(--desktop-lh170);
}

.modal-desc-v2 {
  display: block;
  text-wrap: wrap;
  color: #515151;
  font-size: var(--desktop-fz12);
  font-style: normal;
  font-weight: var(--desktop-fw400);
  line-height: 150%;
  width: 100%;
}

.common-form-modal > .ant-modal-content {
  padding: 50px !important;
}

.form-card {
  box-shadow: none !important;
  padding: 0px !important;
}

.form-label-text {
  color: var(--Black);
  font-size: 23.076px;
  font-style: normal;
  font-weight: var(--desktop-fw450);
  line-height: 150%;
}

.register-form-input-container .ant-input-affix-wrapper:focus {
  box-shadow: none !important;
}

.common-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
}

.common-input-user:focus {
  border-color: var(--PBSS--Primary) !important;
  box-shadow: none !important;
}

.common-input-user:hover {
  border-color: var(--PBSS--Primary) !important;
}

.common-input-user-affix-wrapper:focus {
  border-color: var(--PBSS--Primary) !important;
}

.common-input-user-affix-wrapper:hover {
  border-color: var(--PBSS--Primary) !important;
}

.form-input-v-has-error:hover {
  border-color: #ff4d4f !important;
}

.form-input-v-has-error-has-error:focus {
  border-color: #ff4d4f !important;
}

.common-input-user-status-error:hover {
  border-color: #ff4d4f !important;
}

.common-input-user-status-error:focus {
  border-color: #ff4d4f !important;
}

.common-input-user-affix-wrapper-status-error:hover {
  border-color: #ff4d4f !important;
}

.common-input-user-affix-wrapper-status-error:focus {
  border-color: #ff4d4f !important;
}

.common-input-container,
.common-input-container-v2 {
  width: 100%;
  height: 100%;
}

.common-input-user-affix-wrapper {
  padding: 0px 11px !important;
}

.common-input-container .ant-input::placeholder {
  color: var(--black) !important;
  font-size: var(--desktop-fz21);
  font-style: normal;
  font-weight: var(--desktop-fw500);
  line-height: 150%;
  display: flex;
  align-items: center;
  padding: 0px 0px 0px 15px;
}

.form-input-datepicker .form-input-datepicker-input > input::placeholder {
  color: var(--black) !important;
  font-size: var(--desktop-fz20);
  font-style: normal;
  font-weight: 520;
  line-height: 150%;
  display: flex;
  align-items: center;
  padding: 0px 0px 0px 15px;
}

.form-input-datepicker-v2 .form-input-datepicker-v2-input > input::placeholder {
  color: #1e3d7b !important;
  font-size: var(--desktop-fz16);
  font-style: normal;
  font-weight: var(--desktop-fw400);
  line-height: 150%;
  display: flex;
  align-items: center;
  padding: 0px 0px 0px 0px;
}

.form-input-datepicker-v2 .form-input-datepicker-v2-input > input {
  font-size: var(--desktop-fz16);
  color: #1e3d7b !important;
}

.form-input-datepicker .form-input-datepicker-input > input {
  font-size: var(--desktop-fz20);
}

.main-content-container {
  width: 100%;
  padding-top: 10px;
}

.special-payment-form-item-item-has-error .special-payment-input {
  border: 1px solid red;
}

.special-payment-form-item-item-has-error .special-payment-input:focus-visible {
  outline: none !important;
}

.form-input {
  width: 100%;
  padding: 20px 10px;
  border-radius: 7px;
  border: 1px solid #dadada;
  background: #f6f6f6;
  font-size: var(--desktop-fz20);
  font-weight: var(--desktop-fw450);
}

.form-input-v2 {
  width: 100%;
  padding: 10px 10px;
  border: 1px solid #bcd0f7;
  background-color: #f0f5ff;
  border-radius: 5px;
  font-size: var(--desktop-fz16);
  font-weight: var(--desktop-fw400);
  line-height: 150%;
  color: #1e3d7b;
}

.attachment-url-input-v2-group .attachment-url-input-v2-group-addon {
  padding: 10px 10px;
  border: 1px solid #bcd0f7 !important;
  border-right: none !important;
  background-color: #f0f5ff;
  border-top-left-radius: 5px;
  border-top-right-radius: 0px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 0px;
  font-size: var(--desktop-fz16);
  font-weight: var(--desktop-fw400);
  line-height: 150%;
  color: #1e3d7b;
}

.attachment-url-input-v2-group-wrapper-status-error
  .attachment-url-input-v2-group-addon {
  border-color: #ff4d4f !important;
}

.attachment-url-input-v2 {
  padding: 10px 10px;
  border: 1px solid #bcd0f7;
  background-color: #f0f5ff;
  border-top-left-radius: 5px;
  border-top-right-radius: 0px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 0px;
  font-size: var(--desktop-fz16);
  font-weight: var(--desktop-fw400);
  line-height: 150%;
  color: #1e3d7b;
}

.attachment-url-input-v2::placeholder {
  color: #1e3d7b !important;
  line-height: 150%;
  display: flex;
  align-items: center;
  padding: 0px 0px 0px 0px;
  font-size: var(--desktop-fz16);
  font-weight: var(--desktop-fw400);
}

.form-select-v2 {
  width: 100%;
  border: 1px solid #bcd0f7;
  background-color: #f0f5ff;
  border-radius: 5px;
  line-height: 150%;
}

.form-select-v2 .ant-select-selector {
  padding: 10px !important;
  height: 100% !important;
  border: none !important;
  background: none !important;
}

.form-select-v2 .ant-select-selection-item {
  font-size: var(--desktop-fz16) !important;
  font-weight: var(--desktop-fw400) !important;
  color: #1e3d7b !important;
}

.form-select-v2 .ant-select-selection-placeholder {
  color: #1e3d7b !important;
  line-height: 150%;
  display: flex;
  align-items: center;
  padding: 0px 0px 0px 15px;
  font-size: var(--desktop-fz16);
  font-weight: var(--desktop-fw400);
}

.form-input-text-area-class .ant-input::placeholder {
  color: #1e3d7b !important;
  line-height: 150%;
  display: flex;
  align-items: center;
  padding: 0px 0px 0px 15px;
  font-size: var(--desktop-fz16);
  font-weight: var(--desktop-fw400);
}
.admin-committee-profile-image {
  width: 100% !important;
}
.admin-committee-profile-image-img {
  object-fit: cover;
  width: 100% !important;
}

.common-input-container-v2 .ant-input::placeholder {
  color: #1e3d7b !important;
  line-height: 150%;
  display: flex;
  align-items: center;
  padding: 0px 0px 0px 0px;
  font-size: var(--desktop-fz16);
  font-weight: var(--desktop-fw400);
}

.form-input-datepicker {
  width: 100%;
  padding: 20px 10px;
  border-radius: 7px;
  border: 1px solid #dadada;
  background: #f6f6f6;
  font-size: var(--desktop-fz20);
  font-weight: var(--desktop-fw450);
}

/* Select: register-status-error is form item class and when this class is available we are changing the border color of it's selector 
   Editor: ant-form-item-has-error is form item class and when this class is available we are selecting our custom class on editor and then changing main container of editor
*/
.common-select-register-status-error > .common-select-register-selector,
.common-input-container-v2 .ant-select-status-error,
.ant-form-item-has-error .errorBorderOnEditor .jodit-container {
  border-color: #ff4d4f !important;
}

.form-input-datepicker-v2 {
  width: 100%;
  padding: 10px 10px;
  border-radius: 5px;
  border: 1px solid #bcd0f7;
  background: #f0f5ff;
  font-size: var(--desktop-fz16);
  font-weight: var(--desktop-fw400);
}

.upload-text-logos {
  color: var(--black);
  font-size: var(--desktop-fz20);
  font-style: normal;
  font-weight: var(--desktop-fw500);
  line-height: 150%;
  display: flex;
  justify-content: center;
}

.upload-text-logos-v2 {
  color: #2e56a4;
  font-size: var(--desktop-fz20);
  font-style: normal;
  font-weight: var(--desktop-fw500);
  line-height: 150%;
  display: flex;
  justify-content: center;
}

.upload-cross {
  color: #408689;
  font-size: var(--desktop-fz16);
  font-style: normal;
  font-weight: var(--desktop-fw500);
  line-height: 150%;
  margin: 0px 10px;
}

.upload-cross-v2 {
  color: #395282;
  font-size: var(--desktop-fz16);
  font-style: normal;
  font-weight: var(--desktop-fw500);
  line-height: 150%;
  margin: 0px 10px;
}

.upload-desc {
  color: rgba(27, 30, 31, 0.49);
  font-size: var(--desktop-fz16);
  font-style: normal;
  font-weight: var(--desktop-fw500);
  line-height: 150%;
  /* 17.821px */
}

.upload-desc-v2 {
  color: #395282;
  font-size: var(--desktop-fz16);
  font-style: normal;
  font-weight: var(--desktop-fw500);
  line-height: 150%;
  /* 17.821px */
}

.upload-logo {
  font-size: var(--desktop-fz40);
  color: rgba(44, 120, 123, 1);
  display: flex;
  justify-content: center;
}

.upload-logo-v2 {
  font-size: var(--desktop-fz40);
  color: #395282;
  display: flex;
  justify-content: center;
}

.upload-logo-banner {
  font-size: var(--desktop-fz25);
  color: rgba(44, 120, 123, 1);
}

.upload .ant-upload-list-item-done {
  border-color: rgba(18, 127, 22, 1) !important;
}

.upload .ant-upload-list-item-done .anticon-delete {
  color: rgba(18, 127, 22, 1) !important;
  font-size: var(--desktop-fz19) !important;
}

.upload .ant-upload-list-item-name {
  color: rgba(18, 127, 22, 1) !important;
  font-size: var(--desktop-fz15) !important;
}

.common-input-container .ant-upload-select {
  border: 1px solid #d6d6d600 !important;
  background: #f7fdfd;
  width: 100% !important;
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.common-input-container-v2 .ant-upload-select {
  border: 0.5px solid #bcd0f7 !important;
  background: #f0f5ff;
  border-radius: 5px;
  width: 100% !important;
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.upload .ant-upload-select {
  border-top-left-radius: 9px !important;
  border-bottom-left-radius: 9px !important;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  padding: 40px 0px;
}

.upload-button {
  border-radius: 4.623px;
  border: 0.925px solid #c1baba;
  background: var(--white);
  padding: 22px 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.upload-cross-banner {
  color: #408689;
  font-size: var(--desktop-fz20);
  font-style: normal;
  font-weight: var(--desktop-fw500);
  line-height: 150%;
  margin: 0px 10px;
}

.upload-desc-banner {
  color: rgba(27, 30, 31, 0.49);
  font-size: var(--desktop-fz20);
  font-style: normal;
  font-weight: var(--desktop-fw500);
  line-height: 150%;
  /* 17.821px */
}

.common-input-container .ant-picker-suffix {
  color: rgba(32, 113, 118, 1) !important;
  font-size: var(--desktop-fz30);
}

.payment-text {
  color: #3c3c3c;
  font-size: var(--desktop-fz20);
  font-style: normal;
  font-weight: var(--desktop-fw450);
  line-height: 162%;
  /* 32.4px */
}

.payment-text-v2 {
  color: #3c3c3c;
  font-size: var(--desktop-fz20);
  font-style: normal;
  font-weight: var(--desktop-fw450);
  line-height: 162%;
  /* 32.4px */
}

.alert-info {
  width: 100%;
  margin: 40px 0px;
}

.alert-heading {
  color: var(--PBSS--Primary);
  font-size: 20.29px;
  font-style: normal;
  font-weight: var(--desktop-fw500);
  line-height: 150%;
  /* 30.435px */
}

.alert-desc {
  color: #717171;
  font-size: var(--desktop-fz14);
  font-style: normal;
  font-weight: var(--desktop-fw450);
  line-height: 150%;
  /* 21px */
}

.alert-container {
  padding: 10px;
}

.alert-info .ant-alert-success {
  border: 1px solid #a8cfd0 !important;
}

.close-icon {
  top: 6px;
  font-size: var(--desktop-fz19);
  color: var(--PBSS--Primary);
  right: 6px;
  position: absolute;
}

.close-icon-modal {
  top: 60px;
  font-size: var(--desktop-fz43);
  color: var(--PBSS--Primary);
  right: 28px;
  position: absolute;
}

.close-icon-modal-v2 {
  font-size: var(--desktop-fz43);
  color: var(--pbss-p1);
  right: 28px;
  position: absolute;
}

.ant-modal-close:hover {
  background: none !important;
}

.modal-footer-container {
  display: flex;
  justify-content: space-between;
  padding-top: 10px;
}

.modal-footer-button {
  display: flex;
  justify-content: flex-end;
  gap: 20px;
}

.checkout-button {
  display: flex;
  padding: 30px 20px;
  justify-content: center;
  align-items: center;
  border-radius: 6.294px;
  background: var(--PBSS--Primary);
}

.checkout-button-v2 {
  display: flex;
  padding: 25px 35px;
  justify-content: center;
  align-items: center;
  border-radius: 5;
  background: var(--pbss-p3);
  color: #fff;
}

.checkout-button:hover {
  background: var(--PBSS--Primary) !important;
}

.checkout-text {
  color: var(--white);
  font-size: 24.125px;
  font-style: normal;
  font-weight: var(--desktop-fw500);
  line-height: 150%;
  /* 36.188px */
}

.checkout-text-v2 {
  color: var(--white);
  font-size: var(--desktop-fz14);
  font-style: normal;
  font-weight: var(--desktop-fw400);
  line-height: 150%;
}

.cancel-button {
  padding: 30px 50px;
  align-items: center;
  display: flex;
  justify-content: center;
  border-radius: 6.294px;
  border: 1.049px solid #eb5757;
}

.cancel-button-v2 {
  padding: 25px 35px;
  align-items: center;
  display: flex;
  justify-content: center;
  border-radius: 5px;
  border: 1px solid var(--pbss-p3);
}

.cancel-button:hover {
  border: 1.049px solid #eb5757 !important;
}

.cancel-text {
  color: #e32727;
  font-size: 24.125px;
  font-style: normal;
  font-weight: var(--desktop-fw500);
  line-height: 150%;
  /* 36.188px */
}

.cancel-text-v2 {
  color: var(--pbss-p3);
  font-size: var(--desktop-fz14);
  font-style: normal;
  font-weight: var(--desktop-fw500);
  line-height: 150%;
  /* 36.188px */
}

.cancel-text:hover {
  color: #e32727 !important;
}

/* Map: allowing scroll  */
.map-js-container,
.map-js-container > svg:first-child {
  touch-action: auto !important;
}

/* Map: removing border */
.jvm-region {
  fill: rgb(190, 200, 227) !important;
  fill-opacity: 1 !important;
  stroke: rgb(190, 200, 227) !important;
  stroke-width: 2px !important;
  stroke-opacity: 1 !important;
}

/* Modal Blur Effect */

.customized-modal-v2-wrap {
  background: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(14px);
}

.customized-modal-v2-content {
  padding-top: 30px !important;
}

.customized-modal-v2-close:hover {
  background: none !important;
}

.description-items .ant-descriptions-item-label {
  color: var(--PBSS--Primary) !important;
  font-weight: 550;
  font-size: var(--desktop-fz15) !important;
}

.description-items .ant-descriptions-item-content {
  color: var(--PBSS--Primary) !important;
  font-size: var(--desktop-fz15) !important;
}

.attendees-table .ant-table-thead > tr > th {
  background: var(--table-background) !important;
  border-radius: 0px !important;
  color: #fff;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
  text-align: center;
}

.attendees-table-container-cell > h4 {
  display: flex;
  justify-content: center;
  align-items: center;
}

.attendees-table-container-thead > tr > th {
  background: var(--table-background) !important;
  border-radius: 0px !important;
  color: #fff !important;
  font-size: 15px;
  font-style: normal;
  font-weight: 600 !important;
  line-height: normal !important;
  text-align: center !important;
}

.attendees-table-container-measure-row {
  display: block;
}

.attendees-table-container-tbody > tr > td {
  padding: 7px 8px !important;
}

.attendees-table-container-thead ::before {
  position: fixed !important;
}

.saveAndCancelWrapperForAds {
  display: flex;
}

.add-attendee:hover {
  border: 1.057px solid var(--pbss-p1);
  color: var(--white) !important;
  background: var(--pbss-p1) !important;
}

.attendees-table .ant-table-wrapper table {
  border: 1px solid #f0f0f0 !important;
}

.light {
  border-radius: 2.692px;
  border: 0.673px solid var(--pbss-p1);
  background: #fff;
}

.dark {
  border-radius: 2.692px;
  background: var(--pbss-p1, 95%);
  color: white;
  font-size: var(--desktop-fz18);
  font-weight: var(--desktop-fw700) !important;
  border: none;
}

.action-navigation {
  display: flex;
  width: 70.214px;
  height: 45.038px;
  padding: 10.976px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10.976px;
  flex-shrink: 0;
  border-radius: 8.256px;
  cursor: pointer;
}

.action-button {
  display: inline-flex;
  height: 44px;
  justify-content: center;
  align-items: center;
  gap: 21px;
  flex-shrink: 0;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 6px;
  border: 1px solid var(--Black, #000);
  color: var(--Black, #000);
  font-size: var(--desktop-fz16);
  font-style: normal;
  font-weight: var(--desktop-fw500);
  background: none;
  line-height: 150%;
}

.bg-black {
  background: white;
  border: none;
  color: var(--Black, #000000);
}

.action-button:hover {
  background-color: #000000;
  color: white;
}

/*Event Option details */
.option-details-container {
  font-size: var(--desktop-fz16);
  font-weight: var(--desktop-fw400);
  padding: 20px 0px;
}

.speakers-form {
  padding: 10px 10px !important;
  gap: 2px !important;
}

.speaker-justify-right {
  display: flex;
  justify-content: end !important;
}

.speaker-modal-space {
  display: flex;
  justify-content: left;
  gap: 20px;
  width: 100%;
}

.speaker-add-name-item {
  margin-right: 20px !important;
  width: 80%;
}

.speaker-submit-item {
  display: flex !important;
  align-items: center;
}

.speaker-form {
  display: flex;
  width: 100%;
}

.speaker-modal-space-item {
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  padding: 10px 10px;
  width: 100%;
}

.speaker-modal-container-content {
  overflow-y: scroll;
  max-height: 750px;
  height: 100%;
}

/* sticky heading in regular attendee and on sponsor registration */
.stickyEventName {
  position: sticky;
  top: 100px;
  z-index: 100;
  width: 100%;
}

.faq-section-contact-us {
  margin-top: 21px;
}

.faq-contact-us-content {
  font-size: 16px;
}

@media screen and (max-width: 450px) {
  .stickyEventName {
    top: 80px;
  }
  .chapter-heading-underline {
    font-size: 18px !important;
  }
  .chapter-content-span {
    font-size: 14px !important;
  }
}

@media screen and (max-height: 900px) {
  .sidebar-botton-container {
    bottom: 10px;
  }
}

@media screen and (max-height: 700px) {
  .sidebar-botton-container {
    bottom: 6px;
  }
}

@media screen and (max-width: 1440px) {
  .upload .ant-upload-select {
    padding: 61.5px 0px !important;
  }

  .upload-desc {
    font-size: var(--desktop-fz13);
  }

  .upload-cross {
    font-size: var(--desktop-fz13);
  }

  .upload-text-logos {
    font-size: var(--desktop-fz18) !important;
  }

  .alert-info {
    width: 80% !important;
  }
}

@media screen and (min-width: 1024px) {
  .common-select-register .common-select-register-selection-item {
    max-width: 100%;
  }

  .time-zone-selection .common-select-register-selection-item {
    max-width: 100% !important;
  }
}

@media screen and (max-width: 1024px) {
  .common-select-register .common-select-register-selection-item {
    max-width: 100%;
  }

  .time-zone-selection .common-select-register-selection-item {
    max-width: 100% !important;
  }

  .common-form-modal > .ant-modal-content {
    padding: 50px 30px !important;
  }

  .close-icon-modal {
    top: 0px !important;
    right: 0px !important;
  }

  .modal-footer-container {
    display: flex !important;
    flex-direction: column;
    gap: 10px;
  }

  .modal-footer-button {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .checkout-button-v2,
  .cancel-button-v2 {
    flex: 1;
  }

  .payment-text-v2 {
    font-size: var(--desktop-fz16);
  }

  .modal-heading {
    font-size: var(--desktop-fz27) !important;
  }

  .common-form-modal {
    width: 85% !important;
  }

  .upload .ant-upload-select {
    padding: 64.5px 0px !important;
  }

  .upload-type {
    padding: 0px 10px;
  }

  .upload-desc {
    font-size: var(--desktop-fz13);
  }

  .upload-cross {
    font-size: var(--desktop-fz13);
  }
}

@media screen and (max-width: 768px) {
  .common-pbss-keyword-tooltip {
    max-width: 300px;
  }

  .customized-modal-v2-body .ant-card-head {
    padding-left: 0px !important;
  }

  .customized-modal-v2-body .ant-card-body {
    padding: 0px !important;
  }

  .modal-container-v2 {
    margin-bottom: 10px;
  }

  .upload .ant-upload-select {
    padding: 40.5px 0px !important;
  }

  .alert-info {
    width: 100% !important;
  }

  .upload-type {
    display: grid;
    justify-content: center;
  }

  .upload-logo {
    font-size: var(--desktop-fz25);
  }

  .upload-cross {
    margin: 0px 16px;
  }

  .payment-text-v2 {
    font-size: var(--desktop-fz16);
  }

  .modal-footer-container {
    display: flex !important;
    flex-direction: column;
    gap: 10px;
  }

  .modal-footer-button {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .checkout-button-v2,
  .cancel-button-v2 {
    flex: 1;
  }
}

@media screen and (max-width: 550px) {
  .reg-navigation-btn {
    font-size: var(--desktop-fz14);
    width: 100%;
    padding: 20px !important;
  }
}

@media screen and (max-width: 480px) {
  .form-input-form-item-class-item {
    margin-bottom: 10px !important;
  }

  .pbssMainContainerRow {
    margin-top: 0px !important;
  }

  .close-icon-modal-v2 {
    right: 0px;
    top: 0px;
    position: absolute;
  }

  .close-icon-modal-v2 > svg {
    width: 30px;
    height: 30px;
  }

  .form-input-v2 {
    padding: 10px 10px;
  }

  .common-input-container-v2 > div {
    margin-bottom: 10px;
  }

  .modal-footer-button {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .checkout-button-v2 {
    padding: 10px 20px;
  }

  .cancel-button-v2 {
    padding: 10px 20px;
  }
}

@media screen and (max-width: 425px) {
  .main-section-conatiner {
    padding: 0px 6%;
  }
  .responsiveness-about-us {
    display: grid;
    gap: 20px;
  }

  .attendees-table-container-thead > tr > th {
    font-size: 10px;
  }

  .attendees-table .ant-table-thead > tr > th {
    font-size: 10px;
  }

  .attendees-label {
    font-size: var(--desktop-fz9);
  }

  .action-navigation {
    width: 64.127px;
    height: 44px;
  }

  .common-form-modal > .ant-modal-content {
    padding: 0px !important;
  }

  .main-content-container .ant-col-12 {
    max-width: 100%;
  }

  .main-content-container .ant-col {
    padding: 0px 0px !important;
  }

  .main-content-container .ant-row {
    display: grid !important;
    margin: 0px 0px !important;
  }

  .main-content-container .ant-col-4 {
    max-width: 100%;
  }

  .upload .ant-upload-select {
    border-radius: 9px !important;
  }

  .form-card {
    padding: 10px !important;
  }

  .upload-logo {
    font-size: var(--desktop-fz35);
  }

  .main-content-container .ant-col-20 {
    max-width: 100% !important;
  }

  .upload-button {
    padding: 0px 10px !important;
  }

  .modal-footer-button {
    display: grid;
    justify-content: center;
  }

  .main-content-container .ant-card-head {
    padding: 0px !important;
  }

  .close-icon-modal {
    font-size: var(--desktop-fz30) !important;
  }

  .common-input-container .ant-input::placeholder {
    font-size: var(--desktop-fz15) !important;
  }

  .form-input-datepicker .form-input-datepicker-input > input::placeholder,
  .form-input-datepicker-v2
    .form-input-datepicker-v2-input
    > input::placeholder {
    font-size: var(--desktop-fz16) !important;
  }

  .checkout-text {
    font-size: var(--desktop-fz20) !important;
  }

  .checkout-button {
    padding: 30px 10px;
  }
}

/* Load More Button */

.load-more-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  margin-bottom: 15px;
}

.load-more-button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  border: 1px solid rgb(138, 138, 138);
  padding: 25px 50px;
}

.load-more-button:hover {
  border-color: var(--black) !important;
}

.load-more-text {
  color: rgba(0, 0, 0, 0.833);
  font-size: var(--desktop-fz18);
  font-style: normal;
  font-weight: var(--desktop-fw600);
  line-height: 150%;
  /* 34.933px */
}

.padding-none {
  padding-top: 0px;
  padding-bottom: 0px;
}

.height-auto .ant-upload-select {
  height: auto;
  border: 1px solid #e7e7e730 !important;
  border-radius: 6px !important;
}

.height-auto {
  height: auto;
  display: flex;
  flex-direction: column;
}

.height-auto .ant-upload-select:hover {
  height: auto;
  border-radius: 6px !important;
}

/* /section layout  */
.genral-form {
  width: 100%;
  background: white;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
}

/* @media screen and (max-width: 768px) {
  .main-section-conatiner {
    padding: 30px 50px;
  }
}

@media screen and (max-width: 450px) {
  .main-section-conatiner {
    padding: 30px;
  }
} */

.pbss-hader-menu-horizontal > .pbss-hader-menu-submenu-open::after {
  border-bottom-color: var(--white) !important;
}

/* side bar scrll */
.menu-sidebar-component:hover {
  overflow-y: scroll;
  /* Show scrollbar on hover */
  visibility: visible;
  overflow-x: hidden;
}

.menu-sidebar-component {
  overflow-x: hidden;
  overflow-y: none;
}

/* Optional: Customize scrollbar appearance */
.menu-sidebar-component::-webkit-scrollbar {
  width: 6px;
}

.menu-sidebar-component::-webkit-scrollbar-thumb {
  background-color: rgba(131, 131, 131, 0.405);
  border-radius: 10px;
  scroll-behavior: smooth;
  /* visibility: hidden; */
}

.menu-sidebar-component::-webkit-scrollbar-thumb:hover {
  visibility: visible;
}

.menu-sidebar-component::-webkit-scrollbar-track {
  background-color: #868686;
  visibility: hidden;
}

.sidebar-menu-submenu-popup:hover {
  overflow-y: scroll;
  /* Show scrollbar on hover */
  visibility: visible;
  overflow-x: hidden;
}

.sidebar-menu-submenu-popup {
  overflow-x: hidden;
  overflow-y: none;
}

/* Optional: Customize scrollbar appearance */
.sidebar-menu-submenu-popup::-webkit-scrollbar {
  width: 6px;
}

.sidebar-menu-submenu-popup::-webkit-scrollbar-thumb {
  background-color: rgba(131, 131, 131, 0.405);
  border-radius: 10px;
  scroll-behavior: smooth;
  /* visibility: hidden; */
}

.sidebar-menu-submenu-popup::-webkit-scrollbar-thumb:hover {
  visibility: visible;
}

.sidebar-menu-submenu-popup::-webkit-scrollbar-track {
  background-color: #868686;
  visibility: hidden;
}

.sidebar-menu-mini-submenu-popup:hover {
  overflow-y: scroll;
  /* Show scrollbar on hover */
  visibility: visible;
  overflow-x: hidden;
}

.sidebar-menu-mini-submenu-popup {
  overflow-x: hidden;
  overflow-y: none;
}

/* Optional: Customize scrollbar appearance */
.sidebar-menu-mini-submenu-popup::-webkit-scrollbar {
  width: 6px;
}

.sidebar-menu-mini-submenu-popup::-webkit-scrollbar-thumb {
  background-color: rgba(131, 131, 131, 0.405);
  border-radius: 10px;
  scroll-behavior: smooth;
  /* visibility: hidden; */
}

.sidebar-menu-mini-submenu-popup::-webkit-scrollbar-thumb:hover {
  visibility: visible;
}

.sidebar-menu-mini-submenu-popup::-webkit-scrollbar-track {
  background-color: #868686;
  visibility: hidden;
}

.refund-container {
  display: flex;
  justify-content: end;
}

.common-cancel-button .ant-btn:hover {
  color: var(--PBSS--Primary) !important;
  border-color: var(--PBSS--Primary) !important;
}

.refund-approve {
  border-color: var(--success-500) !important;
  color: var(--success-500) !important;
  font-size: var(--desktop-fz17);
  padding: 17px 22px;
  display: flex;
  align-items: center;
}

.refund-reject {
  border-color: red !important;
  color: red !important;
  margin: 10px 10px;
  font-size: var(--desktop-fz17);
  padding: 18px 25px;
  display: flex;
  align-items: center;
}

.m-0 {
  margin: 0px !important;
}

.ant-radio-button {
  background-color: var(--PBSS--Primary);
  border: 1px solid var(--PBSS--Primary);
  box-shadow: none;
}

/* 
.ant-radio-group .ant-radio-group-solid,
.common-radio-group,
.css-dev-only-do-not-override-txh9fw {
  overflow: hidden;
} */

/* 
  For All 3 Types of Radio Buttons
  1. Radio.Button
  2. Radio
  3. Radio.Group with prop options
*/

.common-radio-group-group
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled),
.common-radio-group
  .ant-radio-button-wrapper-checked:not(
    .ant-radio-button-wrapper-disabled
  ):hover,
.common-radio-group-group
  .common-radio-group-button-wrapper-checked:not(
    .common-radio-group-button-wrapper-disabled
  ),
.common-radio-group-group
  .common-radio-group-button-wrapper-checked:not(
    .common-radio-group-button-wrapper-disabled
  ):hover {
  background-color: var(--PBSS--Primary);
  border-color: var(--PBSS--Primary);
  color: var(--White, #fff);
}

.common-radio-group-group
  .ant-radio-button-wrapper:not(.ant-radio-button-wrapper-checked):hover,
.common-radio-group-group
  .common-radio-group-button-wrapper:not(
    .common-radio-group-button-wrapper-checked
  ):hover {
  color: var(--PBSS--Primary);
}

.pbssMainContainerRow {
  /* margin: 50px 0px !important; */
  /* margin-top: 32px !important; */
  margin-bottom: 32px !important;
}

.pbssMainContainerRowChapter {
  margin: 32px 0px !important;
  margin-top: 0px !important;
}

.pbssMainContainerRowEventDetails {
  margin: 32px 0px !important;
}

.pbssMainContainerRowJobsListingPage {
  margin-top: 0px !important;
  margin-bottom: 80px !important;
}

.common-button-popover {
  height: auto !important;
  padding: 0px !important;
  font-size: var(--desktop-fz12);
}

.upload-list-name-fix-class-admin-list-item-name {
  max-width: 350px;
  color: var(--PBSS--Primary);
}

@media screen and (max-width: 1440px) {
  .upload-list-name-fix-class-admin-list-item-name {
    max-width: 250px;
  }
}

@media screen and (max-width: 1024px) {
  .upload-list-name-fix-class-admin-list-item-name {
    max-width: 150px;
  }
}

@media screen and (max-width: 768px) {
  .upload-list-name-fix-class-admin-list-item-name {
    max-width: 40vw;
  }

  .couponCodeLabelAndTextMessageContainer {
    flex-direction: column;
    gap: 0;
  }
}

@media screen and (max-width: 480px) {
  .upload-list-name-fix-class-admin-list-item {
    position: relative;
    height: auto;
    margin-top: 6px;
    font-size: 14px;
    display: flex;
    align-items: center;
    transition: background-color 0.3s;
  }
}

.pd-y-20 {
  padding-top: 20px;
  padding-bottom: 20px;
}

@media screen and (max-width: 550px) {
  .contact-us-button {
    width: 100% !important;
    font-size: var(--desktop-fz16);
  }

  .ms-auto-mobile {
    margin: 0px !important;
  }

  .pbssMainContainerRow,
  .pbssMainContainerRowChapter,
  .pbssMainContainerRowPastEvent,
  .pbssMainContainerRowEventDetails,
  .pbssMainContainerRowEventListing,
  .pbssMainContainerRowJobsListingPage {
    grid-gap: 20px !important;
  }

  /* .pbssMainContainerRowChapter,
  .pbssMainContainerRowEventDetails {
    margin-top: 15px !important;
  } */
  .pbssMainContainerRowEventListing {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .pbssMainContainerRow {
    margin-top: 0px !important;
    margin-bottom: 32px !important;
  }

  .attendees-content-container {
    display: grid;
    gap: 13px;
    margin-bottom: 13px;
  }

  .attendees-description-alert-message {
    font-size: var(--desktop-fz11);
  }
}

@media screen and (max-width: 376px) {
  .customized-modal-v2-content {
    padding: 10px !important;
    padding-top: 20px !important;
  }

  .payment-text-v2 {
    width: 100%;
    display: flex;
    text-align: center;
    line-height: normal;
  }

  .modal-heading-v2 {
    font-size: var(--mobile-fz20);
  }
}

@media screen and (max-width: 320px) {
  .modal-heading-v2 {
    font-size: var(--mobile-fz16);
  }

  .modal-container-v2 {
    margin-bottom: 20px;
  }
}

.ant-image-preview-close {
  position: fixed;
  top: 50px;
  right: 80px !important;
  display: flex;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.623);
  border-radius: 50%;
  padding: 12px;
  outline: 0;
  top: 80px;
  /* left: 50%; */
  transform: translateX(-50%);
  border: 0;
  cursor: pointer;
  transition: all 0.3s;
}

.eventTypesStaticPageCard {
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-bottom: 20px;
}

.eventTypesStaticPageCard p {
  font-size: var(--desktop-fz18);
}

@media screen and (max-width: 475px) {
  .eventTypesStaticPageCard h1 {
    font-size: var(--desktop-fz20);
  }

  .eventTypesStaticPageCard p {
    font-size: var(--desktop-fz16);
  }
}

/* Main user layout that contains nav,content and footer */
.layout {
  min-height: 100vh;
}

.common-badge-ribbon {
  font-size: 16px !important;
}

.couponCodeLabelAndTextMessageContainer {
  display: flex;
  gap: 10px;
}

@media (max-width: 428px) {
  .attendees-table-container-wrapper input::placeholder {
    font-size: 10px;
  }
  .attendees-table-container-wrapper input {
    font-size: 10px;
  }
  .regularAttendeesTag {
    font-size: 10px;
  }
  .regularAttendees-price {
    font-size: 10px;
  }
}
