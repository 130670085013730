/* Event Card Styles */
.event-main-container {
  background: #fafbff;
  border: 1px solid #e8f0ff;
}

.date > svg {
  width: 24px !important;
  height: 16px !important;
}

.event-sub-container {
  padding: 30px 38px;
}

.event-sub-container .date {
  height: fit-content;
  display: flex;
  border-radius: 3.721px;
  border: 1.181px solid #ddd;
  padding: 4.5px 6px;
  align-items: center;
  gap: 15.364px;
  flex-shrink: 0;
  background: rgba(233, 233, 233, 0.5);
}
.event-sub-container .date > p {
  color: var(--Black, #000);
  font-size: var(--desktop-fz18);
  font-style: normal;
  font-weight: var(--desktop-fw700);
  line-height: 162%;
}

.event-sub-container .date > label {
  color: var(--pbss-p1);
  display: flex;
  align-items: center;
  font-size: var(--desktop-fz18);
  font-style: normal;
  font-weight: var(--desktop-fw700);
  line-height: 162%;
}
.event-sub-container .type {
  padding: 6px;
  height: fit-content;
  color: var(--pbss-p1);
  font-size: var(--desktop-fz18);
  font-style: normal;
  font-weight: var(--desktop-fw600);
  line-height: 162%;
  border-radius: 3.154px;
  background: #eff4ff;
}
.event-sub-container .button > button {
  float: right;
  color: var(--White, #fff);
  padding: 10px 25px;
  justify-content: center;
  align-items: center;
  gap: 14.104px;
  border-radius: 5px;
  border: none;
  background: var(--pbss-p1);
  font-size: var(--desktop-fz16);
  font-style: normal;
  font-weight: var(--desktop-fw700);
  line-height: 150%;
  cursor: pointer;
}
.event-sub-container .button > button:hover {
  background-color: #1a3b7a;
}
.event-name-text > p {
  color: #161515;
  font-size: var(--desktop-fz22);
  width: 80%;
  font-style: normal;
  font-weight: var(--desktop-fw700);
  line-height: 32px;
  margin-top: 20px !important;
}

.details-li {
  display: flex;
  flex: 1;
}
.event-details-container-card {
  padding: 2% 0;
  display: flex;
  padding-bottom: 0px;
  flex-direction: column;
  gap: 15px;
}
.details-li > p {
  flex: 0.2 1;
  color: var(--Black, #000);
  font-size: var(--desktop-fz18);
  font-style: normal;
  font-weight: var(--desktop-fw600);
  line-height: 100%;
}

.details-li > label {
  flex: 0.83;
  color: var(--Black, #000);
  font-size: var(--desktop-fz18);
  font-style: normal;
  font-weight: var(--desktop-fw400);
  line-height: 120%;
}

.event-top-line {
  display: flex;
  justify-content: space-between;
}

.event-top-line > span {
  column-gap: 18px;
  display: flex;
  justify-content: space-between;
}

.event-section1 {
  line-height: 50px;
  width: 70%;
}

.event-section1 > p {
  color: var(--Black, #000);
  font-size: var(--desktop-fz21);
  font-style: normal;
  font-weight: var(--desktop-fw600);
}

.event-section2 {
  /* Add styles for the second section of your event card */
}

.description {
  /* Add styles for the description text */
}

.badge-container {
  display: grid;
  width: 70%;
  grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
  gap: 15px;
}

.group1-badge {
  display: flex;
  flex-direction: column;
  justify-content: end;
  gap: 10px;
}

.group1-badge > label {
  color: #191a1a;
  font-size: var(--desktop-fz12);
  font-style: normal;
  font-weight: var(--desktop-fw700);
  line-height: normal;
}

.date-badge {
  border-radius: 18.115px;
  background: #000;
  display: inline-flex;
  padding: 5.328px 10.656px !important;
  justify-content: center;
  align-items: center;
}

.common-text-white {
  color: #fff;
  text-align: center;
  font-size: 12.787px;
  font-style: normal;
  font-weight: var(--desktop-fw700);
  line-height: normal;
}

.location-badge {
  border-radius: 5.742px;
  background: #828282 !important;
}

.common-badge {
  border-radius: 4.845px;
  background: var(--Gray-4, #bdbdbd);
  display: flex;
  width: 77px;
  height: 27px;
  padding: 2.422px 9.689px !important;
  justify-content: center;
  align-items: center;
  gap: 12.112px;
  flex-shrink: 0;
  width: auto;
}

.event-dis-container {
  display: flex;
  margin-top: 40px;
}

.event-dis-container > p {
  margin: 0 !important;
  padding: 0 !important;
  color: #111010;
  font-size: var(--desktop-fz18);
  font-style: normal;
  font-weight: var(--desktop-fw450);
  line-height: 162%;
}

.event-dis-title {
  color: #111010;
  font-size: var(--desktop-fz18);
  font-style: normal;
  font-weight: var(--desktop-fw700);
  line-height: 162%;
}

/* Add styles for other elements as needed */
.event-section2 {
  gap: 15px;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  flex-direction: column;
}
/* Button Styles */
.event-section2 > button {
  border-radius: 6px;
  background: #000;
  display: inline-flex;
  padding: 8px 20px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  color: var(--White, var(--White, #fff));
  font-size: var(--desktop-fz16);
  font-style: normal;
  font-weight: var(--desktop-fw700);
  line-height: 150%;
  border: none;
  cursor: pointer;
}

.event-section2 > p {
  color: #505050;
  font-size: 22.004px;
  font-style: normal;
  font-weight: var(--desktop-fw600);
  line-height: 162%;
  /* 38.887px */
}

/* Price Badge Styles */
.price-badge {
  border-radius: 14.768px;
  border: 1.207px solid #000;
  background: #fff;
  display: flex;
  width: 125px;
  height: 27px;
  padding: 4.344px 8.687px;
  justify-content: center;
  align-items: center;
  gap: 5.212px;
  flex-shrink: 0;
}

.side-border {
  border-left: 2px solid #b7b7b7;
  width: 100%;
  display: flex;
  justify-content: center;
}

.more-details {
  margin-top: 20px;
}

.mb-button {
  display: block;
}
.mb-button > button {
  visibility: hidden;
}

@media screen and (max-width: 900px) {
  .event-main-container {
    flex-direction: column;
  }
  .event-section1 {
    width: auto;
  }
  .event-section2 {
    display: grid;
    margin: 20px 0px;
  }
}

@media screen and (min-width: 1300px) {
  .mb-detail-list {
    display: flex;
    flex-direction: column;
    gap: 20px !important;
  }
}

@media screen and (max-width: 1500px) {
  .badge-container {
    width: 100%;
  }
}

@media screen and (max-width: 480px) {
  /* .location-li-extend {
    display: flex;
    align-items: end;
  } */
  .mb-detail-list {
    display: flex;
    justify-content: space-between;
  }
  /* .mb-location-text {
    margin-top: 1rem;
    flex: none !important;
    border-radius: var(--Count, 0px);
    background: var(--pbss-p1);
    height: fit-content;
    display: flex;
    width: fit-content;
    padding: 3.8px 4.999px 3.962px 4.994px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    color: white !important;
    font-size: 9.91px !important;
    font-style: normal;
    font-weight: var(--desktop-fw700);
  } */
  .mb-button {
    display: flex;
  }
  .mb-button > button {
    background: var(--White);
    visibility: visible;
    display: flex;
    width: 100%;
    padding: 10.8px 27px;
    justify-content: center;
    align-items: center;
    gap: 10.8px;
    border-radius: 8.1px;
    color: #2f57a4;
    font-size: 16.6px !important;
    font-style: normal;
    font-weight: var(--desktop-fw600);
    line-height: 150%;
    border: 1px solid #2f57a4;
    margin-bottom: 20px;
  }
  /* .mb-price-text {
    margin: 20px 0;
    color: var(--Black, #000) !important;
    font-size: var(--desktop-fz16) !important;
    font-style: normal;
    font-weight: var(--desktop-fw700) !important;
    line-height: 127%;
  } */
  .event-sub-container {
    padding: 14px 12px !important;
  }

  .event-sub-container .date {
    height: fit-content;
    display: flex;
    border-radius: 3.721px;
    border: 1.181px solid #ddd;
    padding: var(--Count, 0px) 5.906px var(--Count, 0px) 4.181px;
    align-items: center;
    gap: 8.364px !important;
    flex-shrink: 0;
    background: rgba(233, 233, 233, 0.5);
  }
  .event-sub-container .date > p {
    font-size: 10.707px !important;
    line-height: 1.935px;
  }

  .event-sub-container .date > label > svg {
    height: 14px;
  }

  .event-sub-container .date > label {
    display: flex;
    align-items: flex-start !important;
    font-size: 9.843px !important;
    line-height: 162%;
  }
  .event-sub-container .type {
    font-size: var(--desktop-fz10) !important;
    width: fit-content;
    font-weight: var(--desktop-fw500);
    line-height: 162%;
  }
  .event-sub-container .button {
    visibility: hidden;
    width: 0px;
    height: 0px;
    display: block;
  }
  .event-sub-container .button > button {
    display: block;
  }

  .event-name-text > p {
    font-size: var(--desktop-fz14) !important;
    width: 100% !important;
    font-weight: var(--desktop-fw600);
    line-height: 16px;
    margin-top: 0.6rem !important;
  }

  .details-li {
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: 10px;
    margin-bottom: 10px;
  }
  .event-details-container-card {
    padding: 20px 0 !important;
    padding-bottom: 0px !important;
    display: flex;
    flex-direction: column;
    gap: 15px !important;
  }
  .details-li > p {
    flex: 0.154;
    font-size: 12.71px !important;
    font-weight: var(--desktop-fw700);
    line-height: 1.935px;
  }
  /* .details-li .mb-price-text {
    font-size: var(--desktop-fz16) !important;
    font-style: normal;
    font-weight: var(--desktop-fw700) !important;
  } */

  .details-li > label {
    flex: 0.846;
    color: #6c6c6c;
    font-size: var(--desktop-fz12) !important;
    font-weight: var(--desktop-fw500);
    line-height: 20px;
  }

  .event-top-line {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 0px !important;
  }

  .event-top-line > span {
    gap: 10px !important;
    flex-direction: column;
    margin-bottom: 0rem !important;
  }
}
@media screen and (max-width: 1024px) {
  .details-li > label {
    flex: 0.846;
    color: #6c6c6c;
    font-size: var(--desktop-fz18);
    font-weight: var(--desktop-fw500);
  }
}
@media screen and (max-width: 768px) {
  .details-li > p {
    /* flex: 0.154; */
    font-size: 18.71px;
    font-weight: var(--desktop-fw700);
    line-height: 1.935px;
  }

  /* .details-li > label {
    flex: 0.846;
    color: #6c6c6c;
    font-size: var(--desktop-fz18);
    font-weight: var(--desktop-fw500);
    line-height: 30px;
    margin-top: 0px;
  } */
  /* .location-li-extend {
    display: flex;
    align-items: end;
  } */
  .mb-detail-list {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
  }
  /* .mb-location-text {
    margin-top: 1rem;
    flex: none !important;
    border-radius: var(--Count, 0px);
    background: var(--pbss-p1);
    height: fit-content;
    display: flex;
    width: fit-content;
    padding: 3.8px 4.999px 3.962px 4.994px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    color: white !important;
    font-size: 12.91px;
    font-style: normal;
    font-weight: var(--desktop-fw700);
  } */
  .mb-button {
    display: flex;
  }
  .mb-button > button {
    background: var(--White);
    visibility: visible;
    display: flex;
    width: 100%;
    padding: 10.8px 27px;
    justify-content: center;
    align-items: center;
    gap: 10.8px;
    border-radius: 8.1px;
    color: #2f57a4;
    font-size: 16.6px;
    font-style: normal;
    font-weight: var(--desktop-fw600);
    line-height: 150%;
    border: 1px solid #2f57a4;
    margin-bottom: 20px;
  }
  /* .mb-price-text {
    margin: 20px 0;
    color: var(--Black, #000) !important;
    font-size: var(--desktop-fz22) !important;
    font-style: normal;
    font-weight: var(--desktop-fw700) !important;
    line-height: 127%;
  } */
  .event-sub-container {
    padding: 20px;
  }

  .event-sub-container .date {
    height: fit-content;
    display: flex;
    border-radius: 3.721px;
    border: 1.181px solid #ddd;
    padding: var(--Count, 0px) 5.906px var(--Count, 0px) 4.181px;
    align-items: center;
    gap: 15.364px;
    flex-shrink: 0;
    background: rgba(233, 233, 233, 0.5);
  }
  .event-sub-container .date > p {
    font-size: 16.707px;
    line-height: 1.935px;
  }

  .event-sub-container .date > label {
    display: flex;
    padding: 5px;
    font-size: 15.843px;
    line-height: 162%;
  }
  .event-sub-container .type {
    font-size: var(--desktop-fz16);
    width: fit-content;
    font-weight: var(--desktop-fw500);
    line-height: 162%;
  }
  .event-sub-container .button {
    visibility: hidden;
    width: 0px;
    height: 0px;
    display: block;
  }
  .event-sub-container .button > button {
    display: block;
  }

  .event-name-text > p {
    font-size: var(--desktop-fz20);
    width: 100% !important;
    font-weight: var(--desktop-fw600);
    line-height: 26px;
  }

  .details-li {
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: 10px;
  }
  .event-details-container-card {
    padding: 37px 0;
    padding-bottom: 28px;
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  .event-top-line {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }

  .event-top-line > span {
    gap: 13px;
    margin-bottom: 1rem;
  }
}

@media screen and (min-width: 768px) and (max-width: 1300px) {
  /* .location-li-extend {
    display: flex;
    align-items: end;
  } */

  .event-sub-container .type {
    font-size: var(--desktop-fz16);
    width: 90px;
    font-weight: var(--desktop-fw500);
    line-height: 162%;
  }

  /* .mb-price-text {
    margin: 20px 0;
    color: var(--Black, #000) !important;
    font-size: var(--desktop-fz28) !important;
    font-style: normal;
    font-weight: var(--desktop-fw700) !important;
    line-height: 127%;
  } */
  /* .details-li {
    flex-direction: column;
  } */
  .mb-detail-list {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
  }
  /* .mb-location-text {
    margin-top: 1rem;
    flex: none !important;
    border-radius: var(--Count, 0px);
    background: var(--pbss-p1);
    height: fit-content;
    display: flex;
    width: fit-content;
    padding: 3.8px 4.999px 3.962px 4.994px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    color: white !important;
    font-size: 12.91px;
    font-style: normal;
    font-weight: var(--desktop-fw700);
  } */
  .event-name-text > p {
    width: 100%;
  }
  .event-top-line > span {
    display: flex;
    row-gap: 18px;
    flex-direction: column;
  }
}
/* @media screen and (min-width: 320px) {
  .event-sub-container .date > label {
    padding: 5px 4px !important;
  }
} */
