.specialPaymentConfimAndPayContainer {
  display: flex;
  justify-content: space-between;
  padding: 1rem 3rem;
  align-items: center;
  border-radius: 8px;
  /* border: 1px solid gray; */
  /* background-color: whitesmoke; */
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.11);
}

.payment-checkout-form {
  margin: 20px 0px;
  margin-top: 10px;
}
.payment-form-heading {
  margin: 20px 0px;
  margin-top: 10px;
}

.specialPaymentConfimAndPayContainer span {
  font-size: var(--desktop-fz22);
  font-weight: var(--desktop-fw500);
}

.specialPaymentCheckoutFormContainer {
  padding: 10px 0px;
}

.confirmation-action-button-specialPayment {
  border: none;
}

@media screen and (max-width: 480px) {
  .specialPaymentConfimAndPayContainer {
    flex-direction: column;
    padding: 1rem 2rem;
    gap: 10px;
  }
  .specialPaymentConfimAndPayContainer > button {
    width: 100%;
  }
}
