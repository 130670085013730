.cardForListing {
  cursor: pointer;
  background-color: white;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 22px;
  border-bottom: 0.5px solid #bce;
  background: #fff;
}
.cardForListing:hover {
  background-color: #2f58a413;
  cursor: pointer;
  box-shadow: 1px 1px 10px #2f58a435;
  transition: all 0.5ms ease-in;
}

.past-event-card-title {
  flex: 7;
  color: #000;
  font-size: var(--desktop-fz16);
  font-weight: var(--desktop-fw400);
}

/* .past-event-card-date {
  flex: 1;
} */
.row2 {
  flex: 5 1;
  display: flex;
  align-items: center;
}

.row3 {
  flex: 2;
  display: flex;
  text-align: center;
}

.past-event-card-date > span {
  border-radius: 0.2239rem;
  border: 0.711px solid #ddd;
  background: rgba(233, 233, 233, 0.5);
  color: #000;
  font-size: var(--desktop-fz14);
  font-weight: var(--desktop-fw700);
  padding: 5px 10px;
}

.past-event-card-date {
  flex: 1;
  display: flex;
  justify-content: center;
}
.past-event-card-extraDetails {
  flex: 1;
  max-width: 20%;
  background-color: #eff4ff;
}

.past-event-card-extraDetails > span {
  align-items: center;
  justify-content: center;
  border-radius: 0.3rem;
  font-size: var(--desktop-fz14);
  font-weight: var(--desktop-fw600);
  color: #2f57a4;
  white-space: break-spaces;
  padding: 5px 10px;
  text-align: center;
  display: flex;
}

.past-event-card-location {
  flex: 1;
  display: flex;
  justify-content: center;
}

.past-event-card-arrow {
  flex: 1;
  display: flex;
  justify-content: center;
  cursor: pointer;
  transition: margin-left 0.3s ease-in-out; /* Added transition for smoother animation */
}

.past-event-card-arrow:hover {
  margin-left: 10px;
}

.past-event-card-location > span {
  font-size: var(--desktop-fz14);
  font-weight: var(--desktop-fw700);
  /* max-width: 100%; */
  /* height: max-content; */
  text-wrap: wrap;
  padding: 2px 3px;
  background-color: #2f57a4;
  color: #fff;
  /* padding: 5px 10px; */
}

@media screen and (max-width: 1440px) {
  .past-event-card-location {
    flex: 1;
  }
  .past-event-card-arrow {
    flex: 1;
  }
  .past-event-card-date > span {
    padding: 5px 5px;
    text-wrap: nowrap;
  }
}

@media screen and (max-width: 1024px) {
  .cardForListing {
    padding: 16px 16px;
  }
  .past-event-card-title {
    font-size: var(--laptop-fz18);
    text-wrap: wrap;
    flex: 5;
  }
  .past-event-card-date > span {
    font-size: var(--laptop-fz14);
    padding: 3px 3px;
  }
  .past-event-card-extraDetails {
    max-width: 30%;
  }
  .past-event-card-extra-details > span {
    font-size: var(--laptop-fz14);
  }

  .past-event-card-location {
    flex: 1;
  }
  .past-event-card-location > span {
    font-size: var(--laptop-fz14);
    height: max-content;
    text-wrap: wrap;
    padding: 2px 3px;
  }
  .past-event-card-arrow {
    flex: 1;
  }

  .past-event-card-container {
    padding: 10px 16px;
  }
  .row2 {
    flex: 5;
    gap: 10px;
  }
  .row3 {
    flex: 3;
  }
}

@media screen and (max-width: 650px) {
  .cardForListing {
    flex-direction: column;
    height: max-content;
    padding: 11px 15px;
    border: 0.5px solid #bce;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.09),
      0px 0px 4px 0px rgba(0, 0, 0, 0.04);
  }

  .past-event-card-title {
    margin-bottom: 20px;
    display: flex;
    justify-content: flex-start;
    width: 100%;
  }
  .row2 {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;
  }
  .row3 {
    align-items: center;
  }
  .past-event-card-date {
    justify-content: end;
  }
  .past-event-card-date > span {
    padding: 2px 3px;
    margin: 0;
  }
  .past-event-card-extraDetails {
    order: 2;
  }
  .past-event-card-date {
    order: 3;
  }
  .past-event-card-location {
    order: 1;
  }
  .past-event-card-extraDetails > span {
    padding: 5px 5px;
    margin: 0;
  }
  .row3 {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .past-event-card-location > span {
    padding: 3px 4px;
  }
}

@media screen and (max-width: 480px) {
  .cardForListing {
    flex-direction: column;
    height: max-content;
    padding: 11px 15px;
  }
  .past-event-card-title {
    font-size: var(--mobile-fz12);
    font-weight: var(--mobile-fw700);
    margin-bottom: 20px;
    display: flex;
    justify-content: flex-start;
    width: 100%;
  }
  .row2 {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;
  }
  .row3 {
    align-items: center;
  }
  .past-event-card-date {
    justify-content: end;
  }
  .past-event-card-date > span {
    font-size: var(--mobile-fz12);
    font-weight: var(--mobile-fw700);
    padding: 2px 3px;
    margin: 0;
  }

  .past-event-card-extraDetails {
    order: 1;
  }
  .past-event-card-date {
    order: 2;
  }
  .past-event-card-extraDetails > span {
    font-size: var(--mobile-fz10);
    font-weight: var(--mobile-fw700);
    padding: 5px 5px;
    margin: 0;
  }
  .row3 {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .past-event-card-location > span {
    font-size: var(--mobile-fz10);
    font-weight: var(--mobile-fw700);
    padding: 3px 4px;
  }
  .past-event-card-arrow > svg {
    width: 20px;
    height: 12px;
  }
}
