/* commonInfoCard.css */

.commonInfo-container {
  display: flex;
  flex-direction: column;
  border-radius: var(--Count, 0px);
  margin-bottom: 15px;
  gap: 20px;
}

.card-heading {
  color: var(--pbss-p1, #005d61);
  font-size: var(--desktop-fz32);
  font-style: normal;
  font-weight: var(--desktop-fw500);
  margin-bottom: 1.2em !important;
  font-weight: var(--desktop-fw500);
  line-height: 80px;
}

.commonInfo-image {
  object-fit: cover;
  height: 381px;
}

.commonInfo-details {
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.commonInfo-description {
  color: #505050;
  font-size: var(--desktop-fz22);
  width: 70%;
  font-style: normal;
  font-weight: var(--desktop-fw500);
  line-height: 162%;
  /* 40.5px */
}

.commonInfo-price {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.commonInfo-price p {
  color: var(--Black, #000);
  font-size: 20.319px;
  font-style: normal;
  font-weight: var(--desktop-fw450);
  line-height: normal;
}

.commonInfo-price span {
  color: var(--Black, #000);
  font-size: 35.184px;
  font-style: normal;
  font-weight: var(--desktop-fw500);
  line-height: normal;
}

.info-body {
  padding: 0px 40px;
  padding-bottom: 30px;
  line-height: 50px;
}

.info-body p {
  color: var(--pbss-p1);
  font-size: var(--desktop-fz22);
  font-style: normal;
  font-weight: var(--desktop-fw500);
  line-height: 150%;
  /* 42px */
}

.info-body span {
  color: var(--Black);
  font-size: var(--desktop-fz18);
  font-style: normal;
  font-weight: var(--desktop-fw500);
  line-height: 150% !important;
  /* 35.64px */
}

.info-body > li {
  color: #282828;
  font-size: var(--desktop-fz16);
  font-style: normal;
  font-weight: var(--desktop-fw400);
  line-height: 160%;
  /* 38.88px */
}

.commonInfo-details > button {
  display: flex;
  padding: 10.272px 25.681px;
  justify-content: center;
  align-items: center;
  gap: 10.272px;
  height: fit-content;
  flex-shrink: 0;
  border-radius: 7.704px;
  background: var(--Gray-1, #333);
  color: var(--White, var(--White, #fff));
  font-size: var(--desktop-fz16);
  border: none;
  font-style: normal;
  font-weight: var(--desktop-fw450);
  line-height: 150%;
  cursor: pointer;
  /* 34.933px */
}

/* Add more styles as needed */

@media screen and (max-width: 500px) {
  .info-body p {
    font-size: var(--desktop-fz18);
  }
  .card-heading {
    font-size: var(--desktop-fz20);
    line-height: 75%;
    margin-bottom: 25px !important;
  }
  .commonInfo-details {
    flex-direction: column;
    gap: 10px;
    align-items: start;
  }
  .commonInfo-details > button {
    width: 100%;
    margin-bottom: 20px;
  }
  .commonInfo-description {
    width: 100%;
  }
  .commonInfo-price {
    align-items: start;
  }

  .info-body {
    display: flex;
    flex-direction: column;
    gap: 15px;
    line-height: normal;
  }
}
