.input-container {
  display: flex;
  justify-content: center;
}
.main-form-container-update-profile {
  display: flex;
  justify-content: center;
  width: 100%;
}
.two-column-form-update-profile {
  width: 60%;
  margin: 80px 0px;
}
.form-column {
  flex: 1;
  width: 100%;
}
.keyword-container-admin {
  background-color: white !important;
  border: 1px solid #4b4b4b !important;
  border-radius: 6px;
  background: #ebf2ff;
  width: 100%;
  padding-left: 26px;
  padding-right: 26px;
  padding-bottom: 26px;
}
.keyword-other-render-admin {
  background-color: white !important;
  border: 1px solid #4b4b4b !important;
  width: 100%;
  border-radius: 6px;
  background: #ebf2ff;
  margin-top: 40px;
  padding: 23px 20px;
}
.admin {
  border: 1px solid #4b4b4b !important;
}
.keyword-required {
  color: red;
}
.update-profile-common-input:focus {
  border-color: var(--pbss-p1) !important;
}
.update-profile-common-input:hover {
  border-color: var(--pbss-p1) !important;
}
.update-profile-common-input::-moz-placeholder {
  color: var(--Black, #000);
  font-size: 17.647px;
  font-style: normal;
  font-weight: var(--desktop-fw450);
  line-height: 150%;
  /* 26.471px */
}
.update-profile-common-input-affix-wrapper:focus {
  border-color: var(--pbss-p1) !important;
}
.update-profile-common-input-affix-wrapper:hover {
  border-color: var(--pbss-p1) !important;
}
.update-profile-common-input-affix-wrapper-focused {
  border-color: var(--pbss-p1) !important;
}
.update-profile-common-input-affix-wrapper-status-error:hover {
  border-color: #ff4d4f !important;
}
.update-profile-common-input-affix-wrapper-status-error:focus {
  border-color: #ff4d4f !important;
}
.update-profile-common-input-affix-wrapper {
  padding: 0px 22px 0px 11px !important;
}
.update-profile-common-input {
  border-radius: 6px;
  border: 0.882px solid #d6d6d6;
  height: 52px;
  display: flex;
  flex-shrink: 0;
}
.modal-updateProfile-container {
  padding-top: 15px;
}
.modal-updateProfile-footer {
  justify-content: center;
  display: flex;
  padding-top: 15px;
  gap: 15px;
}
.modal-verifyButton-footer {
  background-color: #2e56a4;
}
.modal-cancelButton-footer:hover {
  border-color: #2e56a4 !important;
  color: #2e56a4 !important;
}

.modal-verifyButton-footer:hover {
  background-color: #2e56a4 !important;
}
@media screen and (max-width: 1024px) {
  .two-column-form-update-profile {
    width: 90%;
    padding: 0px 60px;
  }
}
@media screen and (max-width: 425px) {
  .two-column-form-update-profile {
    width: 100%;
    padding: 0px 50px;
  }
  .input-container {
    display: grid !important;
    grid-template-columns: 1fr;
  }
}
