.hero-text-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6px 8%;
  padding-bottom: 10px;
  color: var(--white);
  background: #003885 !important;
}
.text-container {
  display: flex;
  flex-direction: column;
}
.hero-link {
  text-decoration: underline;
  font-size: var(--desktop-fz18);
  color: #e26d42 !important;
  font-weight: bold;
}

.heading {
  font-size: var(--desktop-fz20);
  font-weight: var(--desktop-fw500);
  color: white;
}

.subheading {
  font-size: var(--desktop-fz18);
  font-weight: var(--desktop-fw600);
  color: white;
}

@media screen and (max-width: 1440px) {
  .heading {
    font-size: var(--desktop-fz20);
  }
  .subheading {
    font-size: var(--desktop-fz16);
  }
}

@media screen and (max-width: 1024px) {
  .heading {
    font-size: var(--desktop-fz20);
  }
  .subheading {
    font-size: var(--desktop-fz16);
  }
}

@media screen and (max-width: 768px) {
  .heading {
    font-size: var(--desktop-fz18);
  }
  .subheading {
    font-size: var(--desktop-fz16);
  }
}

@media screen and (max-width: 480px) {
  .hero-text-container {
    height: auto;
    padding: 10px 8%;
    gap: 10px;
    display: flex;
    flex-direction: column;
    align-items: start;
    background-color: transparent;
  }
  .heading {
    font-size: var(--mobile-fz16);
    font-weight: var(--mobile-fw500);
    margin-right: 5px;
  }
  .subheading {
    font-size: var(--mobile-fz14);
    font-weight: var(--mobile-fw500);
  }
}
