/* .register-form {
  width: 100%;
  padding: 30px 130px;
} */
.updatePassword-form-input-container > .ant-input:focus {
  box-shadow: none !important;
}

.updatePassword-form-input {
  width: 100%;
  height: 100%;
  padding: 10px 10px;
}
.updatePassword-form-input-container {
  width: 100%;
}
.updatePassword-form-input-container > .ant-input::placeholder {
  color: #000;
  font-size: var(--desktop-fz12) !important;
  font-style: normal;
  font-weight: var(--desktop-fw400);
  line-height: 36px;
  padding: 0px 5px;
}
.login-form-button {
  width: 100%;
  font-size: var(--desktop-fz16) !important;
}
.updatePassword-form-input-container > .ant-input:hover {
  border-color: #d9d9d9 !important ;
}
.updatePassword-form-input-container > .ant-input:focus {
  border-color: #d9d9d9 !important ;
  box-shadow: none !important;
}
.updatePassword-form-input-container
  > .ant-input-affix-wrapper
  > .ant-input::placeholder {
  color: gray;
  font-size: var(--desktop-fz18);
  font-weight: var(--desktop-fw400);
}
.updatePassword-form-input-container > .ant-input-affix-wrapper:hover {
  border-color: #d9d9d9 !important ;
}
.updatePassword-form-input-container > .ant-input-affix-wrapper:focus {
  border-color: #d9d9d9 !important ;
  box-shadow: none !important;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.updatePassword-form-input-container > .ant-input {
  color: #000;
  font-size: var(--desktop-fz20);
  font-weight: var(--desktop-fw400);
  line-height: 0;
}

@media screen and (max-width: 768px) {
  .register-form {
    padding: 0px 0px;
  }
  .updatePassword-form-input::placeholder {
    color: #000;
    font-size: var(--desktop-fz16) !important;
    font-style: normal;
    font-weight: 450 !important;
    line-height: 36px !important;
    padding: 0px 5px !important;
  }
  .updatePassword-form-input-container > .ant-input {
    color: #000;
    font-size: var(--desktop-fz14);
    font-weight: var(--desktop-fw400);
  }
  .updatePassword-form-input-container > .ant-input-affix-wrapper {
    color: #000;
    font-size: var(--desktop-fz14);
    font-weight: var(--desktop-fw400);
  }

  .updatePassword-form-input::placeholder {
    font-size: var(--desktop-fz15) !important;
  }
}
@media screen and (max-width: 425px) {
  .updatePassword-form-input::placeholder {
    font-size: var(--desktop-fz14) !important;
  }
}
