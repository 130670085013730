/* @import '../styles/global.css'; */

.initial-container {
  display: flex;
  justify-content: space-between;
  padding: 48px 8%;
  background: #fbfbfb;
  /* border-top: 1px solid #dcdfe1; */
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 0px;
  padding-bottom: 50px;
}

.links-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.icon-container-footer > li > a {
  text-decoration: none;
  color: var(--black);
  /* font-family: Circular Std; */
  font-size: var(--desktop-fz14);
  font-style: normal;
  font-weight: var(--desktop-fw450);
  line-height: 150%;
  /* 21px */
}
.icon-container-footer > li {
  display: flex;
}

.brand-info {
  color: #919191;
  /* font-family: Circular Std; */
  font-size: var(--desktop-fz14);
  font-style: normal;
  font-weight: var(--desktop-fw450);
  width: 80%;
  line-height: 150%;
  /* 21px */
}

.subscribe-form {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
}

.subscribe-form > input {
  border-radius: 6px;
  border: 1px solid var(--pbss-p1);
  padding: 12px;
  background: var(--white, #fff);
}
.subscribe-form > input::placeholder {
  color: var(--pbss-p1);
  font-size: var(--desktop-fz15);
  font-style: normal;
  font-weight: var(--desktop-fw500);
  line-height: 150%;
}

.subscribe-btn {
  border-radius: 8px;
  border: 1px solid #fff;
  background: var(--pbss-p1);
  display: flex;
  font-size: var(--desktop-fz16);
  font-style: normal;
  font-weight: var(--desktop-fw500);
  color: var(--gray-6);
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  cursor: pointer;
}

.subscribe-btn:hover {
  background: var(--white);
  color: var(--pbss-p1);
  border: 1px solid var(--pbss-p1);
}

.form-privacy {
  color: var(--black, var(--black, #000));
  font-size: var(--desktop-fz12);
  font-style: normal;
  font-weight: var(--desktop-fw600);
}

.subscribe-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
}

.subscribe-container > p {
  color: var(--pbss-p1);
  font-size: var(--desktop-fz16);
  font-style: normal;
  line-height: 100%;
  font-weight: var(--desktop-fw700);
}

.logo-img {
  width: 168px;
  height: 70px;
  margin-top: 20px;
}

.footer-logo-img {
  width: 194px;
  height: 90px;
  margin-top: 30px;
}

.icon-container-footer > li > a:hover {
  color: var(--pbss-p1);
  text-decoration: none;
}

.column-heading {
  font-size: var(--desktop-fz16);
  font-style: normal;
  font-weight: var(--desktop-fw700);
  line-height: 150%; /* 24px */
  color: var(--pbss-p1);
}

.icon-container > li {
  display: flex;
}

.column {
  display: flex;
  flex-direction: column;
  gap: 16px;

  /* justify-content: center; */
}

.logo-footer {
  text-align: center;
}

.logo-footer img {
  max-width: 100px;
  height: auto;
  margin: 40px 0px;
}

/* @media screen and (max-width: 992px) {
  .initial-container {
    padding: 30px 60px;
  }
} */

@media screen and (max-width: 468px) {
  .form-privacy {
    color: var(--black, var(--black, #000));
    text-align: center;
    font-size: 9.541px;
    font-style: normal;
    font-weight: var(--desktop-fw600);
    line-height: 150%; /* 14.311px */
    text-decoration-line: underline;
  }
  .subscribe-form {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 16px;
    width: 100%;
  }
  .subscribe-form > input,
  .subscribe-container,
  .subscribe-btn {
    width: 100%;
  }
  .initial-container {
    background: #ffffff;
    /* border-top: 1px solid #dcdfe1; */
    /* padding: 19px 50px; */
    padding-bottom: 48px;
    gap: 40px !important;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr)) !important;
  }
  .subscribe-container > p {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .links-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    gap: 8px;
    align-items: flex-start;
  }
  .column {
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

@media screen and (max-width: 1500px) {
  .initial-container {
    gap: 40px;
  }
  .md-hide {
    display: none;
  }
}
@media screen and (max-width: 786px) {
  .initial-container {
    row-gap: 40px;
    column-gap: 10px;
  }
}
@media screen and (min-width: 1500px) {
  .initial-container {
    display: flex;
    max-width: var(--page-size) !important;
    width: 100%;
  }
}
@media screen and (min-width: 320px) {
  .icon-container-footer > li > a {
    overflow-wrap: anywhere;
  }
}
