.page-heading-container > p {
  color: var(--Black, #000);
  font-size: var(--desktop-fz30);
  font-style: normal;
  font-weight: var(--desktop-fw500);
  line-height: 150%;
  /* 54px */
}

.page-heading-container > span {
  color: #919191;
  font-size: var(--desktop-fz18);
  font-style: normal;
  font-weight: var(--desktop-fw450);
  line-height: 150%;
  /* 33px */
}

.extend {
  padding: 0px 8% !important;
  display: flex;
  gap: 40px;
  justify-content: space-between;
  align-items: end;
}

@media screen and (max-width: 768px) {
  .extend {
    padding: 0px 60px !important;
  }
}

@media screen and (max-width: 468px) {
  .page-heading-container > p {
    font-size: var(--desktop-fz18);
  }
  .page-heading-container > span {
    font-size: var(--desktop-fz16);
  }
  .extend {
    padding: 0px 30px !important;
  }
}

@media screen and (max-width: 940px) {
  .extend {
    display: flex;
    gap: 20px;
    flex-direction: column;
    align-items: self-start;
  }
}
