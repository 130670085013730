@media screen and (max-width: 500px) {
  .chapterSectionLayout {
    padding: 0px 0px !important;
  }
}

.chapter-welcomeline {
  color: white;
  font-weight: var(--desktop-fw700);
}
