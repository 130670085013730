.register-form {
  width: 100%;
  /* padding: 30px 130px; */
}
.register-form-input-container > .ant-input:focus {
  box-shadow: none !important;
}

.register-max-conatiner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.register-form-input {
  width: 100%;
  height: 100%;
  padding: 10px 10px;
}
.register-form-input-container {
  width: 100%;
}
.register-form-input-container > .ant-input::placeholder {
  color: rgb(80, 80, 80);
  font-size: var(--desktop-fz16);
  font-style: normal;
  font-weight: var(--desktop-fw400);
  line-height: 36px;
  padding: 0px;
}

.register-form-input-container > .ant-select::placeholder {
  color: rgb(80, 80, 80);
  font-size: var(--desktop-fz16);
  font-style: normal;
  font-weight: var(--desktop-fw400);
  line-height: 36px;
  padding: 0px;
}
/* .register-form-input-container > .ant-input:hover {
  border-color: #d9d9d9 !important ;
}
.register-form-input-container > .ant-input:focus {
  border-color: #d9d9d9 !important ;
  box-shadow: none !important;
} */
.register-form-input-container
  > .ant-input-affix-wrapper
  > .ant-input::placeholder {
  color: rgb(80, 80, 80);
  font-size: var(--desktop-fz16);
  font-style: normal;
  font-weight: var(--desktop-fw400);
  line-height: 36px;
  padding: 0px;
}
/* .register-form-input-container > .ant-input-affix-wrapper:hover {
  border-color: #d9d9d9 !important ;
}
.register-form-input-container > .ant-input-affix-wrapper:focus {
  border-color: #d9d9d9 !important ;
  box-shadow: none !important;
} */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* .register-form-input-container > .ant-input {
  border-radius: 6px;
  border: 0.882px solid #d6d6d6;
  background: rgba(247, 247, 247, 0.9);
  height: 52px;
  display: flex;
} */

.common-input-register {
  border-radius: 6px;
  border: 0.882px solid #d6d6d6;
  background: rgba(247, 247, 247, 0.9);
  display: flex;
  padding: 10px 11px;
}

.common-input-register .ant-input::placeholder {
  color: rgb(80, 80, 80);
  font-size: var(--desktop-fz16);
  font-style: normal;
  font-weight: var(--desktop-fw400);
  line-height: 36px;
  padding: 0px 5px;
}

.common-select-register-selector {
  border-radius: 6px !important;
  border: 0.882px solid #d6d6d6 !important;
  /* background: rgba(247, 247, 247, 0.9) !important; */
  height: 52px !important;
  display: flex;
  align-items: center;
}

.register-form-input::placeholder ::before {
  content: '*';
}

@media screen and (max-width: 768px) {
  .register-form {
    padding: 0px 0px;
  }
  .register-form-input::placeholder {
    color: #000;
    font-size: var(--desktop-fz16) !important;
    font-style: normal;
    font-weight: 450 !important;
    line-height: 36px !important;
    padding: 0px 5px !important;
  }
  .register-form-input-container > .ant-input {
    border-radius: 6px;
    border: 0.882px solid #d6d6d6;
    background: rgba(247, 247, 247, 0.9);
    width: 100%;
    height: 52px;
    display: flex;
  }
  /* .register-form-input-container > .ant-input-affix-wrapper {
    color: #000;
    font-size: var(--desktop-fz14);
    font-weight: var(--desktop-fw400);
  } */

  .register-form-input::placeholder {
    font-size: var(--desktop-fz17) !important;
  }
}
@media screen and (max-width: 425px) {
  .register-form-input::placeholder {
    font-size: var(--desktop-fz18) !important;
  }
}
