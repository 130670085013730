.event-schedule-conatiner {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.event-schedule {
  border: none !important;
}

.event-scheduler-header {
  align-items: center !important;
  font-size: var(--desktop-fz17);
}

.add-schedule {
  float: right;
  font-size: var(--desktop-fz17);
  box-shadow: 1px 2px 10px rgba(211, 211, 211, 0.343);
  border-radius: 6px;
  padding: 2px 5px !important;
  cursor: pointer;
  border: 1px solid rgba(0, 0, 0, 0.116);
}

.sponsor-icon-email-attach {
  border-radius: 10px;
  padding: 8px;
  height: 140px !important;
  width: 150px !important;
  margin-right: 10px;
  box-shadow: 1px 4px 7px lightgray;
}

.content-info {
  font-size: var(--desktop-fz16);
}

.info-container {
  display: flex;
  gap: 5px;
  align-items: flex-start;
  margin-bottom: 10px !important;
}
