.event-perks-text .ant-typography {
  display: grid;
  grid-template-columns: repeat(2, 2fr); /* Two columns with equal width */
  grid-gap: 10px;
}

.event-perks-text .ant-typography .ant-tag {
  width: auto;
}

.regularAttendeesTag {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 5px;
  font-family: var(--font-inter);
  font-size: var(--desktop-fz14);
  font-weight: var(--desktop-fw400);
  color: var(--attendee-table-tag-text);
  background-color: var(--attendee-table-tag);
  border: 1px solid var(--attendee-table-tag-border);
}
