.history-Info-container {
  height: 40%;
  border-radius: 2px;
  background-color: rgb(255, 255, 255);
}
.main-container-historypopup {
  width: auto;
  background: 'white';
  border: 1px solid rgba(233, 233, 233, 0.658);
  box-shadow: 1px 1px 10px rgb(243, 243, 243);
}

.inner-info-user {
  padding: 10px 5px;
}
.historyUpdateButton {
  background-color: transparent;
  border: none;
  font-size: var(--desktop-fz14);
  width: 100%;
  border-radius: 4px;
  /* padding: 4px; */
  color: rgb(129, 129, 129);
  cursor: pointer;
  display: flex;
}

.historyUpdateButton > span {
  font-weight: var(--desktop-fw600);
  margin-left: 2px;
}

.user-list-info {
  display: flex;
  color: #484848;
  font-style: normal;
  font-weight: var(--desktop-fw600);
  font-size: var(--desktop-fz14);
  line-height: 13px;
  display: flex;
  justify-content: start;
  padding: 10px;
}

.user-list-info > p {
  font-size: var(--desktop-fz13);
  font-weight: var(--desktop-fw500);
  margin-left: 5px;
}

.createUser {
  background-color: #6966c635;
  color: rgb(0, 0, 0);
  display: flex;
}
.create {
  background-color: transparent;
  color: rgba(0, 0, 0, 0.669);
  font-size: var(--desktop-fz12);
}
.Button-show-history {
  background: '';
  margin-left: 5px;
  width: auto;
  padding: 0px 4px;
  border-radius: 50%;
  color: black;
}
.user-info-heading {
  display: flex;
  font-size: var(--desktop-fz14);
  font-weight: var(--desktop-fw600);
  color: #e48a1f;
  padding: 0px 10px;
}

.updateHeading {
  margin-top: -15px;
  color: #484848;
}

.label-divider {
  margin-top: 10px;
  margin-bottom: 10px;
}
