/* Event Card Styles */

.event-section1 {
  line-height: 50px;
  width: 70%;
}

.event-section1 > p {
  color: var(--Black, #000);
  font-size: var(--desktop-fz21);
  font-style: normal;
  font-weight: var(--desktop-fw600);
}

.event-section2 {
  /* Add styles for the second section of your event card */
}

.description {
  /* Add styles for the description text */
}

.badge-container {
  display: grid;
  width: 70%;
  grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
  gap: 15px;
}

.group1-badge {
  display: flex;
  flex-direction: column;
  justify-content: end;
  gap: 10px;
}

.group1-badge > label {
  color: #191a1a;
  font-size: var(--desktop-fz12);
  font-style: normal;
  font-weight: var(--desktop-fw700);
  line-height: normal;
}

.date-badge {
  border-radius: 18.115px;
  background: #000;
  display: inline-flex;
  padding: 5.328px 10.656px !important;
  justify-content: center;
  align-items: center;
}

.common-text-white {
  color: #fff;
  text-align: center;
  font-size: 12.787px;
  font-style: normal;
  font-weight: var(--desktop-fw700);
  line-height: normal;
}

.location-badge {
  border-radius: 5.742px;
  background: #828282 !important;
}

.common-badge {
  border-radius: 4.845px;
  background: var(--Gray-4, #bdbdbd);
  display: flex;
  width: 77px;
  height: 27px;
  padding: 2.422px 9.689px !important;
  justify-content: center;
  align-items: center;
  gap: 12.112px;
  flex-shrink: 0;
  width: auto;
}

.event-dis-container {
  display: flex;
  margin-top: 40px;
}

.event-dis-container > p {
  margin: 0 !important;
  padding: 0 !important;
  color: #111010;
  font-size: var(--desktop-fz18);
  font-style: normal;
  font-weight: var(--desktop-fw450);
  line-height: 162%;
}

.event-dis-title {
  color: #111010;
  font-size: var(--desktop-fz18);
  font-style: normal;
  font-weight: var(--desktop-fw700);
  line-height: 162%;
}

/* Add styles for other elements as needed */
.event-section2 {
  gap: 15px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
/* Button Styles */
.event-section2 > button {
  border-radius: 6px;
  background: #000;
  display: inline-flex;
  padding: 8px 20px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  color: var(--White, var(--White, #fff));
  font-size: var(--desktop-fz16);
  font-style: normal;
  font-weight: var(--desktop-fw700);
  line-height: 150%;
  border: none;
  cursor: pointer;
}

.event-section2 > p {
  color: #505050;
  font-size: 22.004px;
  font-style: normal;
  font-weight: var(--desktop-fw600);
  line-height: 162%;
  /* 38.887px */
}

/* Price Badge Styles */
.price-badge {
  border-radius: 14.768px;
  border: 1.207px solid #000;
  background: #fff;
  display: flex;
  width: 125px;
  height: 27px;
  padding: 4.344px 8.687px;
  justify-content: center;
  align-items: center;
  gap: 5.212px;
  flex-shrink: 0;
}

.side-border {
  border-left: 2px solid #b7b7b7;
  width: 100%;
  display: flex;
  justify-content: center;
}

.more-details {
  margin-top: 20px;
}

@media screen and (max-width: 900px) {
  .event-main-container {
    flex-direction: column;
  }
  .event-section1 {
    width: auto;
  }
  .event-section2 {
    display: grid;
    margin: 20px 0px;
  }
}

@media screen and (max-width: 1500px) {
  .badge-container {
    width: 100%;
  }
}
