.upcoming-events-heading-container {
  width: 100%;
  display: flex;
  gap: 20px;
  /* justify-content: space-between; */
}

.upcoming-title {
  font-size: var(--desktop-fz28);
  font-weight: var(--desktop-fw500);
}

.upcoming-events-heading-button-container {
  display: flex;
  /* border: 1px solid #c6cfe1; */
}
.filter-by-label {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  padding: 10px 10px;
  /* width: 110px; */
  /* height: 52px; */
  background-color: var(--pbss-p3);
  font-size: var(--desktop-fz12);
  font-weight: var(--desktop-fw600);
  color: #fff;
}

.filter-by-label img {
  width: 12px;
  height: 12px;
}
.event_type_select-selector:hover {
  border-color: var(--pbss-p3) !important;
}
.event_type_select_chapter {
  width: 120px !important;
}
.event_type_select_year-selector {
  width: 120px !important;
}

.event_type_select-selector,
.event_type_select_year-selector,
.event_type_select_chapter-selector {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 120px;
  min-height: 100%;
  border-radius: 0px !important;
  font-size: var(--desktop-fz12);
  font-weight: var(--desktop-fw700);
  color: #000;
}
.date_picker:hover {
  border-color: var(--pbss-p3) !important;
}
.event_type_select_chapter-focused {
  border-color: var(--pbss-p3) !important;
}
.event_type_select_chapter-selector:hover {
  border-color: var(--pbss-p3) !important;
}

.event_type_select_chapter-selector:focus {
  border-color: var(--pbss-p3) !important;
}

.event_type_select_year-selector-focused {
  border-color: var(--pbss-p3) !important;
}
.event_type_select_year-selector:hover {
  border-color: var(--pbss-p3) !important;
}

.event_type_select_year-selector:focus {
  border-color: var(--pbss-p3) !important;
}

.event_type_select-selection-item.event_type_select-selection-item,
.event_type_select_year-selection-item.event_type_select_year-selection-item,
.event_type_select_chapter-selection-item.event_type_select_chapter-selection-item {
  padding: 0px 10px 0 0 !important;
  /* display: flex; */
  /* justify-content: center; */
  /* align-items: center; */
  font-size: var(--desktop-fz12);
  font-weight: var(--desktop-fw700) !important ;
  overflow: hidden !important ;
  text-overflow: ellipsis !important ;
  white-space: nowrap !important ;
}

.event_type_select-selection-search,
.event_type_select_year-selection-search,
.event_type_select_chapter-selection-search {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.event_type_select-arrow,
.event_type_select_year-arrow,
.event_type_select_chapter-arrow {
  color: #323434 !important;
  font-weight: var(--desktop-fw700) !important;
}

.event-date-filter-button {
  height: 100%;
}
.date_picker {
  height: auto;
  padding: 0 15px;
  border: none;
  background-color: #fff;
  border: 1px solid #c6cfe1;
  border-radius: 0px;
  color: rgba(0, 0, 0, 0.88);
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.date_picker > span {
  font-size: var(--desktop-fz12);
  font-weight: var(--desktop-fw700);
}

.date_picker-input > input::placeholder {
  color: #000 !important;
  font-size: var(--desktop-fz12);
  font-weight: var(--desktop-fw700);
}
/* select drop-down width increase  */
.event_type_select-dropdown {
  width: 15% !important;
}

.event_type_select_chapter-dropdown {
  width: 10% !important;
}

.event_type_select-item-option-content,
.event_type_select_chapter-dropdown {
  white-space: wrap !important;
}

@media screen and (max-width: 1400px) {
  .upcoming-events-heading-container-responsive-for-chapter-page {
    flex-wrap: wrap;
    gap: 30px;
  }
  .upcoming-events-heading-button-container-chapter-page {
    width: 100%;
    display: flex;
  }
  .upcoming-events-heading-button-container-chapter-page .event_type_select {
    flex: 1.5 !important;
  }
  .date_picker-chapter-page {
    flex: 1 !important;
    max-width: 100px !important;
  }
  .filter-by-label-chapter-page {
    flex: 1 !important;
    max-width: 90px !important;
  }
  .event_type_select_chapter-dropdown {
    width: 18% !important;
  }
}

@media screen and (max-width: 1024px) {
  .upcoming-title {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: var(--laptop-fz26);
  }

  .filter-by-label {
    width: 90px;
  }

  .event_type_select-selector {
    width: 100% !important;
  }
  .date_picker {
    width: 100px;
  }
}

@media screen and (max-width: 768px) {
  /* .filter-by-label {
    width: 40px;
    height: 36px;
  } */
  .event_type_select_chapter {
    width: 40% !important;
  }
  .event_type_select_year {
    width: 120px !important;
  }
  .used-for-myevents {
    width: 170px !important;
  }
  .event_type_select_year-selector {
    width: 100% !important;
  }
  .upcoming-events-heading-container {
    gap: 15px;
  }
  .upcoming-events-heading-container-responsive-for-past-events {
    flex-wrap: nowrap !important;
    display: flex !important;
    justify-content: space-between !important;
  }

  .upcoming-events-heading-button-container-past-events {
    width: 30% !important;
    display: flex;
    justify-content: flex-end;
  }
  .upcoming-events-heading-button-container {
    width: 100%;
    display: flex;
  }

  .upcoming-title {
    font-size: var(--laptop-fz24);
    min-width: fit-content;
    justify-content: start;
    text-wrap: nowrap;
  }

  .upcoming-events-heading-button-container .event_type_select {
    flex: 1.5 !important;
  }
  .date_picker {
    flex: 1 !important;
    max-width: 100px !important;
  }
  .pbssMainContainerRowEventListing > .ant-col-24 {
    padding: 0px !important;
  }
  .filter-by-label {
    /* flex: 1 !important; */
    /* max-width: 90px !important; */
  }
  .event_type_select_chapter-dropdown {
    width: 22% !important;
  }
}

@media screen and (max-width: 730px) {
  .upcoming-events-heading-container {
    flex-direction: column;
    gap: 15px;
    margin: 15px 0px;
  }
  .usedForPast {
    flex-direction: row !important;
  }
}

@media screen and (max-width: 550px) {
  .filter-by-label {
    height: 36px;
    width: 40px;
  }
  .filter-by-label span {
    display: none;
  }

  .event_type_select,
  .event_type_select_year {
    height: 36px;
    padding: 0 0;
  }

  .event_type_select,
  .event_type_select_year {
    width: 98px !important;
  }
  .event_type_select-selection-item,
  .event_type_select_year-selection-item {
    font-size: var(--mobile-fz10);
    margin-right: 10px;
  }
  .event_type_select-dropdown {
    width: 50% !important;
  }
  .date_picker {
    width: 70px !important;
    margin-left: 0px;
    gap: 5px;
    padding: 0px 5px;
  }
  .date_picker-input > input::placeholder,
  .date_picker-input > input {
    font-size: var(--mobile-fz10);
  }
  .upcoming-title {
    font-size: var(--laptop-fz20);
  }

  .event_type_select_year {
    width: 120px !important;
  }
  .event_type_select_year-selector {
    width: 100% !important;
  }
  .event_type_select_chapter {
    width: 170px !important;
  }
}

@media screen and (max-width: 425px) {
  /* .event_type_select_year-selector {
    width: 87px !important;
  } */
  .filter-by-label {
    height: 36px;
    width: 40px;
  }
  .filter-by-label span {
    display: none;
  }
  .upcoming-events-heading-button-container-past-events {
    width: 30%;
  }
  .upcoming-events-heading-container {
    gap: 25px;
  }
  .upcoming-events-heading-container {
    flex-wrap: wrap;
  }
  .upcoming-title {
    font-size: var(--laptop-fz18);
  }
  .event_type_select_chapter-dropdown {
    width: 45% !important;
  }

  .event_type_select,
  .event_type_select_year {
    height: 36px;
    padding: 0 0;
  }

  .event_type_select {
    width: 80px !important;
  }
  .event_type_select-selection-item,
  .event_type_select_year-selection-item {
    font-size: var(--mobile-fz8);
    margin-right: 0px;
  }
  .date_picker {
    width: 65px !important;
    margin-left: 0px;
  }
  .date_picker-input > input::placeholder,
  .date_picker-input > input {
    font-size: var(--mobile-fz8);
  }
  .date_picker-suffix > svg {
    width: 14px;
    height: 14px;
  }
}
@media screen and (max-width: 400px) {
  .upcoming-events-heading-button-container-past-events {
    width: 35% !important;
  }
}
@media screen and (max-width: 375px) {
  .filter-by-label {
    width: 40px !important;
  }
  .event_type_select_chapter {
    width: 140px !important;
  }

  .upcoming-events-heading-button-container-past-events {
    width: 41% !important;
  }
}

@media screen and (max-width: 350px) {
  .upcoming-events-heading-container {
    margin: 15px -5px;
  }
  .event_type_select_chapter-dropdown {
    width: 55% !important;
  }
}

@media screen and (max-width: 320px) {
  .event_type_select_year-selector {
    width: 120px !important;
  }
  .event_type_select_chapter {
    width: 120px !important;
  }
}
