/* YourComponentStyles.css */

.committee-card-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 15px;
  min-width: 229px;
  /* width: fit-content; */
  height: auto;
  min-height: 80px;
  padding: 1rem;
  box-shadow: 1px 4px 10px rgba(211, 211, 211, 0.671);
}
.committee-card-container-pointer {
  cursor: pointer;
}

.sub-committee-card-container {
  display: flex;
  flex-direction: column;
  gap: 1%;
}

.sub-committee-card-container p {
  color: var(--pbss-p1);
  font-size: var(--desktop-fz18);
  font-style: normal;
  font-weight: var(--desktop-fw500);
  line-height: 150%;
}

.sub-committee-card-container > label {
  color: var(--Black, #000);
  font-size: var(--desktop-fz14);
  font-style: normal;
  font-weight: var(--desktop-fw400);
  line-height: 130%;
}

.committee-read-more-icon {
  font-size: var(--desktop-fz16);
  color: var(--pbss-p1);
  font-weight: var(--desktop-fw700);
  display: flex;
  align-items: center;
  padding: 0px !important;
  gap: 8px;
}
.committee-read-more-icon:hover {
  background: none !important;
  color: var(--pbss-p1) !important;
}
.committee-read-more-icon-container {
  display: flex;
  align-items: center;
}
.committee-close-icon {
  font-size: 20px;
  color: var(--pbss-p1);
}
.committee-member-name {
  color: var(--pbss-p1);
  font-size: var(--desktop-fz22);
  font-weight: var(--desktop-fw600);
  line-height: 100%;
}
.committee-member-sub-heading {
  font-size: var(--desktop-fz16);
  font-weight: var(--desktop-fw600);
}
.committee-member-description {
  font-size: var(--desktop-fz15);
  font-weight: var(--desktop-fw400);
  text-align: left;
}
.committee-profile-image {
  max-width: 200px;
  height: 250px;
  object-fit: cover;
}
.committee-main-container {
  display: flex;
  gap: 40px;
  margin-bottom: 40px;
  margin-top: 30px;
}
.committee-content-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.committee-read-more-modal-content {
  padding: 20px 40px !important;
  height: 450px;
  overflow-y: auto;
}
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: transparent;
}
::-webkit-scrollbar-track {
  width: 6px;
  height: 6px;
  background-color: transparent;
}
::-webkit-scrollbar-thumb {
  width: 6px;
  height: 6px;
  border-radius: 4px;
  background-color: rgba(165, 165, 165, 0.726);
}
@media screen and (max-width: 768px) {
  .committee-read-more-modal {
    width: 650px;
  }
  .committee-member-sub-heading {
    font-size: var(--desktop-fz14);
  }
  .committee-member-description {
    font-size: var(--desktop-fz14);
    text-align: left;
  }
}
@media screen and (max-width: 480px) {
  .sub-committee-card-container p {
    font-size: var(--desktop-fz14);
  }
  .sub-committee-card-container > label {
    font-size: var(--desktop-fz12);
  }
  .committee-card-container {
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .committee-read-more-modal {
    height: 650px;
  }
  .committee-member-sub-heading {
    font-size: var(--desktop-fz13);
  }
  .committee-member-description {
    font-size: var(--desktop-fz13);
    font-weight: var(--desktop-fw500);
    text-align: justify;
  }
  .committee-main-container {
    display: grid;
  }
  .committee-read-more-modal-content {
    overflow-y: scroll;
    height: 650px;
    padding: 20px 30px !important;
  }
  .committee-profile-container {
    display: flex;
    justify-content: center;
  }
}
