.listing-heading-container > p {
  color: var(--Black, #000);
  font-size: var(--desktop-fz28);
  font-style: normal;
  font-weight: var(--desktop-fw450);
  line-height: 132%;
  /* 36.96px */
}

.listing-heading-container > span {
  color: #666;
  font-size: var(--desktop-fz18);
  font-style: normal;
  font-weight: var(--desktop-fw450);
  line-height: 212%;
  /* 38.16px */
}

.event-listing-section1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0;
}

.event-filter {
  display: flex;
  gap: 10px;
}

.filter-button {
  border-radius: 6px;
  border: 1.237px solid #000;
  color: var(--pbss-p1, #005d61);
  font-size: 15.96px;
  font-style: normal;
  font-weight: var(--desktop-fw450);
  line-height: 25.08px;
  /* 157.143% */
}

.event-selection {
  display: flex;
  width: 350px;
  padding: 7px 0px;
  flex-direction: column;
  align-items: flex-start;
  gap: 9.194px;
  flex-shrink: 0;
  color: #005d61 !important;
  border-radius: 6px;
  border: 1px solid;
  background: linear-gradient(
    47deg,
    #bce8ea -85.95%,
    rgba(255, 255, 255, 0) 216.03%
  );
}

.event-selection-selector {
  background: none !important;
  color: #005d61 !important;
  cursor: pointer !important;
  border: none !important;
  box-shadow: none !important;
}

.event-selection-arrow {
  width: 15.134px;
  height: 15.134px;
  font-size: var(--desktop-fz15) !important;
  color: #005d61 !important;
}

.event-listing-cards {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
@media screen and (max-width: 768px) {
  .event-listing-section1 {
    flex-direction: column;
    align-items: start;
    gap: 20px;
  }
}

@media screen and (max-width: 450px) {
  .event-selection {
    width: auto !important;
  }
}
