.register-form {
  width: 100%;
  /* padding: 30px 130px; */
}

.register-select-label {
  font-size: var(--desktop-fz16) !important;
  font-style: normal;
  font-weight: var(--desktop-fw500) !important;
}

.register-form-input-container > .ant-input:focus {
  box-shadow: none !important;
}

.common-select-register-form-selection-item {
  display: flex;
  align-items: center;
}

.space .common-select-register-form-selection-search {
  top: 10px !important;
}
.register-button {
  width: 100% !important;
}
.tagrender-container {
  padding: 5px 0px;
}
.register-form .ant-form-item-required {
  color: var(--Black, #000);
  font-size: var(--desktop-fz16) !important;
  font-style: normal;
  font-weight: var(--desktop-fw500) !important;
}

.register-form
  .ant-form-item
  .ant-form-item-label
  > label.ant-form-item-required::before {
  content: '' !important;
}

.register-form .ant-input-prefix {
  color: #ff4d4f;
}

.Register-form-conatiner {
  max-width: 1000px;
  width: 100%;
}

.register-select-input-selection-search,
.register-select-keyword-selection-search {
  margin-inline-start: 11px !important;
}
.register-select-input-status-error .register-select-input-selector {
  border-color: #ff4d4f !important;
}
.register-form-input {
  width: 100%;
  height: 100%;
  padding: 10px 10px;
  padding: 10px 10px;
  border: 1px solid #4b4b4b !important;
}

.register-form-input-container > .ant-input-affix-wrapper-status-error {
  border: 1px solid #ff4d4f !important;
}
.register-form-input-container > .ant-input-affix-wrapper-status-error:hover {
  border-color: #ff4d4f !important;
}
.register-form-input-container > .ant-input-affix-wrapper-status-error-focused {
  border-color: #ff4d4f !important;
}
.register-form-input-container {
  width: 100%;
}

.register-form-input-container > .ant-input::placeholder {
  color: var(--Black, #000);
  font-style: normal;
  font-size: var(--desktop-fz14);
  font-weight: var(--desktop-fw400);
  line-height: 150%; /* 26.471px */
  display: flex;
  align-items: center;
  font-family: var(--font-inter) !important;
}
.register-form-input::placeholder {
  color: var(--Black, #000);
  font-style: normal;
  font-size: var(--desktop-fz14);
  font-weight: var(--desktop-fw400);
  line-height: 150%; /* 26.471px */
  display: flex;
  align-items: center;
  font-family: var(--font-inter) !important;
}

.register-form-input-container > .ant-select::placeholder {
  color: var(--Black, #000);
  font-style: normal;
  font-size: var(--desktop-fz14);
  font-weight: var(--desktop-fw400);
  line-height: 150%; /* 26.471px */
  display: flex;
  align-items: center;
  font-family: var(--font-inter) !important;
}

.register-form-input-container > .ant-input:hover {
  border: 1px solid #4b4b4b !important;
}

.register-form-input-container > .ant-input:focus {
  border: 1px solid #4b4b4b !important;
}

.register-form-input-container
  > .ant-input-affix-wrapper
  > .ant-input::placeholder {
  color: var(--Black, #000);
  font-style: normal;
  font-size: var(--desktop-fz14);
  font-weight: var(--desktop-fw400);
  line-height: 150%; /* 26.471px */
  display: flex;
  align-items: center;
  font-family: var(--font-inter) !important;
}
.register-form-input-container .ant-input-affix-wrapper:hover {
  border-color: #4b4b4b !important;
  box-shadow: none;
}
/* .register-form-input-container > .ant-input-affix-wrapper:hover {
  border: 1px solid #4b4b4b;
}

.register-form-input-container > .ant-input-affix-wrapper:focus {
  border: 1px solid #4b4b4b;
} */

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.register-form-input-container > .ant-input {
  display: flex;
  border-radius: 6px;
  border: 1px solid #4b4b4b;
  background: #fff;
}

.common-input-register {
  display: flex;
  border-radius: 6px;
  border: 1px solid #4b4b4b;
  background: none;
}

.common-input-register .ant-input::placeholder {
  color: var(--Black, #000);
  font-style: normal;
  font-size: var(--desktop-fz14);
  font-weight: var(--desktop-fw400);
  line-height: 150%; /* 26.471px */
  display: flex;
  align-items: center;
  font-family: var(--font-inter) !important;
}
.register-select-input-selector {
  border-radius: 6px !important;
  border: 1px solid #4b4b4b !important;
  display: flex !important;
  padding: 7px 0px !important;
  align-items: center;
}
.register-select-local-chapter-selector {
  border-radius: 6px !important;
  border: 1px solid #4b4b4b !important;
  display: flex !important;
  padding: 7px 11px !important;
  align-items: center;
  align-items: center;
  height: auto !important;
}

.register-select-country-state-selector,
.register-select-country-selector {
  border-radius: 6px !important;
  border: 1px solid #4b4b4b !important;
  display: flex !important;
  padding: 7px 11px !important;
  align-items: center;
  align-items: center;
  height: auto !important;
}

.register-select-country-state-selection-placeholder::before {
  margin-right: 5px;
}
.register-select-country-selection-placeholder::before {
  content: '*';
  color: red;
  margin-right: 5px;
}

.register-select-local-chapter-status-error
  .register-select-local-chapter-selector,
.register-select-keyword-status-error .register-select-keyword-selector {
  border: 1px solid red !important ;
}

.register-select-country-state-status-error
  .register-select-country-state-selector,
.register-select-country-status-error .register-select-country-selector,
.register-select-keyword-status-error .register-select-keyword-selector {
  border: 1px solid red !important ;
}

.register-select-local-chapter-selection-search {
  display: flex;
  align-items: center !important;
}

.register-select-country-state-selection-search,
.register-select-country-selection-search {
  display: flex;
  align-items: center !important;
}

.register-select-keyword-selector {
  border-radius: 6px !important;
  border: 1px solid #4b4b4b !important;
  display: flex !important;
  padding: 9px 20px !important;
  align-items: center;
  align-items: center;
}

.register-select-keyword-selection-search {
  display: flex;
  align-items: center !important;
}

.register-select-input-focused {
  font-size: var(--desktop-fz17) !important;
  font-weight: var(--desktop-fw500) !important;
}
.register-select-input-selection-placeholder {
  color: var(--Black, #000) !important;
  font-style: normal;
  font-size: var(--desktop-fz14);
  font-weight: var(--desktop-fw400);
  line-height: 150%; /* 26.471px */
  font-family: var(--font-inter) !important;
  display: flex;
  align-items: center;
}
.register-select-input-arrow {
  color: #000 !important;
}
.register-select-input-selection-item {
  color: var(--Black, #000);
  font-size: var(--desktop-fz17) !important;
  font-weight: var(--desktop-fw500) !important;
  font-style: normal;
  line-height: 150%; /* 26.471px */
}
.register-select-local-chapter-focused {
  font-size: var(--desktop-fz14) !important;
  font-weight: var(--desktop-fw500) !important;
}

.register-select-country-state-focused,
.register-select-country-focused {
  font-size: var(--desktop-fz14) !important;
  font-weight: var(--desktop-fw500) !important;
}

.register-select-country-state-selector
  .register-select-country-state-selection-item,
.register-select-country-selector
  .register-select-country-selection-item
  .register-select-local-chapter-selector
  .register-select-local-chapter-selection-item {
  font-size: var(--desktop-fz14) !important;
}

.register-select-local-chapter-selection-placeholder {
  color: var(--Black, #000) !important;
  font-style: normal;
  font-size: var(--desktop-fz14);
  font-weight: var(--desktop-fw400);
  line-height: 150%; /* 26.471px */
  font-family: var(--font-inter) !important;
  display: flex;
  align-items: center;
}

.register-select-country-state-selection-placeholder,
.register-select-country-selection-placeholder {
  color: var(--Black, #000) !important;
  font-style: normal;
  font-size: var(--desktop-fz14);
  font-weight: var(--desktop-fw400);
  line-height: 150%; /* 26.471px */
  font-family: var(--font-inter) !important;
  display: flex;
  align-items: center;
}

.register-select-local-chapter-arrow {
  color: #000 !important;
}

.register-select-country-state-arrow,
.register-select-country-arrow {
  color: #000 !important;
}

/* .register-select-local-chapter-selection-search-input {
  padding-left: 10px !important;
} */
.register-select-local-chapter-selection-item {
  color: var(--Black, #000);
  font-size: var(--desktop-fz17) !important;
  font-weight: var(--desktop-fw500) !important;
  font-style: normal;
  line-height: 150%; /* 26.471px */
}

.register-select-country-state-selection-item,
.register-select-country-selection-item {
  color: var(--Black, #000);
  font-size: var(--desktop-fz17) !important;
  font-weight: var(--desktop-fw500) !important;
  font-style: normal;
  line-height: 150%; /* 26.471px */
}

.register-select-keyword-focused {
  font-size: var(--desktop-fz17) !important;
  font-weight: var(--desktop-fw500) !important;
}
.register-select-keyword-selection-placeholder {
  color: var(--Black, #000) !important;
  font-style: normal;
  font-size: var(--desktop-fz14);
  font-weight: var(--desktop-fw400);
  line-height: 150%; /* 26.471px */
  padding: 20px;
  display: flex;
  align-items: center;
  font-family: var(--font-inter) !important;
}

.keyword-other-container .tag-render-selected {
  margin: 2px;
}
.register-select-keyword-arrow {
  color: #000 !important;
}
.register-select-keyword-selection-item {
  color: var(--Black, #000);
  font-size: var(--desktop-fz17) !important;
  font-weight: var(--desktop-fw500) !important;
  font-style: normal;
  background: none !important;
  line-height: 150%; /* 26.471px */
}
.tag-render {
  display: inline-flex;
  border-radius: 15px;
  align-items: center;
  cursor: pointer;
  user-select: none;
  padding: 2px 10px;
  height: auto !important;
}
.chapter-button {
  position: relative;
  left: 80%;
  bottom: 37px;
}
.tag-render-text {
  display: flex;
  align-items: center;
  font-size: var(--desktop-fz14) !important;
}
.select {
  color: black !important;
  border-color: #000 !important;
  background-color: #ffffff;
}
.clear {
  color: white !important;
  border-color: #000 !important;
  background-color: #000;
}
.close {
  gap: 5px;
}
.keyword-other-buttons {
  display: flex;
  justify-content: end;
}
.keyword-other-input {
  border-radius: 6px;
  border: 1px solid #4b4b4b;
  width: 25%;
  padding: 5px 10px;
  font-size: var(--desktop-fz14) !important;
}
.keyword-other-input:hover {
  border: 1px solid #4b4b4b !important;
}
.keyword-other-input:focus {
  border: 1px solid #4b4b4b !important;
}
.tag-render-selected {
  background-color: #c4d6ff !important;
  color: #10306f !important;
  border-radius: 27.778px;
  font-size: var(--desktop-fz16) !important;
  font-weight: var(--desktop-fw500) !important;
  padding: 2.778px 11.111px;
}
.keyword-container {
  border-radius: 6px;
  background: #ebf2ff;
  width: 100%;
  padding-left: 26px;
  padding-right: 26px;
  padding-bottom: 26px;
}
.keyword-label-container {
  display: flex;
  justify-content: space-between;
  padding-top: 30px;
}
.keyword-label {
  color: var(--Black, #000);
  font-size: var(--desktop-fz18);
  font-style: normal;
  font-weight: 500;
  line-height: 40%; /* 7.2px */
}
.keyword-render {
  border-radius: 2.922px;
  max-width: 282px;
  padding: 10px 20px;
  background: #fff;
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 56px;
}
.keyword-render-container {
  margin-top: 20px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  row-gap: 38px;
  column-gap: 11px;
  height: 250px;
  overflow-y: scroll;
  overflow-x: hidden;
}
.keyword-render-container::-webkit-scrollbar-thumb {
  background-color: #a3a3a3;
  /* Thumb color */
  border-radius: 10px;
  height: 60%;
  box-shadow: 1px 1px 10px lightgray;
}

.keyword-render-container::-webkit-scrollbar-track {
  background-color: #eee;
  /* Track color */
}

.keyword-render-container::-webkit-scrollbar {
  width: 10px;
  /* Adjust the width of the scroll bar */
  visibility: none;
}
.keyword-name {
  width: auto;
}
.keyword-checkbox-input {
  border-radius: 6.819px;
  border: var(--Count, 1.705px) solid var(--Neutral-200, #e5e7eb);
  background: var(--Shades-White, #fff);
}
.keyword-checkbox-inner {
  width: 21.57px !important;
  height: 20.57px !important;
}
.keyword-other-label {
  color: var(--Black, #000);
  font-size: var(--desktop-fz18);
  font-style: normal;
  font-weight: var(--desktop-fw500);
}
.keyword-other-rendercontainer {
  border-radius: 6px;
  background: #ebf2ff;
  max-width: 1008px;
  width: 100%;
  padding-left: 26px;
  padding-right: 26px;
  padding-bottom: 26px;
}
.keyword-other-render {
  width: 100%;
  border-radius: 6px;
  background: #ebf2ff;
  margin-top: 40px;
  padding: 23px 20px;
}
.keyword-other-add {
  border: none;
  background: none;
  color: var(--Black, #000);
  font-size: var(--desktop-fz16);
  font-style: normal;
  font-weight: var(--desktop-fw450);
  line-height: 150%; /* 24px */
  display: inline-flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
}
.keyword-other-icon-add {
  font-size: 14px !important;
}
.close-input {
  color: #000 !important;
  border-color: #000 !important;
  background-color: white;
  padding: 3px 10px;
  font-weight: 600;
}
.input-close-icon {
  font-weight: 600;
}
.save-input {
  color: white !important;
  border-color: #000 !important;
  background-color: #000;
  padding: 3px 10px;
  margin-left: 20px;
}

.keyword-checkbox-inner::after {
  width: 7.714286px !important;
  height: 16.142857px !important;
}
.gap {
  gap: 10px;
}
.vendor-text {
  color: var(--Black, #000);
  font-size: large;
  font-style: normal;
  font-weight: var(--desktop-fw500);
  line-height: 150%; /* 22.991px */
}
.register-buttons {
  margin-top: 46px;
}
.register-submit {
  border-radius: 6px;
  background: #2e56a4;
  display: flex;
  width: 495px;
  height: 52px;
  padding: 11.644px 29.111px;
  justify-content: center;
  align-items: center;
  gap: 11.644px;
  flex-shrink: 0;
  color: var(--White, var(--White, #fff));
  font-size: 23.289px;
  font-style: normal;
  font-weight: var(--desktop-fw500);
  line-height: 150%;
  /* 34.933px */
}
.register-submit:hover {
  background: #2e56a4 !important;
}
.register-cancel {
  border-radius: 12px;
  display: flex;
  width: 495px;
  height: 52px;
  padding: 11.644px 29.111px;
  justify-content: center;
  align-items: center;
  gap: 11.644px;
  flex-shrink: 0;
  color: #333 !important;
  border-radius: 6px;
  border: 1.456px solid var(--Gray-1, #333);
  font-size: 23.289px;
  font-style: normal;
  font-weight: var(--desktop-fw500);
  line-height: 150%;
  /* 34.933px */
}

.register-cancel:hover {
  border-color: #333 !important;
}
@media screen and (max-width: 1440px) {
  .vendor-check {
    display: flex;
    align-items: center;
  }
  .keyword-label {
    line-height: 20px !important;
  }
  .keyword-other-label {
    line-height: 20px !important;
  }
  .chapter-button {
    left: 75% !important;
    bottom: 40px !important;
  }
  .keyword-render-container {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }
}
@media screen and (min-width: 1024px) {
  .ads-column {
    padding-left: 50px;
    padding-right: 50px;
  }
}
@media screen and (max-width: 1024px) {
  .ads-column {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
  .chapter-button {
    left: 60% !important;
    bottom: 40px !important;
  }
  /* .keyword-render-container {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  } */
  .keyword-name {
    font-size: 12px;
  }
}
@media screen and (max-width: 768px) {
  .ads-column {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .vendor-check {
    width: max-content;
  }
  .chapter-button {
    left: 70% !important;
    bottom: 40px !important;
  }
  .register-form {
    padding: 0px 0px;
  }
  /* 
  .register-form-input::placeholder {
    color: #000;
    font-size: var(--laptop-fz16) !important;
    font-weight: var(--laptop-fw450) !important;
  } */

  /* .register-form-input-container > .ant-input {
    border: 0.882px solid #d6d6d6;
    width: 100%;
    height: 52px;
    display: flex;
  } */

  /* .common-input-register .ant-input::placeholder {
    font-size: var(--laptop-fz16) !important;
    font-weight: var(--laptop-fw450) !important;
  }
  .register-form-input-container > .ant-input::placeholder {
    font-size: var(--laptop-fz16) !important;
    font-weight: var(--laptop-fw450) !important;
  }

  .register-form-input::placeholder {
    font-size: var(--laptop-fz16) !important;
    font-weight: var(--laptop-fw450) !important;
  }
  .register-select-local-chapter-selection-placeholder {
    font-size: var(--laptop-fz16) !important;
    font-weight: var(--laptop-fw450) !important;
  }
  .register-select-keyword-selection-placeholder {
    font-size: var(--laptop-fz16) !important;
    font-weight: var(--laptop-fw450) !important;
  } */

  /* .keyword-label {
    font-size: var(--laptop-fz18) !important;
    font-weight: var(--laptop-fw450) !important;
  } */
  /* .keyword-other-label {
    font-size: var(--laptop-fz18) !important;
    font-weight: var(--laptop-fw500) !important;
  } */
  /* .register-select-input-selection-placeholder {
    font-size: var(--laptop-fz16) !important;
    font-weight: var(--laptop-fw450) !important;
  } */
  .vendor-text {
    font-size: large !important;
    font-weight: var(--laptop-fw400) !important;
  }
}

@media screen and (max-width: 425px) {
  .ads-column {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .common-input-register .ant-input::placeholder {
    font-size: var(--mobile-fz16) !important;
    font-weight: var(--mobile-fw450) !important;
  }
  .register-form-input::placeholder {
    font-size: var(--mobile-fz16) !important;
    font-weight: var(--mobile-fw450) !important;
  }
  .register-select-local-chapter-selection-placeholder {
    font-size: var(--mobile-fz16) !important;
    font-weight: var(--mobile-fw450) !important;
  }

  .register-select-country-state-selection-placeholder,
  .register-select-country-selection-placeholder {
    font-size: var(--mobile-fz16) !important;
    font-weight: var(--mobile-fw450) !important;
  }

  .register-select-keyword-selection-placeholder {
    font-size: var(--mobile-fz16) !important;
    font-weight: var(--mobile-fw450) !important;
  }
  .register-form-input-container
    > .ant-input-affix-wrapper
    > .ant-input::placeholder {
    font-size: var(--mobile-fz16) !important;
    font-weight: var(--mobile-fw450) !important;
  }
  .keyword-label {
    font-size: var(--mobile-fz16) !important;
    font-weight: var(--mobile-fw450) !important;
  }
  .keyword-other-label {
    font-size: var(--mobile-fz16) !important;
    font-weight: var(--mobile-fw450) !important;
  }
  .register-select-input-selection-placeholder {
    font-size: var(--mobile-fz16) !important;
    font-weight: var(--mobile-fw450) !important;
  }
  .vendor-text {
    font-size: var(--mobile-fz16) !important;
    font-weight: var(--mobile-fw450) !important;
  }
  .ads-column {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .tag-group-container {
    display: flex;
    column-gap: 10px;
    justify-content: center;
  }
  .tag-group-content {
    margin-left: 57px;
  }
  .chapter-button {
    left: 70% !important;
    bottom: 47px !important;
    margin-right: 15px;
  }
  .keyword-buttons-container {
    width: 50%;
  }
}
