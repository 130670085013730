.submit-update-pass-page {
  border-radius: 6px;
  background: var(--pbss-p1) !important;
  display: flex;
  height: 52px;
  width: 100%;
  padding: 11.644px 29.111px;
  justify-content: center;
  align-items: center;
  gap: 11.644px;
  color: var(--White, var(--White, #fff));
  font-size: var(--desktop-fz20) !important;
  font-style: normal;
  font-weight: var(--desktop-fw500);
  line-height: 150%;
  /* 34.933px */
}
.submit-update-pass-page:hover {
  background: var(--pbss-p1) !important;
  color: var(--White, var(--White, #fff)) !important;
}
.update-pass-form {
  display: flex;
  justify-content: center;
  padding: 50px 0px;
}
.updatePassword-form-input-container
  .ant-input-affix-wrapper-status-error
  .ant-input-affix-wrapper:hover {
  border-color: #ff4d4f !important;
}
.updatePassword-form-input-container
  > .ant-input-affix-wrapper-status-error
  .ant-input-affix-wrapper:focus {
  border-color: #ff4d4f !important;
}

@media screen and (max-width: 786px) {
  .update-pass-form {
    display: grid;
  }
}
@media screen and (max-width: 330px) {
  .submit-update-pass-page-button-wrapper {
    font-size: var(--desktop-fz16) !important;
    padding: 11.644px 94px;
  }
}
