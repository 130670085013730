.nav-haeder-wapper {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  position: absolute;
  background: var(--white);
}

.pbss-hader-menu .pbss-hader-menu-title-content {
  line-height: 30px;
}

.pbss-hader-menu .pbss-hader-menu-submenu-title .anticon {
  font-size: 30px;
}

.nav-bar {
  padding: 15px 8%;
  background: var(--white);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.nav-header-container {
  border-bottom: 1px solid var(--border-primary);
  position: relative;
  width: 100%;
}

.nav-header-sticky {
  position: sticky;
  top: 0;
  z-index: var(--z-index-main-header);
}

.pbss-menu-submenu-title {
  flex-direction: row-reverse !important;
  display: flex !important;
  gap: 10px;
  font-size: var(--desktop-fz20);
  font-style: normal;
  font-weight: var(--desktop-fw500);
  line-height: 24px;
  /* 120% */
  letter-spacing: -0.4px;
  color: var(--black) !important;
}

.bg-remove {
  background: none !important;
  color: var(--pbss-p1) !important;
  border: 1px solid var(--pbss-p1) !important;
}

/* .extend-button-w{
  width: 164px !important;
  min-width: auto;
} */

.button-container-header {
  display: flex;
  gap: 10px;
  align-items: center;
}

.button-container-header button {
  border: 1px solid #000 !important;
  border-radius: 20px !important;
  background: #072f7d !important;
}

.button-container-header .bg-remove{
 color: #e26d42 !important ;
}

.chapter-selector {
  border: 1px solid #000000a3 !important;
  height: auto !important;
  padding: 1px 12px !important;
  min-width: 150px !important;
  background: linear-gradient(
    47deg,
    #bce8eaa8 -85.95%,
    rgba(255, 255, 255, 0) 216.03%
  );
}

.chapter-extend-selector {
  border: 1px solid #ffffffa3 !important;
  height: auto !important;
  padding: 1px 12px !important;
  min-width: 150px !important;
  color: var(--color-darkslategray-100) !important;
}

.chapter-extend-collapse-selector {
  border: 1px solid #ffffff !important;
  height: auto !important;
  padding: 1px 12px !important;
  min-width: 150px !important;
  color: var(--color-darkslategray-100) !important;
}

.chapter-extend {
  margin: 0px 4px;
  height: 40px;
  line-height: 40px;
  display: flex;
  align-items: center;
  color: var(--color-darkslategray-100);
  border-radius: 6px;
  border: 1px solid #d5f2f3 !important;
}

.chapter-selection-placeholder {
  color: #000 !important;
}

.pbss-menu-item-selected::after {
  color: var(--color-darkslategray-100) !important;
  border-bottom-color: var(--color-darkslategray-100) !important;
}

.pbss-menu-item-selected {
  color: var(--color-darkslategray-100) !important;
  background-color: #edffffdf;
}

.pbss-menu-inner-item-selected {
  color: #78afb3 !important;
}

.pbss-menu-item-active {
  color: var(--color-darkslategray-100) !important;
}

.pbss-menu-item:hover::after {
  border-bottom-color: var(--color-darkslategray-100) !important;
}

.pbss-menu-submenu-selected::after {
  border-bottom-color: white !important;
}

.pbss-menu-submenu-open::after {
  border-bottom-color: var(--color-darkslategray-100) !important;
}

.pbss-menu {
  border-bottom: none;
  visibility: visible;
  width: 100%;
  /* margin-left: 64px; */
}

.pbss-hader-menu-item-selected {
  color: var(--white) !important;
}

.pbss-hader-menu-item-selected::after {
  border-bottom-color: var(--white) !important;
}

.pbss-hader-menu-item:hover::after {
  border-bottom-color: var(--offWhite) !important;
}

.pbss-hader-menu-item::after {
  top: 29px !important;
}

.pbss-hader-menu-submenu-selected > .pbss-hader-menu-submenu-title {
  color: var(--white) !important;
}

 .pbss-hader-menu-submenu-popup .pbss-hader-menu-submenu-selected .pbss-hader-menu-title-content{
  color: var(--pbss-p1) !important;
}
.pbss-hader-menu-submenu-popup .pbss-hader-menu-submenu-selected  .pbss-hader-menu-submenu-arrow{
  color: var(--pbss-p1) !important;
}

.pbss-hader-menu-submenu::after {
  top: 29px !important;
}

.pbss-hader-menu-submenu-selected {
  color: var(--pbss-p1) !important;
}

.pbss-hader-menu-submenu-selected::after {
  color: var(--pbss-p1) !important;
  border-bottom-color: var(--white) !important;
}

.pbss-hader-menu-submenu-selected:hover::after {
  border-bottom-color: var(--pbss-p1) !important;
  color: var(--pbss-p1) !important;
}

.pbss-hader-menu {
  color: whitesmoke;
  background: none;
  border-bottom: none;
  visibility: visible;
  width: 100%;
  flex: 1;
  margin-left: 3.5%;
  font-size: var(--desktop-fz14);
  font-style: normal;
  font-weight: var(--desktop-fw500);
  line-height: 24px;
  letter-spacing: -0.4px;
}

.drawer-header-logo {
  width: 120px;
  height: 60px;
  cursor: pointer;
}

.header-logo {
  width: 180px;
  height: 78px;
}

.header-logo-user {
  width: 103px;
  height: 50px;
  cursor: pointer;
}

.header-chapter-logo {
  width: 160px;
  height: 70px;
  cursor: pointer;
}

.pbss-menu-submenu-active::after {
  border-bottom-color: var(--pbss-p1) !important;
}

.side-bar-layout {
  min-height: 100vh;
  background: linear-gradient(
    180deg,
    rgba(10, 87, 91, 0.05) 55.8%,
    rgba(4, 20, 21, 0) 166.83%
  );
}

.costom-header {
  height: 400px !important;
  width: 400px;
  left: 0px !important;
}

.mega-container {
  margin: 30px;
  margin-left: 100px;
  width: 100vh;
}

.mega-menu-img {
  width: 300px;
  height: 100px;
}

.pbss-menu-submenu {
  left: 0 !important;
  width: auto;
}

.pbss-header-menu-item-active {
  color: var(--pbss-p1);
}

/* .pbss-hader-menu-title-content{
  color: var(--PBSS-Primary-menu-text);
} */
.pbss-hader-menu-item:hover {
  color: var(--offWhite) !important;
}

/* .pbss-hader-menu-vertical.pbss-hader-menu-sub .pbss-hader-menu-title-content{
  color: var(--PBSS-Primary-menu-text);
} */

.pbss-hader-menu-vertical
  > .pbss-hader-menu-submenu
  > .pbss-hader-menu-submenu-title {
  width: fit-content;
  width: auto;
  justify-content: flex-end;
}

.pbss-hader-menu-vertical.pbss-hader-menu-sub .pbss-hader-menu-item-selected {
  color: var(--pbss-p1) !important;
}

.pbss-hader-menu-vertical.pbss-hader-menu-sub {
  width: fit-content !important;
  min-width: 120px !important;
  margin-top: 4px !important;
  font-size: 13px;
  font-style: normal;
  border-top: 2px solid transparent;
  font-weight: var(--desktop-fw700);
  border-radius: 0px;
  background: rgb(241, 245, 255) !important;
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.15) !important;
  z-index: 999;
}

.pbss-hader-menu-vertical.pbss-hader-menu-sub > li {
  /* background-color: none !important; */
  /* padding-inline: 0px !important; */
  /* height: 30px !important; */
  /* display: flex !important; */
  align-items: center !important;
  line-height: 20px;
}

.pbss-hader-menu-submenu-popup .pbss-hader-menu-vertical .pbss-hader-menu-item {
  height: auto !important;
  line-height: 30px !important;
  padding: 0px 8px;
}

.pbss-hader-menu-light.pbss-hader-menu-horizontal
  > .pbss-hader-menu-submenu:hover::after {
  border-bottom-color: var(--offWhite) !important;
}
.pbss-hader-menu-light.pbss-hader-menu-horizontal >.pbss-hader-menu-submenu:hover{
  color: var(--offWhite) !important;
}

.pbss-hader-menu
  .pbss-hader-menu-item-group
  .pbss-hader-menu-item-group-list
  .pbss-hader-menu-item {
  padding-inline: 20px 16px !important;
}

.pbss-hader-menu-submenu-popup.pbss-hader-menu-submenu {
  width: 120px !important;
  margin-left: -34px !important;
}

.up-coming-event {
  color: #005d61;
  font-size: var(--desktop-fz24);
  font-style: normal;
  font-weight: var(--desktop-fw600);
  line-height: 150%;
}

.sponsors {
  color: #78afb3 !important;
}

.dis-event > p {
  color: #717171;
  font-size: var(--desktop-fz14);
  line-height: normal;
  font-style: normal;
  font-weight: var(--desktop-fw500);
  box-sizing: border-box;
}

.dis-event {
  width: 230px;
  line-height: 150%;
}

.explore-jobs {
  display: inline-flex;
  padding: 8px 30px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 6px;
  border: 1px solid #a8cfd0;
  background: #f7fdfd;
  color: var(--pbss-p1, #005d61);
  font-size: 15.53px;
  font-style: normal;
  font-weight: var(--desktop-fw600);
  cursor: pointer;
  line-height: 150%;
  width: fit-content;
}

.section1 {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: auto;
}

/* .pbss-menu-submenu-popup {
  width: 100%;
}
.pbss-menu-vertical {
  box-shadow: none !important;
  background: #f5ffff !important;
}

.pbss-menu-vertical:hover {
  box-shadow: none !important;
  background: #ffffff !important;
} */
.section2 {
  margin-left: 60px;
}

.common-mega-items {
  margin: 20px !important;
  width: auto !important;
  background: none !important;
  border: none !important;
  color: var(--pbss-p1, #005d61);
  font-size: var(--desktop-fz14);
  font-style: normal;
  font-weight: var(--desktop-fw600);
  line-height: 150%;
}

.down-extend {
  font-size: var(--desktop-fz13) !important;
  font-weight: var(--desktop-fw700) !important;
}

.pbss-hader-menu-submenu-title {
  flex-direction: row-reverse;
  gap: 10px;
  display: flex !important;
  height: 30px !important;
}

.extend-settings {
  flex-direction: column;
  margin: 10px 15px;
}

.extend-settings-button {
  width: 100%;
}

.mega-menu-children {
  height: 35vh !important;
  left: 0px !important;
}

.pbss-hader-menu-submenu-popup::before {
  display: flex !important;
  position: relative;
  width: 200px !important;
}

.custom-profile-button .ant-btn-default {
  border: none !important;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: var(--desktop-fz14);
  color: var(--white);
  font-style: normal;
  font-weight: var(--desktop-fw500);
}

.custom-profile-button .ant-btn-default:hover {
  color: var(--pbss-p1) !important;
}

.custom-profile-button {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-items: center;
  border-radius: 20px !important;
  border: 1px solid #000 !important;
}

.drawer-menu {
  padding: 0 15px;
  font-size: var(--desktop-fz14);
  font-style: normal;
  font-weight: var(--desktop-fw500) !important;
  line-height: 18px;
  /* 128.571% */
  letter-spacing: -0.28px;
}

.drawer-menu .pbss-menu-submenu-title {
  padding-left: 0px !important;
}

.drawer-menu .pbss-menu-submenu-title:hover {
  color: var(--pbss-p1) !important;
  background-color: white !important;
  border-bottom: 2px solid var(--pbss-p1);
  border-radius: 0px;
}

.drawer-menu .pbss-menu-item-only-child {
  padding-left: 10px !important;
  font-size: 11.24px;
  font-style: normal;
  font-weight: var(--desktop-fw700);
  line-height: 108%;
  /* 12.139px */
  letter-spacing: -0.247px;
}

.drawer-menu .pbss-menu-item-selected {
  background-color: white !important;
  color: var(--pbss-p1) !important;
}

.drawer-menu .pbss-menu-sub {
  background-color: var(--PBSS-PrimaryMenuBackground) !important;
  font-size: 11px !important;
  padding-left: 10px !important;
}

.drawer-menu .pbss-menu-sub .pbss-menu-item-only-child {
  padding-left: 10px !important;
  height: 30px;
  font-size: 11.24px;
  font-style: normal;
  font-weight: var(--desktop-fw700);
  line-height: 108%;
  /* 12.139px */
  letter-spacing: -0.247px;
}

.custom-profile-button .ant-dropdown-trigger {
  width: fit-content;
}

.custom-profile-button .ant-btn-compact-first-item {
  padding: 4px;
}

.user-profile-button-menu {
  color: var(--white);
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 4px 6px;
}

.user-profile-button-menu .anticon-user{
  margin-left: 8px;
}

@media screen and (max-width: 768px) {
  .header-chapter-logo {
    width: 160px !important;
    height: 70px !important;
  }

  .nav-bar {
    justify-content: space-between;
  }

  .pbss-header-nav {
    padding: 15px 50px !important;
  }

  .extend-width {
    display: none;
  }
}

@media screen and (max-width: 450px) {
  .header-chapter-logo {
    width: 103px !important;
    height: 50px !important;
  }

  .ant-drawer-content-wrapper {
    width: 100% !important;
  }

  .pbss-header-nav {
    padding: 15px 30px !important;
  }
}

@media screen and (max-width: 1440px) {
  .header-chapter-logo {
    width: 160px;
    height: 70px;
  }

  .nav-bar {
    max-width: var(--page-size);
  }

  .bg-remove {
    min-width: 164px !important;
  }
}
