.about-us-container > label {
  color: var(--pbss-p1);
  font-size: var(--desktop-fz28);
  font-style: normal;
  font-weight: var(--desktop-fw600);
  line-height: 132%; /* 49.28px */
}

.about-us-container > p {
  color: #505050;
  font-size: var(--desktop-fz16);
  font-style: normal;
  font-weight: var(--desktop-fw400);
  margin-top: 20px !important;
  line-height: 162%; /* 37.26px */
}

.about-us-container {
  margin: 3rem 0;
  margin-top: 2rem;
}

.about-us-container > img {
  border-radius: 12px;
  width: 100%;
  height: 481px;
  object-fit: cover;
  margin-bottom: 2%;
}

.commite-sub-container {
  display: flex;
  background: #f0f6f7;
}

.commite-sub-wapper {
  display: flex;
  margin: 4rem 0;
  margin-right: 4rem;
  gap: 10rem;
}

.commite-sub-wapper > div > label {
  color: var(--pbss-p1);
  font-size: var(--desktop-fz28);
  font-style: normal;
  font-weight: var(--desktop-fw600);
  line-height: 132%; /* 49.28px */
}

.commite-sub-wapper > div > p {
  color: #505050;
  font-size: var(--desktop-fz18);
  font-style: normal;
  font-weight: var(--desktop-fw400);
  margin-top: 40px;
  line-height: 162%; /* 37.26px */
}

.commite-sub-wapper > img {
  width: 527.125px;
  height: 440px;
  flex-shrink: 0;
  object-fit: cover;
}

.commitee-left-section {
  display: flex;
  flex-direction: column;
  gap: 4%;
}

@media screen and (max-width: 1200px) {
  .commite-sub-wapper {
    flex-direction: column;
    gap: 30px;
  }
  .commite-sub-wapper > img {
    width: 100% !important;
    height: 326px;

    flex-shrink: 0;
  }
  .commite-sub-wapper {
    margin-right: 0;
    gap: 2rem !important;
  }
}

@media screen and (max-width: 1500px) {
  .commite-sub-wapper {
    gap: 4rem;
  }
  .commite-sub-wapper > img {
    width: 427.125px;
    height: 340px;
  }
}

@media screen and (max-width: 480px) {
  .about-us-container > label {
    font-size: var(--desktop-fz22);
  }
  .about-us-container {
    margin: 0;
  }
  .about-us-container > p {
    font-size: var(--desktop-fz14);
  }
  .commite-sub-wapper > div > label {
    font-size: var(--desktop-fz22);
  }
  .commite-sub-wapper {
    margin-right: 0;
    margin: 2rem 0;
    margin-bottom: 3rem;
  }
  .commite-sub-wapper > div > p {
    margin-top: 20px !important;
    font-size: var(--desktop-fz14);
  }
  .commite-sub-wapper > img {
    height: 200px;
  }
  .about-us-container > img {
    height: 200px;
  }
}
