.common-job-listing-container {
  border-radius: 8px;
  border: 0.573px solid #e9e9e9;
  background: linear-gradient(
    274deg,
    rgba(117, 173, 176, 0.16) 74.25%,
    rgba(251, 251, 251, 0) 191.17%
  );
  margin: 20px 0px;
}

.common-jobs-listing-main-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 10px 0px;
}

.job-listing-group {
  margin: 0px 40px;
}
.job-listing-heading {
  color: var(--pbss-p1);
  font-size: var(--desktop-fz27);
  font-style: normal;
  font-weight: var(--desktop-fw700);
  line-height: normal;
}
.job-listing-subHeading {
  color: var(--black);
  font-size: var(--desktop-fz24);
  font-style: normal;
  font-weight: var(--desktop-fw500);
  line-height: 150%;
  margin-top: 15px;
}
.job-listing-desc {
  color: #747474;
  max-width: 1550px;
  font-size: var(--desktop-fz19);
  font-style: normal;
  font-weight: var(--desktop-fw450);
  line-height: var(--desktop-lh170);
  margin-top: 15px;
}
.job-listing-buttons {
  display: flex;
  gap: 20px;
  margin: 30px 0px 40px;
  justify-content: space-between;
}
.job-listing-position-container {
  display: flex;
  gap: 20px;
  align-items: center;
}
.job-listing-position {
  display: flex;
  align-items: center;
}
.position-button {
  border-radius: 8px;
  background: var(--pbss-p1);
  display: flex;
  align-items: center;
  padding: 25px 20px;
}
.position-button:hover {
  background: var(--pbss-p1) !important;
}
.position-content {
  color: #a3d3d5;
  font-size: 19.759px;
  font-style: normal;
  font-weight: var(--desktop-fw500);
  line-height: normal;
  margin-right: 10px;
}
.position-button-count {
  background: #d3e4e5;
  border-radius: 50%;
  padding: 18px 8px;
  display: flex;
  align-items: center;
}
.position-button-count:hover {
  background: #d3e4e5 !important;
}
.count-text {
  color: var(--pbss-p1);
  font-size: var(--desktop-fz20);
  font-style: normal;
  font-weight: var(--desktop-fw700);
  line-height: normal;
}
.job-listing-date {
  display: flex;
  padding: 25px 20px;
  align-items: center;
  gap: 15px;
  border-radius: 8px;
  background: var(--white);
}
.job-listing-date:hover {
  background: var(--white) !important;
}
.job-listing-date:focus-visible {
  outline: none !important;
}
.job-listing-date:focus {
  box-shadow: none !important;
}
.date-head {
  color: #635757;
  font-size: var(--desktop-fz18);
  font-style: normal;
  font-weight: var(--desktop-fw500);
  line-height: normal;
}
.date-content {
  color: var(--pbss-p1);
  font-size: 19.759px;
  font-style: normal;
  font-weight: var(--desktop-fw500);
  line-height: normal;
}
.date-content-container {
  display: flex;
  gap: 10px;
}
.date-icon-group {
  display: flex;
  align-items: center;
}
.icon-date {
  color: var(--pbss-p1);
  font-size: var(--desktop-fz20);
}

.job-listing-edit-viewdetails-wrapper {
  display: flex;
  gap: 1rem;
}

.job-listing-details-container {
  display: inline-flex;
  padding: 1.181px 30.453px;
  justify-content: center;
  align-items: center;
  gap: 12.181px;
  border-radius: 9.136px;
  background: var(--black);
  cursor: pointer;
}
.jobs-details-text {
  color: rgb(219, 219, 219);
  text-align: center;
  font-size: var(--desktop-fz18);
  font-style: normal;
  font-weight: var(--desktop-fw600);
  line-height: normal;
}

.job-listing-details-container:hover {
  color: black;
  background: var(--pbss-p1);
}

.status-badge {
  margin-top: 20px;
  display: flex;
  position: relative;
  bottom: 1px;
  left: 1px;
}
.status-badge-container {
  display: flex;
  justify-content: flex-end;
  position: relative;
}
.status-text {
  display: flex;
  align-items: center;
  font-size: var(--desktop-fz20);
  font-style: normal;
  font-weight: var(--desktop-fw500);
  top: 25px;
  line-height: 25.08px;
}
.draft {
  position: absolute;
  padding: 0px 30px;
  color: #061178;
}
.approved {
  position: absolute;
  padding: 0px 10px;
  color: #127f16;
}
.in_review {
  position: absolute;
  padding: 0px 10px;
  color: #780650;
}
.rejected {
  position: absolute;
  padding: 0px 15px;
  color: #96511f;
}
@media screen and (max-width: 768px) {
  .job-listing-details-container {
    font-size: var(--desktop-fz16) !important;
    padding: 12.181px 30.453px;
  }
}
@media screen and (max-width: 425px) {
  .job-listing-position {
    justify-content: center;
  }
  .position-button {
    padding: 25px 10px !important;
  }
  .jobs-listing-card .ant-card-body {
    padding: 0px !important;
  }
  .date-head {
    font-size: var(--desktop-fz15) !important;
  }
  .date-content {
    font-size: var(--desktop-fz15) !important;
  }
  .job-listing-date {
    padding: 25px 7px !important;
  }
  .content-text {
    font-size: var(--desktop-fz20);
  }
}
