.btn-main-container > button {
  padding: 8px;
  gap: 0.8em;
  height: fit-content;
  display: flex;
  align-items: center;
  border-radius: 8px;
  border: 1px solid var(--pbss-p1);
  background: rgba(86, 118, 179, 0);
  color: var(--pbss-p1);
  font-size: var(--desktop-fz19);
  font-style: normal;
  font-weight: var(--desktop-fw500);
  line-height: 150%;
  cursor: pointer;
}

/* .btn-main-container > button > span {
  background: var(--pbss-p1);
  padding: 0px 6px;
  color: white;
  border-radius: 4px;
} */

.btn-main-container > button:hover {
  background: var(--pbss-p1);
  color: var(--white);
}

.btn-main-container > button:hover .btn-icon-box {
  background: white;
  color: var(--pbss-p1);
}

.btn-icon-box {
  background: var(--pbss-p1);
  color: white;
  padding: 0px 6px;
  font-size: 18px;
  font-weight: 700;
  border-radius: 8px;
}

@media screen and (max-width: 480px) {
  .btn-main-container > button {
    padding: 4px 8px !important;
    font-size: var(--desktop-fz14);
    width: 100%;
    justify-content: center;
  }

  .btn-icon-box {
    padding: 2px 4px;
  }
}
