.login-title > p {
  color: #0e1c1d;
  font-size: var(--desktop-fz28);
  font-style: normal;
  font-weight: var(--desktop-fw500);
  line-height: 150%;
  /* 42px */
}
.login-title > span {
  color: #0e1c1d;
  font-size: var(--desktop-fz16);
  font-style: normal;
  font-weight: var(--desktop-fw500);
  line-height: 150%;
  /* 24px */
}
.login-title > span > label {
  color: #7c7d7d;
  font-size: var(--desktop-fz16);
  font-style: normal;
  font-weight: var(--desktop-fw500);
  line-height: 150%;
}
