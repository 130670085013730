.register-container {
  margin-bottom: 150px;
}

.Register-form-user-conatiner {
  width: 100%;
  max-width: 600px;
}
.registration-description {
  font-style: normal;
  font-weight: var(--desktop-fw500);
  font-size: var(--desktop-fz28);
  line-height: 150%; /* 39px */
  color: #2e56a4;
}
.registration-disclaimer {
  font-style: normal;
  font-weight: var(--desktop-fw500);
  font-size: var(--desktop-fz18);
  line-height: 150%; /* 39px */
  margin: 5px 0px 41px;
  color: black;
  max-width: 1030px;
  text-align: justify;
}
.registration-disclaimer2 {
  font-style: normal;
  font-weight: var(--desktop-fw500);
  font-size: var(--desktop-fz18);
  line-height: 150%; /* 39px */
  margin: 5px 0px 41px;
  color: black;
  max-width: 1030px;
  text-align: justify;
}

@media screen and (max-width: 786px) {
  .registration-description {
    font-size: var(--desktop-fz22);
  }
  .registration-content-container {
    margin-top: 10px;
  }
  .registration-disclaimer {
    font-size: var(--desktop-fz16) !important;
  }
  .registration-disclaimer2 {
    font-size: var(--desktop-fz16) !important;
  }
}
@media screen and (max-width: 425px) {
  .registration-description {
    font-size: var(--desktop-fz18);
    margin: 20px 0px;
  }
  .registration-content-container {
    margin-top: 0px;
  }
}
