.add-attendee-container {
  display: flex;
  justify-content: space-between !important;
}

.attendees-info-text {
  color: var(--Black, #000);
  font-size: var(--desktop-fz26);
  font-style: normal;
  font-weight: var(--desktop-fw500);
  line-height: 100%; /* 26px */
}

.attendee-sub-container {
  display: flex;
  align-items: center;
  gap: 50px;
}

.food-options-selection {
  border: none;
  display: flex;
  padding: 5px 10px;
  justify-content: center;
  align-items: center;
  gap: var(--Count, 10px);
  flex-shrink: 0;
  background: rgba(128, 128, 128, 0.147);
  border-radius: 5.833px;
  font-size: 13.817px;
  font-style: normal;
  font-weight: var(--desktop-fw450);
  border-color: none;
  line-height: 120%; /* 16.58px */
}

.food-options-selection:hover {
  display: flex;
  padding: 5px 10px;
  justify-content: center;
  align-items: center;
  gap: var(--Count, 10px);
  flex-shrink: 0;
  border-radius: 5.833px;
  color: var(--pbss-p1, #005d61) !important;
  font-size: 13.817px;
  font-style: normal;
  font-weight: var(--desktop-fw450);
  line-height: 120%; /* 16.58px */
}

.optionactive {
  background: #e5f8ed;
}

.sponsor-navigate-container > p {
  color: #505050;
  font-size: var(--desktop-fz20);
  font-style: normal;
  font-weight: var(--desktop-fw450);
  line-height: 162%; /* 38.88px */
}

.sponsor-navigate-container > button {
  display: inline-flex;
  padding: 8px 20px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 6px;
  background: var(--pbss-p1, #005d61);
  color: var(--White, var(--White, #fff));
  font-size: var(--desktop-fz16);
  font-style: normal;
  border: none;
  font-weight: var(--desktop-fw400);
  line-height: 150%; /* 24px */
  cursor: pointer;
}

.sponsor-navigate-container > button:hover {
  background: var(--pbss-p1, #000606) !important;
  color: var(--White, var(--White, #fff));
}

.sponsor-navigate-container {
  display: flex;
  align-items: center;
  gap: 20px;
}

.sponsor-upload-assets {
  width: 60% !important;
}

.addAttendeeWrapperEventSponsorComponent {
  display: flex;
  justify-content: end;
}

.sponsor-upload-assets .ant-modal-content {
  padding: 4% 5%;
  max-height: 70vh;
  overflow-y: auto;
  padding: 4%;
}
.sponsor-upload-assets .ant-modal-title {
  background: rgba(211, 211, 211, 0.383);
  padding: 10px;
  border-radius: 4px;
}
.upload-conatiner {
  margin-bottom: 0px;
}
@media screen and (max-width: 950px) {
  .sponsor-navigate-container {
    flex-direction: column;
    align-items: start;
  }
}

@media screen and (max-width: 480px) {
  .sponsor-upload-assets {
    width: 90% !important;
  }
  .upload-conatiner {
    margin: 20px 0px;
    margin-bottom: 30px;
    display: flex;
    gap: 30px;
  }
}
