.hero-main-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 8% 12px 8%;
  grid-gap: 1rem;
  background-color: #003885 !important;
  border: 1px solid #003885;
  color: #ffffff;
}

@media screen and (max-width: 860px) {
  .hero-main-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  }
}

@media screen and (min-width: 1600px) {
  .hero-main-container {
    padding: 12px 8% 12px 8%;
  }
}

.extra-content {
  display: flex;
  justify-content: end;
}

.hero-sub-container {
  display: flex;
  gap: 4px;
  flex-direction: column;
  justify-content: center;
}

.text-container-sponsor {
  display: flex;
  flex-direction: column;
  gap: 5px;
  background-color: transparent;
}

.regular-attendee-button {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: var(--desktop-fz18);
  font-weight: var(--desktop-fw600);
  color: #13326c;
  background-color: #fff;
  padding: 12px 16px;
  border-radius: 6px;
  border: 1px solid #2e56a4;
  box-shadow: 0px 0px 5px 0px rgba(79, 112, 176, 0.36);
}

.hero-heading {
  font-size: var(--desktop-fz28);
  font-weight: var(--desktop-fw500);
  line-height: 100%;
  margin: 0;
}

.hero-subheading {
  font-size: var(--desktop-fz18);
  font-weight: var(--desktop-fw400);
}

.contact-us_register {
  width: 90%;
  margin-bottom: 10px;
}

@media screen and (max-width: 1440px) {
  .hero-heading {
    font-size: var(--desktop-fz28);
  }
  .hero-subheading {
    font-size: var(--desktop-fz20);
  }
  .regular-attendee-button {
    font-size: var(--desktop-fz16);
  }
}

@media screen and (max-width: 1024px) {
  .hero-heading {
    font-size: var(--desktop-fz28);
  }
  .hero-subheading {
    font-size: var(--desktop-fz18);
  }
  .contact-us_register {
    width: 100%;
    margin-bottom: 10px;
  }
  .regular-attendee-button {
    font-size: var(--desktop-fz16);
  }
}

@media screen and (max-width: 768px) {
  .hero-heading {
    font-size: var(--desktop-fz22);
  }
  .hero-subheading {
    font-size: var(--desktop-fz16);
  }
  .regular-attendee-button {
    font-size: var(--desktop-fz14);
    padding: 10px 14px;
  }
}
@media screen and (max-width: 370px) {
  .hero-main-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr)) !important;
  }
}
@media screen and (max-width: 480px) {
  .hero-main-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  }
  .hero-main-container {
    padding: 6% 8%;
  }
  .hero-sub-container {
    height: auto;
    /* padding: 28px 8%; */
    /* display: block; */
    display: flex;
    flex-direction: column;
    gap: 10px;
    background-color: transparent;
  }
  .hero-heading {
    font-size: var(--mobile-fz16);
    font-weight: var(--mobile-fw600);
    margin-right: 5px;
    line-height: normal;
  }
  .hero-subheading {
    font-size: var(--mobile-fz14);
    font-weight: var(--mobile-fw400);
  }
  .regular-attendee-button {
    font-size: var(--mobile-fz14);
    padding: 10px 14px;
  }
}

@media screen and (max-width: 375px) {
  .hero-heading {
    font-size: var(--mobile-fz14);
    font-weight: var(--mobile-fw600);
  }
  .hero-subheading {
    font-size: var(--mobile-fz14);
    font-weight: var(--mobile-fw400);
  }
}
