.costom-textarea-modal {
  min-height: 300px !important;
  max-height: 500px !important;
}

.import-code-modal-cancel {
  margin: 0px 10px;
  background-color: white !important;
  border: 1px solid rgb(158, 158, 158) !important;
  color: rgb(158, 158, 158) !important;
}

.import-code-modal-cancel:hover {
  background-color: rgb(158, 158, 158) !important;
  color: white !important;
  border: 1px solid rgb(158, 158, 158) !important;
}

.import-code-modal {
  background-color: white !important;
  border: 1px solid var(--color-darkslategray-100) !important;
  color: var(--color-darkslategray-100) !important;
}

.import-code-modal:hover {
  background-color: var(--color-darkslategray-100) !important;
  color: white !important;
  border: 1px solid var(--color-darkslategray-100) !important;
}

.update-status-selection {
  width: 100%;
}
.status-modal-popconfirm-buttons .ant-btn-primary {
  background-color: var(--PBSS--Primary) !important;
}
.update-footer {
  display: flex;
  justify-content: end;
  margin-top: 20px !important;
}

.refund-modal-fields-wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.refund-modal-radio-wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.refund-modal-radio-wrapper > p {
  padding-left: 5px !important;
}

.refund-radio-group-group .ant-radio-button {
  background-color: var(--PBSS--Primary);
  border: solid 1px var(--PBSS--Primary);
}

.refund-radio-group-group .ant-radio-button-wrapper span {
  color: black;
}

.refund-radio-group-group .ant-radio-button-wrapper span:hover {
  color: var(--PBSS--Primary);
}

.refund-radio-group-group .ant-radio-button-wrapper-checked span {
  color: white;
}

.refund-radio-group-group .ant-radio-button-wrapper-checked span:hover {
  color: white;
}

.refund-radio-group-group .ant-radio-button-wrapper-checked {
  border: solid 1px var(--PBSS--Primary) !important;
}

.refund-radio-group-group .ant-radio-button-wrapper-checked::before {
  background-color: var(--PBSS--Primary) !important;
}

.common-button-refund {
  border: 1px solid var(--pbss-p1);
  background-color: var(--pbss-p1);
  display: flex;
  align-items: center;
}
.common-button-refund a {
  color: var(--white) !important;
}

.common-button-refund:hover {
  background-color: var(--pbss-p1) !important;
}
