.common-text-payment {
  font-size: var(--desktop-fz17);
  font-style: normal;
  font-weight: var(--desktop-fw700);
  line-height: 100%;
}

.common-text-desc {
  color: var(--Gray-2, #4f4f4f);
  font-size: var(--desktop-fz17);
  font-style: normal;
  font-weight: var(--desktop-fw500);
  line-height: 100%;
}

.attendees-table-cell {
  display: flex;
  justify-content: center;
  align-items: center;
}

.attendees-table .ant-pagination-total-text {
  position: absolute;
  right: 10px;
  border-radius: 5px;
  padding: 5px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: var(--desktop-fz19);
  font-style: normal;
  font-weight: var(--desktop-fw700);
  line-height: 150%;
}

.payment-history-table {
  margin-bottom: 30px;
  box-shadow: 0px 4px 16px 0px rgba(204, 204, 204, 0.25);
}

.filter-action-container {
  display: flex;
  gap: 10px;
}

.filter-action-container > button {
  border-radius: var(--Count, 0px);
  color: black;
  border: 0.778px solid #c6cfe1;
  background: #fff;
  height: inherit;
  font-size: 11.506px;
  font-style: normal;
  font-weight: var(--desktop-fw700);
}

.filter-action-container > button:hover {
  border-color: var(--pbss-p1) !important;
  color: var(--pbss-p1) !important;
}

.filter-action-main {
  display: flex;
  justify-content: space-between;
}

.filters-container {
  display: flex;
  gap: 10px;
}

.filters-container > button {
  border: 1px solid #dadada;
  border-radius: 4px;
  background: #ffffff;
  display: flex;
  height: inherit;
  padding: 9px 21px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  color: var(--pbss-p1);
  font-size: var(--desktop-fz17);
  font-style: normal;
  font-weight: var(--desktop-fw600);
  line-height: 100%;
}

.active-payment {
  background: var(--pbss-p1) !important;
  color: white !important;
}

.payment-history-table .ant-table-footer {
  padding: 20px !important;
}
.view-more-extend {
  padding: 8px 15px !important;
}
.view-more-text-extend {
  font-size: var(--desktop-fz14);
}
@media screen and (max-width: 480px) {
  .common-text-payment {
    font-size: var(--desktop-fz14);
  }
  .common-text-desc {
    font-size: var(--desktop-fz14);
  }
  .attendees-table .ant-pagination-total-text {
    font-size: var(--desktop-fz14);
  }
  .filters-container > button {
    font-size: var(--desktop-fz14);
  }
}

@media screen and (max-width: 900px) {
  .filter-action-main {
    flex-direction: column;
    gap: 10px;
  }
}
