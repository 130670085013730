.stem-content-container {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.stem-heading {
  color: #000000;
  font-size: var(--desktop-fz22);
  font-style: normal;
  font-weight: var(--desktop-fw500);
  margin-top: 20px !important;
  width: 100%;
  line-height: 162%;
}
.stem-meeting-container {
  width: 100%;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 6px;
}
.stem-meeting-link {
  color: var(--pbss-p1);
  font-size: var(--desktop-fz20);
  font-style: normal;
  font-weight: var(--desktop-fw500);
}
.stem-meeting-link:hover {
  color: var(--pbss-p1);
  font-size: var(--desktop-fz20);
  font-style: normal;
  font-weight: var(--desktop-fw500);
}
.stem-meeting-passcode {
  color: #000000;
  font-size: var(--desktop-fz16);
  font-style: normal;
  font-weight: var(--desktop-fw400);
}
.stem-contact-heading {
  color: #000000;
  font-size: var(--desktop-fz18);
  font-style: normal;
  font-weight: var(--desktop-fw400);
  margin-top: 20px !important;
  width: 100%;
}
.stem-contact-link {
  color: var(--pbss-p1);
  font-size: var(--desktop-fz18);
  font-style: normal;
  font-weight: var(--desktop-fw400);
  line-height: 162%;
}
.stem-meeting-session-container {
  display: flex;
  gap: 7px;
  align-items: center;
}
.stem-meeting-date {
  color: #000000;
  font-size: var(--desktop-fz18);
  font-style: normal;
  font-weight: var(--desktop-fw400);
  line-height: 162%;
}
.stem-meeting-session {
  color: var(--pbss-p1);
  font-size: var(--desktop-fz20);
  font-style: normal;
  font-weight: var(--desktop-fw500);
}
@media screen and (max-width: 768px) {
  .stem-heading {
    font-size: var(--desktop-fz18);
  }
}
@media screen and (max-width: 425px) {
  .stem-heading {
    font-size: var(--desktop-fz16);
  }
  .stem-contact-heading {
    font-size: var(--desktop-fz16);
  }
  .stem-meeting-session {
    font-size: var(--desktop-fz16);
  }
  .stem-meeting-date {
    font-size: var(--desktop-fz16);
  }
  .stem-meeting-link {
    font-size: var(--desktop-fz16);
  }
  .stem-meeting-passcode {
    font-size: var(--desktop-fz14);
  }
  .stem-contact-link {
    font-size: var(--desktop-fz16);
  }
}
@media screen and (max-width: 375px) {
  .stem-heading {
    font-size: var(--desktop-fz14);
  }
  .stem-contact-heading {
    font-size: var(--desktop-fz14);
  }
  .stem-meeting-session {
    font-size: var(--desktop-fz14);
  }
  .stem-meeting-date {
    font-size: var(--desktop-fz14);
  }
  .stem-meeting-link {
    font-size: var(--desktop-fz14);
  }
  .stem-meeting-passcode {
    font-size: var(--desktop-fz12);
  }
  .stem-contact-link {
    font-size: var(--desktop-fz14);
  }
}
