.hero-text-container-login {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* border: 1px solid #fff; */
  /* background: var(--PBSS-BG-LI); */
  padding: 12px 8% 12px 8%;
  background-color: #003885 !important;
  border: 1px solid #003885;
  color: #ffffff !important;
}

.heading-login {
  font-style: normal;
  line-height: 110%; /* 36.278px */
  font-size: var(--desktop-fz28);
  font-weight: var(--desktop-fw500);
  margin-bottom: 5px;
  align-items: center;
  display: flex;
}

.subheading-login {
  font-size: var(--desktop-fz20);
  font-weight: var(--desktop-fw500);
  font-style: normal;
  line-height: 150%; /* 27px */
}

@media screen and (min-width: 1600px) {
  .hero-text-container-login {
    padding: 12px 8% 12px 8%;
  }
}

@media screen and (max-width: 1440px) {
  .heading-login {
    font-size: var(--desktop-fz25);
  }
  .subheading-login {
    font-size: var(--desktop-fz22);
  }
}

@media screen and (max-width: 1024px) {
  .heading-login {
    font-size: var(--desktop-fz24);
  }
  .subheading-login {
    font-size: var(--desktop-fz20);
  }
}

@media screen and (max-width: 768px) {
  .heading-login {
    font-size: var(--desktop-fz24);
  }
  .subheading-login {
    font-size: var(--desktop-fz19);
  }
}

@media screen and (max-width: 480px) {
  .hero-text-container {
    height: auto;
    padding: 28px 8%;
    display: block;
    background-color: transparent;
  }
  .heading-login {
    font-size: var(--mobile-fz22);
    font-weight: var(--mobile-fw500);
    margin-right: 5px;
  }
  .subheading-login {
    font-size: var(--mobile-fz16);
    font-weight: var(--mobile-fw500);
  }
}
