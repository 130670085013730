.common-job-listing-card-content-wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 100%;
}

.common-job-left-right-group-wrapper {
  display: flex;
  gap: 20px;
}

.common-job-listing-card {
  width: 100%;
  min-height: max-content;
  border: 0.5px solid #a1b6df;
  filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.09))
    drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.04));
  padding: 20px 20px 0px 20px;
}
.common-job-listing-card-action-container {
  display: flex;
  gap: 10px;
}
.common-job-listing-locationDateDetails {
  display: flex;
  gap: 40px;
  min-height: 30px;
}

.common-job-listing-locationText {
  font-size: var(--desktop-fz14);
  font-weight: 500;
  background-color: var(--pbss-p3);
  padding: 5px 10px;
  color: #fff;
}

.common-job-listing-dateText {
  background-color: #eaf2ff;
  /* box-shadow: 0px 0.509px 4.073px 0px rgba(172, 172, 172, 0.18); */
  padding: 5px;
}

.dateText-text {
  font-size: var(--desktop-fz12);
  font-weight: var(--desktop-fw450);
  color: var(--pbss-p1);
}

.dateText-date {
  font-size: var(--desktop-fz12);
  font-weight: var(--desktop-fw500);
  color: var(--pbss-p1);
}

.common-job-listing-card-titleEmployerDetails {
  display: flex;
  gap: 10px;
}

.titleEmployerDetails-employer {
  font-size: var(--desktop-fz18);
  font-weight: var(--desktop-fw700);
}

.titleEmployerDetails-title {
  font-size: var(--desktop-fz18);
  font-weight: var(--desktop-fw400);
}

.common-job-listing-card-description {
  font-size: var(--desktop-fz16);
  font-weight: var(--desktop-fw450);
  color: #282828;
  line-height: 170%;
}

.common-job-listing-cardLeftGroup {
  display: flex;
  flex: 8;
  flex-direction: column;
  justify-content: start;
  gap: 10px;
}

.common-job-listing-cardRightGroup {
  display: flex;
  /* align-items: center; */
  justify-content: center;
  flex: 1;
  flex-direction: row;
  margin-top: -16px;
  margin-bottom: 20px;
  gap: 10px;
  padding: 15px;
  padding-left: 60px;
  min-height: 100%;
  border-left: 1px solid #9ab8f1;
}

.common-job-listing-viewDetailsButton {
  font-size: var(--desktop-fz16);
  font-weight: var(--desktop-fw500);
  color: var(--pbss-p3);
  background-color: #fff;
  border: 1px solid var(--pbss-p3);
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  cursor: pointer;
}

.common-job-listing-viewDetailsButton:hover {
  background-color: var(--pbss-p3);
  color: #fff;
}

@media screen and (max-width: 768px) {
  .common-job-listing-card {
    padding-right: 20px;
    padding-bottom: 10px;
  }

  .titleEmployerDetails-employer {
    min-width: fit-content;
  }
}

@media screen and (max-width: 700px) {
  .common-job-listing-card-content-wrapper {
    flex-direction: column;
    gap: 10px;
  }
  .common-job-left-right-group-wrapper {
    flex-direction: column;
  }
  .common-job-listing-cardRightGroup {
    border: none;
    width: 100%;
    padding: 0px;
    flex-direction: column;
    padding-left: 0px;
    margin-left: 0px;
  }
  .common-job-listing-viewDetailsButton {
    width: 100%;
  }
  .common-job-listing-locationDateDetails {
    flex-direction: column;
    justify-content: space-between;
    gap: 15px;
  }
}

@media screen and (max-width: 480px) {
  .common-job-listing-locationText {
    font-size: var(--mobile-fz12);
  }
  .common-job-listing-card-titleEmployerDetails {
    flex-direction: column;
    gap: 5px;
  }

  .common-job-listing-card-description {
    font-size: var(--mobile-fz14);
  }
  .common-job-listing-viewDetailsButton {
    font-size: var(--mobile-fz14);
  }
}

@media screen and (max-width: 375px) {
  .titleEmployerDetails-employer,
  .titleEmployerDetails-title {
    font-size: var(--mobile-fz16);
  }
  .common-job-listing-card-description {
    font-size: var(--mobile-fz12);
  }
  .common-job-listing-viewDetailsButton {
    font-size: var(--mobile-fz12);
  }
  .common-job-listing-locationText {
    font-size: var(--mobile-fz10);
    padding: 5px 5px;
    min-width: fit-content;
  }
  .common-job-listing-card {
    padding: 20px 10px;
  }
}
