.payment-success-container {
    text-align: center;
    padding: 50px;
}

.payment-success-box {
    display: inline-block;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 10px;
}

#success-icon {
    font-size: 48px;
    color: #4CAF50;
}

#success-message {
    color: #4CAF50;
}

#success-description {
    margin: 15px 0;
}

.success-btn {
    margin: 10px;
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.primary-btn {
    background-color: #4CAF50;
    color: #fff;
}

.secondary-btn {
    background-color: #ccc;
}

#back-to-home {
    /* Additional styles specific to this button if needed */
}

#my-event {
    /* Additional styles specific to this button if needed */
}