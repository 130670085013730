.common-editor {
  height: 375px !important;
  border: 1px solid #f1f1f1 !important;
  padding: 5px !important;
  margin-top: 10px;
  border-radius: 2px !important;
}
.jodit-status-bar-link {
  display: none;
}

.title-text {
  color: var(--black) !important;
  font-size: var(--desktop-fz21);
  font-style: normal;
  font-weight: var(--desktop-fw500);
  line-height: 150%;
  display: flex;
  align-items: center;
  margin: 10px 0px;
}
.jodit-add-new-line span {
  visibility: hidden;
}

.jodit-toolbar__box {
  background-color: white !important;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
}

.jodit-wysiwyg {
  background-color: white !important;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  min-height: 150px !important;
  overflow-y: scroll;
}

.jodit-status-bar {
  background-color: white !important;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
}

.custom-class .jodit-toolbar__box {
  background-color: #f0f5ff !important;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
}

.custom-class .jodit-wysiwyg {
  background-color: #f0f5ff !important;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
}

.custom-class .jodit-wysiwyg > p {
  color: #1e3d7b !important;
  font-size: var(--desktop-fz16) !important;
  font-weight: var(--desktop-fw400) !important;
}

.custom-class .jodit-status-bar {
  background-color: #f0f5ff !important;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
}

.custom-class .jodit-placeholder {
  color: #1e3d7b !important;
}
